import React, { useEffect } from "react";

import aboutUsImage1 from "../extra-images/aboutus-image-1.jpg";
import postImg from "../extra-images/post-text-img.png";

const AboutUs = ({ pageTitle }) => {
  useEffect(() => {
    document.title = `${pageTitle ?? ""} - GuidanceplusNI`;
  }, []);

  return (
    <>
      <div class="education-main-section">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="education-about-services">
                <h2>Wel Come To Guidance Plus NI </h2>
                <p className="text-justify">
                  UNLEASH YOUR FULL POTENTIAL WITH THE Leading Study and Recruitment Consultant in
                  the United Kingdom and India. Guidance Plus Ni is a premier study abroad
                  consultant and recruitment partner for esteemed institutions in the UK. Dare to
                  dream big and achieve every goal with the most dependable overseas education and
                  recruitment consultants in the UK. We empower students and employees by providing
                  them with essential information, unlocking international opportunities. Our team
                  of expert recruitment and study abroad consultants is dedicated to offering
                  personalized solutions designed specifically for you to help you reach your dreams
                  and aspirations.
                </p>

                <div class="education-service education-service-grid">
                  <ul class="row">
                    <li class="col-md-6">
                      <div class="education-service-grid-wrap">
                        <i class=" icon-old-fashion-briefcase"></i>
                        <h5>
                          <a href="#">Training</a>
                        </h5>
                        <p>
                          In some instances, additional training may be necessary to meet job
                          requirements.
                        </p>
                        <a href="#" class="education-readmore-btn">
                          Read More <i class="fa fa-arrow-circle-o-right"></i>
                        </a>
                      </div>
                    </li>
                    <li class="col-md-6">
                      <div class="education-service-grid-wrap">
                        <i class="icon-calculate"></i>
                        <h5>
                          <a href="#">Counseling</a>
                        </h5>
                        <p>
                          Career counseling is crucial for guiding young graduates
                          towardsAdditionally
                        </p>
                        <a href="#" class="education-readmore-btn">
                          Read More <i class="fa fa-arrow-circle-o-right"></i>
                        </a>
                      </div>
                    </li>
                    <li class="col-md-6">
                      <div class="education-service-grid-wrap">
                        <i class="icon-flask-outline"></i>
                        <h5>
                          <a href="#">Specialized Recruitment</a>
                        </h5>
                        <p>Our expertise lies in executive search, staffing</p>
                        <a href="#" class="education-readmore-btn">
                          Read More <i class="fa fa-arrow-circle-o-right"></i>
                        </a>
                      </div>
                    </li>
                    <li class="col-md-6">
                      <div class="education-service-grid-wrap">
                        <i class="icon-calculate"></i>
                        <h5>
                          <a href="#">Recruitment</a>
                        </h5>
                        <p>
                          Additionally, you can leverage our top-tier recruitment services to find
                          the right fit
                          <br />
                        </p>
                        <a href="#" class="education-readmore-btn">
                          Read More <i class="fa fa-arrow-circle-o-right"></i>
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <img src={aboutUsImage1} alt="Guiguidanceplusni" class="education-about-image" />
            </div>
            <div class="col-md-12">
              <div class="education-about-services">
                <h6 className="text-center">
                  We are responsible recruiters working in partnership with universities inside and
                  outside the United Kingdom for over the past two decades. We also work in the
                  employment sector by fulfilling the employer's needs by providing the right fit
                  candidate with the employer's desired skill set within the education and
                  employment services industry.
                </h6>

                <h6 className="text-center">
                  We are proudly in service to the general public, specialising in various fields
                  that include: International Student Admissions counselling, Overseas NMC
                  registration assistance, OCI application assistance, and Skilled worker placement
                  assistance.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="education-main-section education-section-postfull">
        <span class="post-transparent"></span>
        <div class="container">
          <div class="row">
            <div class="col-md-5">
              <div class="education-post-image">
                <img src={postImg} alt="Guiguidanceplusni" />
              </div>
            </div>
            <div class="col-md-7">
              <div class="education-post-text">
                <h3>WHAT DO WE DO?</h3>
                <p>
                  Securing admission to an international university requires meticulous attention to
                  detail at every step. Even the smallest errors can jeopardize your aspirations of
                  studying abroad.
                </p>
                <p>
                  Guidance Plus NI is a reputable student visa and employment consultancy
                  collaborating with over 500 top Companies, 1000 top-tier professional universities
                  and colleges worldwide. We provide robust support to enhance your chances of
                  receiving an offer from your desired company or university.
                </p>
                <a href="#" class="education-post-btn">
                  Get in Touch
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
