import React, { useEffect } from "react";

const NoPage = ({ pageTitle }) => {
  useEffect(() => {
    document.title = `${pageTitle ?? ""} - GuidanceplusNI`;
  }, []);

  return (
    <div class="education-main-content education-error-pagefull">
      <div class="education-main-section">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="education-Error-page">
                <h3>Error</h3>
                <h2>404</h2>
                <h4>Page Not Found</h4>
                <p>
                  Sorry, we can't find the page you are looking for.Please go to{" "}
                  <a href="index.html">HomePage</a>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoPage;
