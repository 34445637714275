import React, { useEffect } from "react";

import coursesGrid1 from "../extra-images/event-thumb-img.jpg";
import coursesGrid2 from "../extra-images/event-organizer-img.jpg";

const Recruitment = ({ pageTitle }) => {
  useEffect(() => {
    document.title = `${pageTitle ?? ""} - GuidanceplusNI`;
  }, []);

  return (
    <div class="education-main-section">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <figure class="education-event-thumb">
              <img src={coursesGrid1} alt="" />
              <figcaption>
                <div class="education-thumb-text">
                  <h2>Streamlined International Recruitment</h2>
                </div>
              </figcaption>
            </figure>
            <div class="education-course-features">
              <p>
                Guidance Plus NI was established to address the persistent challenges faced by the
                hospitality and manufacturing sectors in recruiting and retaining skilled and
                semi-skilled staff. With a base in Belfast, we bring a wealth of experience in
                Hospitality, Healthcare, Manufacturing, Recruitment, and Human Resources.
              </p>

              <p>
                Our focus is on offering staffing solutions to businesses in the UK and Ireland by
                sourcing talented professionals from countries such as India, the Philippines, the
                UAE, Latin American countries, Europe, Africa and beyond where these in-demand
                skills are abundant. Through strategic partnerships with reputable recruitment
                agencies and leveraging technology and social media platforms, we ensure a broad
                outreach to a diverse pool of qualified candidates.
              </p>
              <p>
                Our ultimate objective is clear: to facilitate a seamless recruitment process that
                benefits both our clients and the skilled individuals we aim to connect with,
                ensuring a smooth recruitment experience for clients and candidates.
              </p>
              <p>Ensuring a Smooth Recruitment Experience for Clients and Candidates</p>
            </div>
          </div>

          <aside class="col-md-6 education-sidebar">
            <div class="education-fancy-title">
              <i class="fa fa-graduation-cap"></i>
              <span>Personal information</span>
              <h2>JOB TITLE</h2>
            </div>
            <div class="education-contact-us-form">
              <form>
                <ul>
                  <li>
                    <input type="text" value="" placeholder="First name" />
                    <i class="fa fa-user"></i>
                  </li>
                  <li>
                    <input type="text" value="" placeholder="Last name" />
                    <i class="fa fa-user"></i>
                  </li>

                  <li>
                    <input type="text" value="" placeholder="Email*" />
                    <i class="fa fa-envelope"></i>
                  </li>
                  <li>
                    <input type="text" value="" placeholder="Phone" />
                    <i class="fa fa-phone"></i>
                  </li>

                  <li>
                    <input type="file" value="" placeholder="CV" />
                    <i class="fa fa-envelope"></i>
                    <label>Upload CV</label>
                  </li>
                  <li>
                    <input type="file" value="" placeholder="Additional files" />
                    <i class="fa fa-phone"></i>
                    <label>Additional files</label>
                  </li>

                  <li class="message-box">
                    <textarea name="comment" placeholder="Cover letter"></textarea>
                  </li>
                  <li>
                    <label>
                      <input type="submit" value="Send" />
                    </label>
                  </li>
                </ul>
              </form>
            </div>
          </aside>
        </div>
        <div className="row">
          <div class="col-md-12">
            <div class="education-section-heading">
              <h2>Collaboration</h2>
            </div>
            <div class="row">
              <p>
                At GuidanceplusNi, we are driven by passion for our work and committed to forming
                partnerships with businesses to comprehensively grasp their unique needs.
              </p>
            </div>

            <div class="education-section-heading">
              <h2>Effortless Solution</h2>
            </div>
            <div class="row">
              <p>
                Our straightforward approach guarantees that we deliver staff with the necessary
                skills and experience to fuel your business growth.
              </p>
            </div>

            <div class="education-section-heading">
              <h2>Top Talent</h2>
            </div>
            <div class="row">
              <p>
                Collaborating with our global partners enables us to handpick the finest candidates,
                while our colleagues in India offer local support to address any potential concerns
                that may arise.
              </p>
            </div>

            <div class="education-section-heading">
              <h2>Embark on this Journey with Us</h2>
            </div>
            <div class="row">
              <p>
                We understand that the overseas recruitment process can be overwhelming, which is
                why we provide a comprehensive package that includes everything from advertising to
                placing your new staff on your site.
              </p>
            </div>

            <div class="education-section-heading">
              <h2>If we press Discover</h2>
            </div>
            <div class="row">
              <p>
                Navigating the ongoing battle to recruit and retain skilled staff has emerged as a
                persistent challenge for many. Guidanceplusni was established to address the
                increasing need for talented personnel across various sectors in the UK and Ireland.
              </p>
              <p>
                Located in Mid Ulster, our inception stems from firsthand encounters with the
                continual and demanding hurdles encountered by the manufacturing industry in
                recruiting and retaining skilled employees.
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="education-section-heading">
              <h2>Why Choose Guidanceplusni?</h2>
            </div>

            <p>
              We recognize that every business is unique, and our dedication lies in collaborating
              closely with your company to fully comprehend your specific staffing needs. Our
              approach is straightforward and effective - we offer a transparent solution, ensuring
              that you receive staff with the requisite skills and experience to keep your business
              running smoothly and advancing, handling all responsibilities on your behalf.
            </p>
          </div>

          <div class="col-md-4">
            <div class="education-section-heading">
              <h2>Quality Assurance</h2>
            </div>

            <p>
              Our commitment to excellence encompasses sourcing and evaluating candidates. Through
              our global partners, we meticulously select candidates that align with your company's
              requirements. Additionally, our global partners provide crucial support to facilitate
              a seamless transition for candidates.
            </p>
          </div>

          <div class="col-md-4">
            <div class="education-section-heading">
              <h2>Legal Support</h2>
            </div>

            <p>
              Navigating visas and legal obligations can be complex and overwhelming. Guidanceplusni
              eases this burden. Our team ensures that all essential documentation and compliance
              are efficiently completed, both in the UK and the candidates' location.
            </p>
          </div>
        </div>
        <div className="row">
          <div class="col-md-4">
            <div class="education-section-heading">
              <h2>Our Vision</h2>
            </div>

            <p>
              At Guidanceplusni, we are an international recruitment firm dedicated to simplifying
              the process of hiring skilled workers from abroad. With a specific focus on the UK and
              Ireland, our goal is to bridge the gap between industry needs and the abundance of
              talent available globally.
            </p>
          </div>

          <div class="col-md-4">
            <div class="education-section-heading">
              <h2>A Complete Overseas Staffing Solution</h2>
            </div>

            <p>
              Embarking on an overseas recruitment journey can be overwhelming, but with
              Guidanceplusni, we provide a full-service package from beginning to end. From
              advertising job openings to placing your new staff on-site, we manage every aspect of
              the process. Our team will present you with a selection of qualified candidates
              tailored to your requirements, prepared to relocate to the UK.
            </p>
          </div>

          <div class="col-md-4">
            <div class="education-section-heading">
              <h2>A Complete Overseas Staffing Solution</h2>
            </div>

            <p>
              Embarking on an overseas recruitment journey can be overwhelming, but with
              Guidanceplusni, we provide a full-service package from beginning to end. From
              advertising job openings to placing your new staff on-site, we manage every aspect of
              the process. Our team will present you with a selection of qualified candidates
              tailored to your requirements, prepared to relocate to the UK.
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-5">
            <img src={coursesGrid2} alt="" />
          </div>
          <div class="col-md-7">
            <div class="education-event-organizer">
              <p>
                Screening and Validation : Our screening services are thorough, guaranteeing that
                all candidates possess the validated qualifications essential for the job.
              </p>
              <ul class="education-list-style-two">
                <li>
                  <i class="fa fa-check-square-o"></i> Utilizing cutting-edge welding test
                  facilities to swiftly and accurately assess candidate skill levels.
                </li>
                <li>
                  <i class="fa fa-check-square-o"></i> For roles necessitating skilled butchers, we
                  collaborate with Industrial Veterinary consultants to evaluate their abilities
                  from primal cutting and beyond, ensuring that our candidates meet your precise
                  standards regardless of your industry.
                </li>

                <li>
                  <i class="fa fa-check-square-o"></i>In eget aliquam odio. Quisque sodales eros ac.
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
          <div class="col-md-4">
            <div class="education-section-heading">
              <h2>Coordinating English Language Exams</h2>
            </div>

            <p>
              We aid candidates in preparing for and scheduling their English language proficiency
              exams to fulfill the Skilled Worker Visa prerequisites. Additionally, we facilitate
              the booking of a TB test if mandated by the Home Office, depending on their present
              location.
            </p>
          </div>

          <div class="col-md-4">
            <div class="education-section-heading">
              <h2>Visa Processing</h2>
            </div>

            <p>
              The administrative team at Guidanceplusni handles all your staff visa needs, from the
              initial application to the provision of biometrics.
            </p>

            <p>
              We offer personalized support to streamline the application process and minimize
              stress. Our seasoned Visa specialists are available to assist you every step of the
              way, ensuring a smooth and hassle-free experience. Additionally, we can provide
              guidance with your Home Office Sponsor license application if it is not already in
              place.
            </p>
          </div>

          <div class="col-md-4">
            <div class="education-section-heading">
              <h2>Travel Coordination</h2>
            </div>
            <p>
              Our team offers comprehensive travel assistance, handling everything from flight
              bookings and ground transportation arrangements to coordinating accommodations and
              creating detailed itineraries.
            </p>
            <p>
              We strive to exceed expectations in meeting all your candidate travel requirements,
              allowing you to concentrate on the crucial task of selecting the ideal candidate for
              your position.
            </p>
          </div>
        </div>
        <div className="row">
          <div class="col-md-6">
            <div class="education-section-heading">
              <h2>Skilled Worker Visa Assistance and Costs</h2>
            </div>
            <p>
              We are delighted to provide our clients with a thorough and effective Skilled Worker
              UK Visa service.
            </p>
            <p>
              Our team is available to assist you throughout the entire process, from the initial
              application to the successful visa issuance, handling all paperwork on your behalf.
            </p>
            <p>
              We recognize the significance of facilitating a seamless and hassle-free transition to
              prevent any unnecessary delays in bringing your staff on site.
            </p>
          </div>

          <div class="col-md-6">
            <div class="education-section-heading">
              <h2>Applying for a Sponsor Licence</h2>
            </div>
            <p>
              When hiring a migrant worker, a sponsor licence is typically required to legally
              employ someone from outside the UK.
            </p>
            <p>
              To obtain a sponsorship licence, you must demonstrate that you are a legitimate
              organisation operating lawfully in the UK. You need to showcase your awareness of and
              ability to fulfill your visa sponsorship responsibilities, with the necessary HR and
              recruitment systems and practices in place.
            </p>
            <p>
              We will assist in preparing your application and provide training on your
              responsibilities as a Sponsor Licence Holder.
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-7">
            <div class="education-event-organizer">
              <p>
                Usually, you are classified as a small sponsor if at least two of the following
                apply:
              </p>
              <ul class="education-list-style-two">
                <li>
                  <i class="fa fa-check-square-o"></i> Your business's annual turnover is £10.2
                  million or less
                </li>
                <li>
                  <i class="fa fa-check-square-o"></i> Your total business assets are valued at £5.1
                  million or less
                </li>

                <li>
                  <i class="fa fa-check-square-o"></i> You have 50 employees or fewer
                </li>
              </ul>

              <p>
                The current Home Office sponsor licence fee is £536 for small employers and £1,476
                for medium and large sponsors.
              </p>
            </div>
          </div>
          <div class="col-md-5">
            <img src={coursesGrid2} alt="" />
          </div>
        </div>

        <div className="row">
          <div class="col-md-6">
            <div class="education-section-heading">
              <h2>Issuing Certificate of Sponsorship (CoS) to Candidates</h2>
            </div>
            <p>
              Before sponsoring a migrant worker, you must assign a Certificate of Sponsorship to
              confirm visa requirements have been met. This is done through the Sponsor Management
              System (SMS) portal. The CoS reference is used by the new Skilled Worker for their
              visa application to the Home Office. The CoS can only be used for employment with your
              company throughout the visa duration.
            </p>
          </div>

          <div class="col-md-6">
            <div class="education-section-heading">
              <h2>Preparing Visa Application</h2>
            </div>
            <p>
              We will handle the visa application and all legal paperwork for the candidate. They
              need a valid passport and a clear TB test result if they have lived in a listed
              country within the last 6 months. Most Skilled Worker Visa applicants must meet the
              English language requirement by achieving a minimum B1 level on the Common European
              Framework of Reference. We assist in organizing this. The Immigration Skills Charge
              ranges from £364 to £1,000 per year, depending on employer size. This charge must be
              paid by the employer for the visa duration.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recruitment;
