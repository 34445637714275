import coursesGrid1 from "../extra-images/country/canada-1.jpg";
import coursesGrid2 from "../extra-images/country/uk.jpg";
import coursesGrid3 from "../extra-images/country/france.jpg";
import coursesGrid4 from "../extra-images/country/germany.jpg";
import coursesGrid5 from "../extra-images/country/dubai.jpg";
import coursesGrid6 from "../extra-images/country/ireland.jpg";
import coursesGrid7 from "../extra-images/country/malaysia.jpg";
import coursesGrid8 from "../extra-images/country/usa.jpg";
import coursesGrid9 from "../extra-images/country/australia.jpg";
import coursesGrid10 from "../extra-images/country/singapore.jpg";
import coursesGrid11 from "../extra-images/country/new-zealand.jpg";
import coursesGrid12 from "../extra-images/country/sweden.jpg";
import coursesGrid13 from "../extra-images/country/finland.jpg";
import coursesGrid14 from "../extra-images/country/denmark.jpg";
import coursesGrid15 from "../extra-images/country/malta.jpg";
import coursesGrid16 from "../extra-images/country/estonia.jpg";
import coursesGrid17 from "../extra-images/country/lithuania.jpg";
import coursesGrid18 from "../extra-images/country/netherland.jpg";
import coursesGrid19 from "../extra-images/country/poland.jpg";
import coursesGrid20 from "../extra-images/country/norway.jpg";

import university1 from "../images/university/Ulster.png";
import university2 from "../images/university/queens-university-belfast-logo.png";
import university3 from "../images/university/Heriot-Watt_University_logo.png";
import university4 from "../images/university/University_of_Hertfordshire_Logo_2019.png";
import university5 from "../images/university/Middlesex_University_London.png";
import university6 from "../images/university/INTO_Group_of_Institutions.png";
import university7 from "../images/university/Birmingham-City-University-BCU.png";
import university8 from "../images/university/South-Wales-University.png";
import university9 from "../images/university/De-Montfort-university.png";
import university10 from "../images/university/UNIVERSITY-OF-GLOUCESTERSHIRE.png";
import university11 from "../images/university/Oxford-International-Education-Group-OIGEC.png";
import university12 from "../images/university/University_for_the_creative_Arts.png";
import university13 from "../images/university/University-for-wolverhampton.png";
import university14 from "../images/university/Bath-spa-University.png";
import university15 from "../images/university/University-of-Liverpool.png";
import university16 from "../images/university/Canterbury-christ-church-university.png";
import university17 from "../images/university/University-of-Law.png";
import university18 from "../images/university/University-Of-Limerick.png";
import university19 from "../images/university/Maynooth-University.png";
import university20 from "../images/university/griffith-college-logo.png";
import university21 from "../images/university/Dundalk-Institute-of-Technology.png";
import university22 from "../images/university/Technological-university-Dublin.png";
import university23 from "../images/university/dublin-city-university.png";
import university24 from "../images/university/IBAT-College-Dublin.png";
import university25 from "../images/university/ESSCA.png";
import university26 from "../images/university/ESDES.png";
import university27 from "../images/university/Burgundy-School-of-Business.png";
import university28 from "../images/university/Neoma-Business-School.png";
import university29 from "../images/university/College-De-Paris.png";

import university30 from "../images/university/australia/1521699828-Excelsia-College.png";
import university31 from "../images/university/australia/1583875494_eitnewlogo.jpg";
import university32 from "../images/university/australia/1601630453-1.png";
import university33 from "../images/university/australia/4381.png";
import university34 from "../images/university/australia/54244.png";
import university35 from "../images/university/australia/AIT_Horizontal_Logo_Teal_Black_RGB.png";
import university36 from "../images/university/australia/Australian-College-of-Applied-Psychology-logo.jpg";
import university37 from "../images/university/australia/CQUniversity_Australia_logo.svg.png";
import university38 from "../images/university/australia/Curtin-Uni-logo-profile.jpg";
import university39 from "../images/university/australia/Fed_Unii-1024x270.jpg";
import university40 from "../images/university/australia/Flinders_University_Logo_Horizontal_RGB_Master.png";
import university41 from "../images/university/australia/Griffith_University_Logo_Variant_2022.svg.png";
import university42 from "../images/university/australia/QUT-International-logo.jpg";
import university43 from "../images/university/australia/Russo_Business_School.original.png";
import university44 from "../images/university/australia/TORRENS_UNIVERSITY_AUSTRALIA_PRIMARY_LOGO_ORANGE_GREY_RGB.png";
import university45 from "../images/university/australia/The-University-of-Western-Australia-logo-profile.jpg";
import university46 from "../images/university/australia/UNSW-Global-Logo_colour.jpg";
import university47 from "../images/university/australia/UTS-Logo-Syd.jpg";
import university48 from "../images/university/australia/UTS_College_Full_Logo_RGB-2.png";
import university49 from "../images/university/australia/UTS_College_Full_Logo_RGB.png";
import university50 from "../images/university/australia/Unknown.png";
import university51 from "../images/university/australia/UoA-College-horz-CMYK.png";
import university52 from "../images/university/australia/WSU_Logo_LeftAligned_Centred_RGB.png";
import university53 from "../images/university/australia/Western_Sydney_University_logo.png";
import university54 from "../images/university/australia/William-Angliss-Institute.png";
import university55 from "../images/university/australia/deakin-university4219.jpg";
import university56 from "../images/university/australia/images-10.png";
import university57 from "../images/university/australia/images-11.png";
import university58 from "../images/university/australia/images-12.png";
import university59 from "../images/university/australia/images-13.png";
import university60 from "../images/university/australia/images-14.png";
import university61 from "../images/university/australia/images-15.png";
import university62 from "../images/university/australia/images-16.png";
import university63 from "../images/university/australia/images-17.png";
import university64 from "../images/university/australia/images-18.png";
import university65 from "../images/university/australia/images-2.png";
import university66 from "../images/university/australia/images-3.png";
import university67 from "../images/university/australia/images-4.png";
import university68 from "../images/university/australia/images-5.png";
import university69 from "../images/university/australia/images-6.png";
import university70 from "../images/university/australia/images-7.png";
import university71 from "../images/university/australia/images-8.png";
import university72 from "../images/university/australia/images-9.png";
import university73 from "../images/university/australia/images.png";
import university74 from "../images/university/australia/james-cook-university-jcu-australia-vector-logo.png";
import university75 from "../images/university/australia/kisspng-deakin-university-warrnambool-waurn-ponds-stony-b-arkansas-state-university-5b1914724dd3d5.7034798315283702903188.jpg";
import university76 from "../images/university/australia/le-cordon-bleu-logo-vector.png";
import university77 from "../images/university/australia/logo_4.png";
import university78 from "../images/university/australia/macquarie-university5860.jpg";
import university79 from "../images/university/australia/new-logo.jpg";
import university80 from "../images/university/australia/tasmania.png";
import university81 from "../images/university/australia/the-university-of-newcastle-australia-vector-logo.png";
import university82 from "../images/university/australia/uoa-logo-col-vert.gif";

import university83 from "../images/university/canada/1_member_logo_COTR.jpeg";
import university84 from "../images/university/canada/1_member_logo_douglas.jpg";
import university85 from "../images/university/canada/1_member_logo_medicine_hat.jpg";
import university86 from "../images/university/canada/509px-University_of_Victoria_Logo_and_Wordmark.svg_.png";
import university87 from "../images/university/canada/Acadia-University-Logo.jpg";
import university88 from "../images/university/canada/Acsenda_School_of_Management_Logo_2013.jpg";
import university89 from "../images/university/canada/BrockU-2022-RGB-Top-1050x636.jpg";
import university90 from "../images/university/canada/CapU_logo_horizontal_rgb.png";
import university91 from "../images/university/canada/Coast-Mountain-College.png";
import university92 from "../images/university/canada/Confederation_college_logo.jpg";
import university93 from "../images/university/canada/Dalhousie University.jpg";
import university94 from "../images/university/canada/George-Brown-College-2.jpg";
import university95 from "../images/university/canada/Georgian_College_logo.svg.png";
import university96 from "../images/university/canada/Graphic-Standards-Guide_FDU-Logo-Plain-2C-Update23.jpg";
import university97 from "../images/university/canada/Lakeland_College_(Alberta)_logo.svg.png";
import university98 from "../images/university/canada/NYIT logo(1).png";
import university99 from "../images/university/canada/Queens-viguide-logos-1200x589-horizontal-2x_1.jpg";
import university100 from "../images/university/canada/STFX HORIZONTAL Full Colour_0.png";
import university101 from "../images/university/canada/Saskatchewan_Polytechnic_RLS.png";
import university102 from "../images/university/canada/Southern-Alberta-Institute-of-Technology-Logo.jpg";
import university103 from "../images/university/canada/St_Laurence_College_logo.png";
import university104 from "../images/university/canada/TMU_logo.svg.png";
import university105 from "../images/university/canada/UCW_LOGO.jpg";
import university106 from "../images/university/canada/UNBC-logo.png.png";
import university107 from "../images/university/canada/University-of-Guelph-Logo.png";
import university108 from "../images/university/canada/University-of-Manitoba-350x169.png";
import university109 from "../images/university/canada/VIU_standard_logo.jpg";
import university110 from "../images/university/canada/adler un.jfif";
import university111 from "../images/university/canada/alexander cllg.png";
import university112 from "../images/university/canada/assiniboine cllg.png";
import university113 from "../images/university/canada/bow valley.png";
import university114 from "../images/university/canada/brescia_university_college_logo_3e6e08f890.png";
import university115 from "../images/university/canada/british coloumbia un.png";
import university116 from "../images/university/canada/cambrian cllg.png";
import university117 from "../images/university/canada/canadore cllg.png";
import university118 from "../images/university/canada/cape breton un.png";
import university119 from "../images/university/canada/centennial cllg.png";
import university120 from "../images/university/canada/conestoga cllg.png";
import university121 from "../images/university/canada/coquitlam cllg.png";
import university122 from "../images/university/canada/crandall un.png";
import university123 from "../images/university/canada/durham college.png";
import university124 from "../images/university/canada/fanshawe-college-vector-logo.png";
import university125 from "../images/university/canada/fleming cllg.png";
import university126 from "../images/university/canada/international business un.png";
import university127 from "../images/university/canada/justice institute.png";
import university128 from "../images/university/canada/keyano cllg.jfif";
import university129 from "../images/university/canada/kings un.png";
import university130 from "../images/university/canada/kwantlen un.jfif";
import university131 from "../images/university/canada/la salle cllg.png";
import university132 from "../images/university/canada/lakehead un.png";
import university133 from "../images/university/canada/lambton cllg.png";
import university134 from "../images/university/canada/laurier un.png";
import university135 from "../images/university/canada/le cordon bleu.png";
import university136 from "../images/university/canada/lethbridge un.png";
import university137 from "../images/university/canada/lethbridge-college-logo-F93DF7BD10-seeklogo.com.png";
import university138 from "../images/university/canada/logo-Mount-Saint-Vincent-University.png";
import university139 from "../images/university/canada/logo-University_of_the_Fraser_Valley-2017_09_13_12_50_10.png";
import university140 from "../images/university/canada/loyalist cllg.png";
import university141 from "../images/university/canada/manitoba.jfif";
import university142 from "../images/university/canada/mcewan un.png";
import university143 from "../images/university/canada/mcgill-university-logo-png-transparent-cropped.png";
import university144 from "../images/university/canada/mount-allison-university-logo-C8DF24AB15-seeklogo.com.png";
import university145 from "../images/university/canada/mun_ca.jpg";
import university146 from "../images/university/canada/new caledonia.png";
import university147 from "../images/university/canada/niagara cllg.png";
import university148 from "../images/university/canada/nipissing un.png";
import university149 from "../images/university/canada/norquest cllg.png";
import university150 from "../images/university/canada/north island cllg.png";
import university151 from "../images/university/canada/northern cllg.png";
import university152 from "../images/university/canada/northern lights cllg.jfif";
import university153 from "../images/university/canada/ontario_tech_university.png";
import university154 from "../images/university/canada/portage cllg.png";
import university155 from "../images/university/canada/regina un.png";
import university156 from "../images/university/canada/royal-roads-university-logo.png";
import university157 from "../images/university/canada/salle.png";
import university158 from "../images/university/canada/sault cllg.png";
import university159 from "../images/university/canada/selkirk cllg.png";
import university160 from "../images/university/canada/seneca academy.png";
import university161 from "../images/university/canada/sheridan cllg.png";
import university162 from "../images/university/canada/simon fraser un.png";
import university163 from "../images/university/canada/st thomas un.png";
import university164 from "../images/university/canada/the-university-of-winnipeg-vector-logo.png";
import university165 from "../images/university/canada/thompson rivers un.png";
import university166 from "../images/university/canada/toronto film school.png";
import university167 from "../images/university/canada/toronto school of mgmnt.png";
import university168 from "../images/university/canada/trinity western un.png";
import university169 from "../images/university/canada/trnt un.png";
import university170 from "../images/university/canada/university-of-new-brunswick-unb-logo-vector-10.png";
import university171 from "../images/university/canada/university-of-prince-edward-island-vector-logo.png";
import university172 from "../images/university/canada/university-of-windsor-logo-vector.png";
import university173 from "../images/university/canada/uwaterloologo.png";
import university174 from "../images/university/canada/vancouver cllg.png";
import university175 from "../images/university/canada/vancouver film school.png";
import university176 from "../images/university/canada/vancouver premier college.png";
import university177 from "../images/university/canada/yorkville-university-logo-F9DFEDA145-seeklogo.com.png";

import university178 from "../images/university/denmark/Aarhus_University_logo.svg.png";
import university179 from "../images/university/denmark/images.png";
import university180 from "../images/university/denmark/sdulogo-uk-sort-feb2019.png";

import university181 from "../images/university/dubai/640px-HULT_IBS_Logo_Outline_Black_(cropped).png";
import university182 from "../images/university/dubai/BirminghamUni-Logo-Membership.png";
import university183 from "../images/university/dubai/EAHM_LOGO_without_academic_with_ehl-01.jpg";
import university184 from "../images/university/dubai/Logo-Taylors-University.png";
import university185 from "../images/university/dubai/Swinburne-Sarawak.jpg";
import university186 from "../images/university/dubai/Uos_international_malaysia_Logo_Uniblue_Rgb_900px_w_72ppi.png";
import university187 from "../images/university/dubai/abudhabi un.png";
import university188 from "../images/university/dubai/american university.png";
import university189 from "../images/university/dubai/asia pacific un.jfif";
import university190 from "../images/university/dubai/canadian un.png";
import university191 from "../images/university/dubai/de montfort un.png";
import university192 from "../images/university/dubai/dubai-png.png";
import university193 from "../images/university/dubai/global business school.png";
import university194 from "../images/university/dubai/heriot un.png";
import university195 from "../images/university/dubai/htmi dubai.jfif";
import university196 from "../images/university/dubai/inst of management dubai.png";
import university197 from "../images/university/dubai/j244-19_webamends-logo-resizing_767px-wide_v1_eau.jpg";
import university198 from "../images/university/dubai/le cordon malaysia.png";
import university199 from "../images/university/dubai/logo-curtin.png";
import university200 from "../images/university/dubai/manipal dubai.jpg";
import university201 from "../images/university/dubai/manipal.png";
import university202 from "../images/university/dubai/modul un.jfif";
import university203 from "../images/university/dubai/monash.png";
import university204 from "../images/university/dubai/murdoch un.png";
import university205 from "../images/university/dubai/nottingham un.jfif";
import university206 from "../images/university/dubai/png-transparent-heriot-watt-university-hd-logo.png";
import university207 from "../images/university/dubai/rochester un.jpg";
import university208 from "../images/university/dubai/stirling un.png";
import university209 from "../images/university/dubai/ucsi un.png";
import university210 from "../images/university/dubai/un kuala lumpur.png";
import university211 from "../images/university/dubai/wollongong dubai un.png";
import university213 from "../images/university/dubai/wollongong un.png";

import university214 from "../images/university/estonia/1526565612_tsllin.jpg";
import university215 from "../images/university/estonia/images (3).png";
import university216 from "../images/university/estonia/EUROACADEMY_PUBLISHING.png";
import university217 from "../images/university/estonia/images (4).png";
import university218 from "../images/university/estonia/estonian-business-school-35-logo.png";
import university219 from "../images/university/estonia/images (5).png";
import university220 from "../images/university/estonia/images (1).png";
import university221 from "../images/university/estonia/images.png";
import university222 from "../images/university/estonia/images (2).png";

import university223 from "../images/university/finland/1552494889_1538656959_tre_la23_uas____en_b3___rgb.jpg";
import university224 from "../images/university/finland/615d103e2155f493031803.png";
import university225 from "../images/university/finland/6eddf7812ee57dcc5671d7132cf892ef1.jpg";
import university226 from "../images/university/finland/Tampere_University_logo.png";
import university227 from "../images/university/finland/VAMK_logo.png";
import university228 from "../images/university/finland/Vaasan-Yliopisto-logo-vaaka.jpg";
import university229 from "../images/university/finland/Xamk_logo_english_low_res.png";
import university230 from "../images/university/finland/hh_-600x600.png";
import university231 from "../images/university/finland/images (1).png";
import university232 from "../images/university/finland/images (2).png";
import university233 from "../images/university/finland/images (3).png";
import university234 from "../images/university/finland/images (4).png";
import university235 from "../images/university/finland/images (5).png";
import university236 from "../images/university/finland/images.png";
import university237 from "../images/university/finland/kisspng-university-of-eastern-finland-joensuu-mikkeli-univ-logo-cs-1-6-5b459e4eb66253.8616076215312891667471.jpg";
import university238 from "../images/university/finland/small_Hanken_School_of_Economics_c095b45553_2752f0a305.png";

import university239 from "../images/university/france/14501.svg";
import university240 from "../images/university/france/1495721021_ESIGELEC1.png.jpeg";
import university241 from "../images/university/france/1672989019869.jpeg";
import university242 from "../images/university/france/2022_logo_esdes_bleu_sans_fond-1739x576.png";
import university243 from "../images/university/france/641047d1645aefa0e5a3bafb_airways logo with slogan.png";
import university244 from "../images/university/france/BSB_logo_burgundy.jpg";
import university245 from "../images/university/france/EM_Normandie_Business_School_lo.2e16d0ba.fill-16000x9000.png";
import university246 from "../images/university/france/Istituto-Marangoni-logo.png";
import university247 from "../images/university/france/Kedgebs-logo.png";
import university248 from "../images/university/france/LOGO-BS_0.jpg";
import university249 from "../images/university/france/Logo-pole-leonard-vinci-n.png";
import university250 from "../images/university/france/Logo_ESSCA_school_of_managment.png";
import university251 from "../images/university/france/Logo_IDRAC_Business_School.jpg";
import university252 from "../images/university/france/Rennes_School_of_Business_Unframed_Thinking.jpg";
import university253 from "../images/university/france/SKEMA_Business_School_s_logo.png";
import university254 from "../images/university/france/cdp-logo-dore-hd-1920x823.jpg";
import university255 from "../images/university/france/images-2.png";
import university256 from "../images/university/france/images.jpeg";
import university257 from "../images/university/france/images.png";
import university258 from "../images/university/france/le-cordon-bleu-logo-vector.png";
import university259 from "../images/university/france/le-cordon-bleu.png";
import university260 from "../images/university/france/neoma_logotype_rvb.png";
import university261 from "../images/university/france/ppa_master_logo-f.png";
import university262 from "../images/university/france/preloader-logo.png";

import university263 from "../images/university/germany/1920_iu-logo-en-black-rgb-vertikal.png";
import university264 from "../images/university/germany/Accadis_Hochschule_Bad_Homburg_logo.svg.png";
import university265 from "../images/university/germany/Arden-University-Logo.png";
import university266 from "../images/university/germany/CU_Logotype_RGB_navy_blue_red.png";
import university267 from "../images/university/germany/EU Business School (Germany).png";
import university268 from "../images/university/germany/EURASIA-LOGO_eingangsschilder.jpg";
import university269 from "../images/university/germany/GISMA_Business_School_logo.png";
import university270 from "../images/university/germany/ISM_logo_monochrome.png";
import university271 from "../images/university/germany/Kurzlogo_EBS_2768U.jpg";
import university272 from "../images/university/germany/Logo_CBS_International_Business_School.png";
import university273 from "../images/university/germany/New_European_College_Logo_2015.jpg";
import university274 from "../images/university/germany/bsbi.jpg";
import university275 from "../images/university/germany/csm_SocialSharing_Bildung_MobileUniversity_light_e454c6bbc2.jpg";
import university276 from "../images/university/germany/eoe applied science.png";
import university277 from "../images/university/germany/images.png";
import university278 from "../images/university/germany/ism-logo-schema.png";
import university279 from "../images/university/germany/lancaster university leipzig.png";
import university280 from "../images/university/germany/logo_fom_quer.png";
import university281 from "../images/university/germany/macromedia-university-logo-axial-rgb-blueberry-989x1024.png";

import university282 from "../images/university/Ireland/Image-University_of_Limerick-Emblem.png";
import university283 from "../images/university/Ireland/Image-empty-state.png";
import university284 from "../images/university/Ireland/Logo-UGalway-2.jpg";
import university285 from "../images/university/Ireland/Maynooth_University_Logo_colour_RGB.png";
import university286 from "../images/university/Ireland/SETU_Ireland_logo.png";
import university287 from "../images/university/Ireland/TUS-midwest.png";
import university288 from "../images/university/Ireland/University-College-Dublin-1200px-logo.jpg";
import university290 from "../images/university/Ireland/cropped-logo-1024x191.png";
import university291 from "../images/university/Ireland/difc-logo-text-change-01.jpg";
import university292 from "../images/university/Ireland/dkitlogo-fullcolour-1.jpg";
import university293 from "../images/university/Ireland/dublin-city-university-dcu-logo-104DD16BFF-seeklogo.com.png";
import university294 from "../images/university/Ireland/images copy 2.png";
import university295 from "../images/university/Ireland/images copy.png";
import university296 from "../images/university/Ireland/images-2 copy.png";
import university297 from "../images/university/Ireland/images-2.png";
import university298 from "../images/university/Ireland/images.png";

import university299 from "../images/university/Lithuania/ISM-logo-EN-navy-01.png";
import university300 from "../images/university/Lithuania/images-3.png";
import university301 from "../images/university/Lithuania/Logo_ehu_en_horizontal-1-2.png";
import university302 from "../images/university/Lithuania/images-4.png";
import university303 from "../images/university/Lithuania/Logo_ehu_en_horizontal-1.png";
import university304 from "../images/university/Lithuania/images.png";
import university305 from "../images/university/Lithuania/Logo_vilnius_university-2.png";
import university306 from "../images/university/Lithuania/logo.png";
import university307 from "../images/university/Lithuania/Logo_vilnius_university.png";
import university308 from "../images/university/Lithuania/images-2.png";
import university309 from "../images/university/Lithuania/naujiena.jpg";

import university310 from "../images/university/malaysia/Logo-Taylors-University.png";
import university311 from "../images/university/malaysia/Swinburne-Sarawak.jpg";
import university312 from "../images/university/malaysia/Uos_international_malaysia_Logo_Uniblue_Rgb_900px_w_72ppi.png";
import university313 from "../images/university/malaysia/heriot un.png";
import university314 from "../images/university/malaysia/le cordon malaysia.png";
import university315 from "../images/university/malaysia/manipal.png";
import university316 from "../images/university/malaysia/monash.png";
import university317 from "../images/university/malaysia/ucsi un.png";
import university318 from "../images/university/malaysia/un kuala lumpur.png";
import university319 from "../images/university/malaysia/wollongong un.png";

import university320 from "../images/university/malta/gbs_malta__logo-2x.png";
import university321 from "../images/university/malta/images.png";

import university322 from "../images/university/netherlands/1631764467phpqPtYza.jpeg";
import university323 from "../images/university/netherlands/Christelijke_Hogeschool_Ede_(Christian_University_of_Applied_Sciences).png";
import university324 from "../images/university/netherlands/amsterdam un of science.png";
import university325 from "../images/university/netherlands/hu un.png";
import university326 from "../images/university/netherlands/images (1).png";
import university327 from "../images/university/netherlands/images (2).png";
import university328 from "../images/university/netherlands/images (3).png";
import university329 from "../images/university/netherlands/images (4).png";
import university330 from "../images/university/netherlands/images (5).png";
import university331 from "../images/university/netherlands/images (6).png";
import university332 from "../images/university/netherlands/images (7).png";
import university333 from "../images/university/netherlands/images (8).png";
import university334 from "../images/university/netherlands/images.png";

import university335 from "../images/university/newzeland/54245.png";
import university336 from "../images/university/newzeland/8732 nmit_LOGO FAMILY_Oct15 Full Justified RGB - 500px wide.jpg";
import university337 from "../images/university/newzeland/AUT-logo_600.png";
import university338 from "../images/university/newzeland/Ara-Landscape-Logo_1553137699-768x310.png";
import university339 from "../images/university/newzeland/CaptureMITlogo.png.png";
import university340 from "../images/university/newzeland/Eastern-Institute-of-Technology.png";
import university341 from "../images/university/newzeland/Lincoln-University-logo.png.png";
import university342 from "../images/university/newzeland/Southern_Institute_of_Technology_(New_Zealand)_logo.jpg";
import university343 from "../images/university/newzeland/TOI OHOMAI WM_Wordmark B RT_RGB.jpg";
import university344 from "../images/university/newzeland/The-University-of-Waikato-logo-2023.jpg";
import university345 from "../images/university/newzeland/Unitec_logo_600.png.png";
import university346 from "../images/university/newzeland/University-of-auckland.png";
import university347 from "../images/university/newzeland/VUW Logo.png";
import university348 from "../images/university/newzeland/asfh-01.png";
import university349 from "../images/university/newzeland/download-46.jpg";
import university350 from "../images/university/newzeland/images (1).png";
import university351 from "../images/university/newzeland/images (2).png";
import university352 from "../images/university/newzeland/images (3).png";
import university353 from "../images/university/newzeland/images (4).png";
import university354 from "../images/university/newzeland/images.png";
import university355 from "../images/university/newzeland/major-sponsor-1-400x388.png";
import university356 from "../images/university/newzeland/pihms-logo-transparent (1).png";
import university357 from "../images/university/newzeland/pihms-logo-transparent.png";
import university358 from "../images/university/newzeland/university-of-canterbury-logo-CCECBE272F-seeklogo.com.png";
import university359 from "../images/university/newzeland/unnamed (1).jpg";
import university360 from "../images/university/newzeland/unnamed.jpg";
import university361 from "../images/university/newzeland/wintec-logo-dark.png.png";
import university362 from "../images/university/newzeland/witt_logo.png.png";

import university363 from "../images/university/norway/images (1).png";
import university364 from "../images/university/norway/images (2).png";
import university365 from "../images/university/norway/images (6).png";
import university366 from "../images/university/norway/images.png";

import university367 from "../images/university/poland/1664867373uni_profile_93415.jpg";
import university368 from "../images/university/poland/images.png";
import university369 from "../images/university/poland/9ac7aa46320309.58500922803fa.jpg";
import university370 from "../images/university/poland/logo_ul_h_en_rgb.jpg";

import university371 from "../images/university/singapore/20220216014819.dIDDQR.png";
import university372 from "../images/university/singapore/346daa28-4bea-4040-8d7f-8b78fb1b1ccd.jpg";
import university373 from "../images/university/singapore/Easb_logo.png";
import university374 from "../images/university/singapore/National_University_of_Singapore_logo_NUS.png";
import university375 from "../images/university/singapore/S-P-Jain_Final_logo_color.jpg";
import university376 from "../images/university/singapore/dimensions-international-college.jpg";
import university377 from "../images/university/singapore/image.png";
import university378 from "../images/university/singapore/images (1).png";
import university379 from "../images/university/singapore/images (2).png";
import university380 from "../images/university/singapore/images (3).png";
import university381 from "../images/university/singapore/images (4).png";
import university382 from "../images/university/singapore/images (5).png";
import university383 from "../images/university/singapore/images.png";
import university384 from "../images/university/singapore/institute_2634_logo1609142743.png";
import university385 from "../images/university/singapore/logo.png";
import university386 from "../images/university/singapore/png-clipart-raffles-design-institute-raffles-college-of-design-and-commerce-graphic-design-design.png";

import university387 from "../images/university/sweden/1484571783_JU_A_sv.jpg";
import university388 from "../images/university/sweden/kristianstad un.png";
import university389 from "../images/university/sweden/Linneuniversitetet_logo.png";
import university390 from "../images/university/sweden/skovde un.png";
import university391 from "../images/university/sweden/dalarna un.png";
import university392 from "../images/university/sweden/uppsala un.png";
import university393 from "../images/university/sweden/halmstad un.png";

import university394 from "../images/university/UK/1724312092_uclan_primary_logo_digital-1.png";
import university395 from "../images/university/UK/1724776682-Aberystwyth-University.jpg";
import university396 from "../images/university/UK/23e9b69fb336cca3d33731aa80082b9e.png";
import university397 from "../images/university/UK/6524626a22b6e.png";
import university398 from "../images/university/UK/About-Crest2.jpg";
import university399 from "../images/university/UK/Bath_Spa_University_logo.svg.png";
import university400 from "../images/university/UK/BrandEvolution-WhiteonNottinghamBlue-Cropped-450x173.png";
import university401 from "../images/university/UK/CCCU-logo-2col-cmyk.png";
import university402 from "../images/university/UK/Courtauld-logo.jpg";
import university403 from "../images/university/UK/Goldsmiths-University-of-London-logo.jpg";
import university404 from "../images/university/UK/Northumbria_University_Logo.png";
import university405 from "../images/university/UK/RGU_logo.jpg";
import university406 from "../images/university/UK/Secondary logo 2.jpg";
import university407 from "../images/university/UK/University-of-Reading-Logo.png";
import university408 from "../images/university/UK/University_for_the_Creative_Arts_(UCA)_logo.png";
import university409 from "../images/university/UK/University_of_Portsmouth_Logo.png";
import university410 from "../images/university/UK/University_of_Southampton_Logo.png";
import university411 from "../images/university/UK/University_of_Westminster_Logo.jpg";
import university412 from "../images/university/UK/Untitled-design-(30)-header_normal.png";
import university413 from "../images/university/UK/WOLVERHAMPTON-LOGO.jpeg";
import university414 from "../images/university/UK/avatars-000617661867-qpt7lq-original.jpg";
import university415 from "../images/university/UK/bcu-birmingham-city-university5078.jpg";
import university416 from "../images/university/UK/cardiff1.png";
import university417 from "../images/university/UK/glos_uni_crest_eaffc56448.jpg";
import university418 from "../images/university/UK/images (10).png";
import university419 from "../images/university/UK/images (11).png";
import university420 from "../images/university/UK/images (12).png";
import university421 from "../images/university/UK/images (13).png";
import university422 from "../images/university/UK/images (14).png";
import university423 from "../images/university/UK/images (15).png";
import university424 from "../images/university/UK/images (16).png";
import university425 from "../images/university/UK/images (17).png";
import university426 from "../images/university/UK/images (18).png";
import university427 from "../images/university/UK/images (19).png";
import university428 from "../images/university/UK/images (20).png";
import university429 from "../images/university/UK/images (21).png";
import university430 from "../images/university/UK/images (22).png";
import university431 from "../images/university/UK/images (23).png";
import university432 from "../images/university/UK/images (24).png";
import university433 from "../images/university/UK/images (25).png";
import university434 from "../images/university/UK/images (26).png";
import university435 from "../images/university/UK/images (27).png";
import university436 from "../images/university/UK/images (28).png";
import university437 from "../images/university/UK/images (29).png";
import university438 from "../images/university/UK/images (30).png";
import university439 from "../images/university/UK/images (31).png";
import university440 from "../images/university/UK/images (32).png";
import university441 from "../images/university/UK/images (33).png";
import university442 from "../images/university/UK/images (34).png";
import university443 from "../images/university/UK/images (35).png";
import university444 from "../images/university/UK/images (36).png";
import university445 from "../images/university/UK/images (37).png";
import university446 from "../images/university/UK/images (38).png";
import university447 from "../images/university/UK/images (39).png";
import university448 from "../images/university/UK/images (40).png";
import university449 from "../images/university/UK/images (41).png";
import university450 from "../images/university/UK/images (42).png";
import university451 from "../images/university/UK/images (43).png";
import university452 from "../images/university/UK/images (44).png";
import university453 from "../images/university/UK/images (45).png";
import university454 from "../images/university/UK/images (46).png";
import university455 from "../images/university/UK/images (47).png";
import university456 from "../images/university/UK/images (48).png";
import university457 from "../images/university/UK/images (6).png";
import university458 from "../images/university/UK/images (7).png";
import university459 from "../images/university/UK/images (8).png";
import university460 from "../images/university/UK/images (9).png";
import university461 from "../images/university/UK/kisspng-aston-university-engineering-academy-reutlingen-un-5b4d93b135f889.5572826915318107372211.jpg";
import university462 from "../images/university/UK/kisspng-university-of-dundee-logo-organization-school-5bfe6a01ef7f88.498380941543399937981.jpg";
import university463 from "../images/university/UK/kisspng-university-of-east-anglia-university-of-waterloo-u-5afc2c0cc50b94.5995437615264757888071.jpg";
import university464 from "../images/university/UK/kisspng-university-of-essex-academic-degree-master-s-degre-5aff1b22471984.5777163415266680662912.jpg";
import university465 from "../images/university/UK/lancaster-university2308.jpg";
import university466 from "../images/university/UK/lu-logo-proposed1.png";
import university467 from "../images/university/UK/png-clipart-trent-university-durham-gta-fleming-college-council-of-ontario-universities-algoma-university-university-icon.png";
import university468 from "../images/university/UK/png-transparent-bank-london-south-bank-university-logo-text-area.png";
import university469 from "../images/university/UK/png-transparent-the-university-of-greenwich-lecturer-student-student.png";
import university470 from "../images/university/UK/strath-crest-600x300.jpg";
import university471 from "../images/university/UK/swansea-university-logo-vector.png";
import university472 from "../images/university/UK/the-university-of-south-wales-logo-6642E297DF-seeklogo.com.png";
import university473 from "../images/university/UK/university-of-aberdeen-logo-vector.png";
import university474 from "../images/university/UK/university-of-leeds-logo.png";
import university475 from "../images/university/UK/university-of-northampton-logo-vector.png";
import university476 from "../images/university/UK/winchester_logo_new_2017_0.png";
import university477 from "../images/university/USA/122606_BAU_DC_logo2.png";
import university478 from "../images/university/USA/1690437370-Navitas-Group-Queens-College,-City-University-of-New-York.webp";
import university479 from "../images/university/USA/230330_SMC logos_FINAL-01.png";
import university480 from "../images/university/USA/635806ec222fee6778b4e085_Murray 400x400.png";
import university481 from "../images/university/USA/7-great-reasons-attend-western-new-england-university.png";
import university482 from "../images/university/USA/98ae5f19-8b20-4361-8003-ea11f96ed766.png";
import university483 from "../images/university/USA/Adelphi-Primary-Logo.png";
import university484 from "../images/university/USA/Auburn_University_at_Montgomery_(logo).png";
import university485 from "../images/university/USA/CSU-Academic-Logo-blue7503_gold295-1024x411.jpg";
import university486 from "../images/university/USA/Culinary_Institute_of_America_Logo.png";
import university487 from "../images/university/USA/Devry-university-logo.webp";
import university488 from "../images/university/USA/Drew-University-logo.gif";
import university489 from "../images/university/USA/Drexel-logo.png";
import university490 from "../images/university/USA/EmbryΓÇôRiddle_Aeronautical_University-Logo.wine.png";
import university491 from "../images/university/USA/Florida_Atlantic_University_logo.svg.png";
import university492 from "../images/university/USA/Full Vertical_CSU Green_Fresh Green_CMYK_Update.jpg";
import university493 from "../images/university/USA/GRC_logo.png";
import university494 from "../images/university/USA/George_Mason_University_logo.svg.png";
import university495 from "../images/university/USA/George_Washington_University_wordmark.svg.png";
import university496 from "../images/university/USA/Graphic-Standards-Guide_FDU-Logo-Plain-2C-Update23.jpg";
import university497 from "../images/university/USA/HULT_IBS_Logo_Outline_Black_(cropped).png";
import university498 from "../images/university/USA/Johnson-&-Wales-University-logo.jpg";
import university499 from "../images/university/USA/LIU-Brooklyn-logo.jpg";
import university500 from "../images/university/USA/MCPHS_Logo_Red_3Lines_LeftGreySeal.png";
import university501 from "../images/university/USA/MTSU_Wordmark.png";
import university502 from "../images/university/USA/Marist_College_Seal_-_Vector.svg.png";
import university503 from "../images/university/USA/Midway_University_logo.jpg";
import university504 from "../images/university/USA/Monroe_College_logo.svg.png";
import university505 from "../images/university/USA/NAU_Primary_Logo.png";
import university506 from "../images/university/USA/NYFA_PE_sq_B_RGB-600x600.jpg";
import university507 from "../images/university/USA/New_England_Institute_Of_Technology_61973cd6ba.jpg";
import university508 from "../images/university/USA/Primary YU Shield C.JPG";
import university509 from "../images/university/USA/PrimaryLogo3color-768x594.jpg";
import university510 from "../images/university/USA/R-Nebraska_N-name-RGB.png";
import university511 from "../images/university/USA/RGB_color_NYIT_three-lines.png";
import university512 from "../images/university/USA/Richard_Bland_College_of_William_&_Mary_-_Logo.png";
import university513 from "../images/university/USA/SAGINAW-VALLEY-STATE-UNIVERSITY-Logo.jpg";
import university514 from "../images/university/USA/SIUE-image3.png.webp";
import university515 from "../images/university/USA/SNHU-Logo-1024x447.webp";
import university516 from "../images/university/USA/Santa_Ana_College_logo.svg.png";
import university517 from "../images/university/USA/Savannah_College_of_Art_and_Design.png";
import university518 from "../images/university/USA/Seattle_University_logo.png";
import university519 from "../images/university/USA/StandardColor360x150.jpg";
import university520 from "../images/university/USA/Tennessee_Technological_University_seal.svg.png";
import university521 from "../images/university/USA/The_University_of_California_Logo_2.png";
import university522 from "../images/university/USA/The_University_of_Memphis_logo.png";
import university523 from "../images/university/USA/The_University_of_Toledo.svg.png";
import university524 from "../images/university/USA/Tiffin_University_logo.svg.png";
import university525 from "../images/university/USA/Troy_University_logo.png";
import university526 from "../images/university/USA/U-of-Tampa.jpg";
import university527 from "../images/university/USA/UAlbanyMark_F_S02A_HEX_Gold_Black_300ppi_.jpg";
import university528 from "../images/university/USA/UCO-logo.gif";
import university529 from "../images/university/USA/University Y 2 line Serif vert logo_0.png";
import university530 from "../images/university/USA/University-of-Louisville-Logo.png";
import university531 from "../images/university/USA/University-of-St.-Thomas.jpg";
import university532 from "../images/university/USA/UniversityOfBridgeportLogo.svg.png";
import university533 from "../images/university/USA/University_of_Alabama_(logo).png";
import university534 from "../images/university/USA/University_of_Dayton.svg.png";
import university535 from "../images/university/USA/University_of_Illinois_Chicago_wordmark.png";
import university536 from "../images/university/USA/University_of_Maryland,_Baltimore_County_logo.svg.png";
import university537 from "../images/university/USA/University_of_MichiganΓÇôFlint_logo.svg.png";
import university538 from "../images/university/USA/University_of_New_Hampshire_logo.svg.png";
import university539 from "../images/university/USA/University_of_Suffolk_Logo.png";
import university540 from "../images/university/USA/University_of_the_Pacific_logo.png";
import university541 from "../images/university/USA/WSU-lockup-horz-4c-1.jpg";
import university542 from "../images/university/USA/WWU_MakeWaves_Stacked_RGB (1).png";
import university543 from "../images/university/USA/Webster_University_Logo.svg.png";
import university544 from "../images/university/USA/Western_Illinois_University_logo.png";
import university545 from "../images/university/USA/__College+Logo.png";
import university546 from "../images/university/USA/ball-state-logo-B78B24B97F-seeklogo.com.png";
import university547 from "../images/university/USA/bayloruniversity_social-media.png";
import university548 from "../images/university/USA/bddec1e4632079e3085179fff5e0760d.jpg";
import university549 from "../images/university/USA/brand-color-combo-blue.png";
import university550 from "../images/university/USA/brand-gallery-word-mark_primary.png";
import university551 from "../images/university/USA/callu.png";
import university552 from "../images/university/USA/cf2bb14c-0760-4111-ac99-0baf609e08b8-DSU_UniversityLogo_Stacked_Primary_BlueWordmark_FC.png";
import university553 from "../images/university/USA/contra-costa-college.png";
import university554 from "../images/university/USA/dallas-baptist-university-dbu-logo-BDBE0961FE-seeklogo.com.png";
import university555 from "../images/university/USA/fhdai-logo-hor-rgb.png";
import university556 from "../images/university/USA/fit.webp";
import university557 from "../images/university/USA/fiu-logo.png";
import university558 from "../images/university/USA/florida-institute-of-technology-logo.jpg";
import university559 from "../images/university/USA/full-sail-university-logo-D75CD349E0-seeklogo.com.png";
import university560 from "../images/university/USA/gic-logo-dark-300x117.png";
import university561 from "../images/university/USA/horizontal_logo.jpg";
import university562 from "../images/university/USA/illinois-state-university.svg";
import university563 from "../images/university/USA/images copy 2.png";
import university564 from "../images/university/USA/images copy 3.png";
import university565 from "../images/university/USA/images copy 4.png";
import university566 from "../images/university/USA/images copy.jpeg";
import university567 from "../images/university/USA/images copy.png";
import university568 from "../images/university/USA/images-10 copy 2.png";
import university569 from "../images/university/USA/images-10 copy 3.png";
import university570 from "../images/university/USA/images-12 copy 2.png";
import university571 from "../images/university/USA/images-12 copy.png";
import university572 from "../images/university/USA/images-12.png";
import university573 from "../images/university/USA/images-13 copy 2.png";
import university574 from "../images/university/USA/images-13 copy.png";
import university575 from "../images/university/USA/images-13.png";
import university576 from "../images/university/USA/images-14 copy 2.png";
import university577 from "../images/university/USA/images-14 copy.png";
import university578 from "../images/university/USA/images-14.png";
import university579 from "../images/university/USA/images-15 copy.png";
import university580 from "../images/university/USA/images-15.png";
import university581 from "../images/university/USA/images-16 copy.png";
import university582 from "../images/university/USA/images-16.png";
import university583 from "../images/university/USA/images-17.png";
import university584 from "../images/university/USA/images-18.png";
import university585 from "../images/university/USA/images-19.png";
import university586 from "../images/university/USA/images-2 copy 2.png";
import university587 from "../images/university/USA/images-2 copy 3.png";
import university588 from "../images/university/USA/images-2 copy 4.png";
import university589 from "../images/university/USA/images-2 copy.jpeg";
import university590 from "../images/university/USA/images-2 copy.png";
import university591 from "../images/university/USA/images-2.jpeg";
import university592 from "../images/university/USA/images-2.png";
import university593 from "../images/university/USA/images-20.png";
import university594 from "../images/university/USA/images-21.png";
import university595 from "../images/university/USA/images-22.png";
import university596 from "../images/university/USA/images-23.png";
import university597 from "../images/university/USA/images-24.png";
import university598 from "../images/university/USA/images-25.png";
import university599 from "../images/university/USA/images-26.png";
import university600 from "../images/university/USA/images-3 copy 2.png";
import university601 from "../images/university/USA/images-3 copy 3.png";
import university602 from "../images/university/USA/images-3 copy 4.png";
import university603 from "../images/university/USA/images-3 copy.png";
import university604 from "../images/university/USA/images-3.png";
import university605 from "../images/university/USA/images-4 copy 2.png";
import university606 from "../images/university/USA/images-4 copy 3.png";
import university607 from "../images/university/USA/images-4 copy 4.png";
import university608 from "../images/university/USA/images-4 copy.png";
import university609 from "../images/university/USA/images-4.png";
import university610 from "../images/university/USA/images-5 copy 2.png";
import university611 from "../images/university/USA/images-5 copy 3.png";
import university612 from "../images/university/USA/images-5 copy 4.png";
import university613 from "../images/university/USA/images-5 copy.png";
import university614 from "../images/university/USA/images-5.png";
import university615 from "../images/university/USA/images-6 copy 2.png";
import university616 from "../images/university/USA/images-6 copy 3.png";
import university617 from "../images/university/USA/images-6 copy 4.png";
import university618 from "../images/university/USA/images-6 copy.png";
import university619 from "../images/university/USA/images-6.png";
import university620 from "../images/university/USA/images-7 copy 2.png";
import university621 from "../images/university/USA/images-7 copy 3.png";
import university622 from "../images/university/USA/images-7 copy 4.png";
import university623 from "../images/university/USA/images-7 copy.png";
import university624 from "../images/university/USA/images-7.png";
import university625 from "../images/university/USA/images-8 copy 2.png";
import university626 from "../images/university/USA/images-8 copy 3.png";
import university627 from "../images/university/USA/images-8 copy.png";
import university628 from "../images/university/USA/images-8.png";
import university629 from "../images/university/USA/images-9 copy 2.png";
import university630 from "../images/university/USA/images-9 copy 3.png";
import university631 from "../images/university/USA/images-9 copy.png";
import university632 from "../images/university/USA/images-9.png";
import university633 from "../images/university/USA/images.jpeg";
import university634 from "../images/university/USA/images.png";
import university635 from "../images/university/USA/kent-state-university-vertical-logo.jpg";
import university636 from "../images/university/USA/lewislogo-b.gif";
import university637 from "../images/university/USA/logo.webp";
import university638 from "../images/university/USA/logo[1663083002].png";
import university639 from "../images/university/USA/logos-vertical-1024x683.jpg";
import university640 from "../images/university/USA/logos-wordmark-stacked.png";
import university641 from "../images/university/USA/lsu-logo-1-2.png";
import university642 from "../images/university/USA/lumberjacks_primarylogo-275px.png";
import university643 from "../images/university/USA/msstate_vertical_maroon_wwhitebanner_large.png";
import university644 from "../images/university/USA/mu-logo-btd-centered-bg-4c.png";
import university645 from "../images/university/USA/osu-nomenclature.png";
import university646 from "../images/university/USA/osu-primarylogo-2-compressor.jpg";
import university647 from "../images/university/USA/park-logo-red-text-only.jpg";
import university648 from "../images/university/USA/pittsburg-state-university-logo-FEEDCDDE8A-seeklogo.com.png";
import university649 from "../images/university/USA/portland-state-university-logo-13F21A0F8D-seeklogo.com.png";
import university650 from "../images/university/USA/sacred-heart-university-vector-logo.png";
import university651 from "../images/university/USA/signature-vertical-logo-web-HEX-full.png";
import university652 from "../images/university/USA/simmons-university-logo-vector.png";
import university653 from "../images/university/USA/square_xsml_msoe-lgo.png";
import university654 from "../images/university/USA/ua_stack_rgb_4.png";
import university655 from "../images/university/USA/umass-logo2 copy.png";
import university656 from "../images/university/USA/umass-logo2.png";
import university657 from "../images/university/USA/unh_sig_seal_stacked_288.webp";
import university658 from "../images/university/USA/university-of-colorado-vector-logo.png";
import university659 from "../images/university/USA/universityofutah-logo-1.png";
import university660 from "../images/university/USA/unm-logo-spelled-vertical-color.png";
import university661 from "../images/university/USA/unt-university-of-north-texas8338.logowik.com.webp";
import university662 from "../images/university/USA/vert-w-crest-logo-web-digital-color.png";
import university663 from "../images/university/USA/wkucuplong.jpg";

let countryDetails = [];
countryDetails[1] = {
  name: "Study in UK",
  image: coursesGrid2,
  link: "/study-in-uk-agency",
  pageTitle: "Study in UK",
  pageDesc: `<h6>At a Glance</h6>
    <p>The United Kingdom, made up of England, Wales, Northern Ireland, and Scotland, is renowned for its world-class higher education. With a rich variety of programs in fields like Arts, Culture, Religion, Fashion, Engineering, Management, Hospitality and Tourism, Medicine, and Architecture, the UK attracts thousands of international students each year. Prestigious universities across the UK offer the ideal environment to advance your academic journey.</p>
    <p>Partner with Guidance Plus,  one of the leading UK education consultants, to secure your spot at one of these esteemed institutions and take the first step toward your future.</p>`,
  pageContent: [
    {
      heading: "The Upsides",
      content: `
      <ol>
        <li>The UK provides diverse courses designed to match different career goals.</li>
        <li>It has over 100 years of education expertise.</li>
        <li>Free health insurance for students</li>
        <li>Graduates can stay and work for up to two years after finishing their program.</li>
        <li>Courses often include internships and sandwich placements.</li>
        <li>UK degrees are internationally recognized.</li>
        <li>Most universities are government-owned, offering a high volume of options.</li>
        <li>International students can work for 20 hours per week during the semester and 40 hours per week during breaks.</li>
        <li>High standards of living</li>
      </ol>`,
    },
    {
      heading: "Requirements",
      content: `<p>Generally, IELTS is required for studying in the UK, but there are alternative options available for demonstrating English proficiency:</p>
        <ol>
        <li>English Medium Education: Some universities accept a Medium of Instruction (MOI) certificate from English medium schools.</li>
        <li>12th Grade English Scores: Class 12th English scores can be considered as proof of proficiency.</li>
        <li>Online Interview: Certain universities may conduct interviews via platforms like Skype or Microsoft Teams.</li>
        <li>Other Exams: Some universities offer their own qualifying exams instead of IELTS, TOEFL, or PTE.</li>
        </ol>
        
        <h6>Documents Needed:</h6>
        
        <ol>
        <li>10th and 12th mark sheets</li>
        <li>Bachelor’s semester mark sheets, provisional certificate, and consolidated mark sheet</li>
        <li>Medium of Instruction (MOI) certificate, if required</li>
        <li>Passport, Letters of Recommendation, Resume, Statement of Purpose</li>
        <li>University offer letter, Letter of Experience (if applicable)</li>
        <li>Other academic transcripts</li>
        <li>Visa Requirements:</li>
        </ol>
        
        <p>For a Tier 4 UK student visa, English proficiency is required.</p>
        <ol>
        <li>Minimum band scores: Diploma (5.0), Bachelor’s (6.0), Master’s (6.0–6.5).</li>
        <li>If IELTS is waived, request confirmation from the university for visa eligibility.</li>
        </ol>`,
    },
    {
      heading: "Intakes",
      content: `<p>Intake Availability:<br/>
        Majority of programs are available in September and January.<br/>
        Minor intakes are offered in March, April, and May.</p>
        <h6>Admission Process:</h6>
        <p>Admission seekers should consult with our counselors for program details.<br/>
        After selecting the program and university, we will apply on behalf of the candidate to the respective university.</p>

        <h6>Offer Letter and Medical Test:</h6>
        <p>Once the university issues the offer letter, the aspirant must qualify for a medical test.</p>

        <h6>Financial Credibility:</h6>
        <p>After the medical test, the student must demonstrate financial credibility to obtain the CAS (Confirmation of Acceptance for Studies) letter.</p>

        <h6>Financial Requirements:</h6>
        <p><b>Outer London:</b> Tuition fees + living expenses of approximately £9,207 must be maintained in the student’s account for 28 days.</p>
        <p><b>Inner London:</b> Tuition fees + living expenses of approximately £12,006 must be kept in the student’s or their parent’s account for 28 days.</p>

        <h6>Visa and Travel:</h6>
        <p>After obtaining the visa, the student can fly to the UK to pursue their dream course.</p>



        <h6>Prominent scholarships</h6>
        <ol>
        <li>GREAT Scholarship: Supports international students to study in the UK.</li>
        <li>Chevening Scholarship: Funded by the UK government for outstanding students pursuing a master’s degree.</li>
        <li>Commonwealth Scholarships: For students from low and middle-income Commonwealth countries.</li>
        <li>Global Wales Scholarship: Funding for undergraduate and postgraduate programs in Wales.</li>
        <li>Fulbright Scholarship: Offers scholarships for Indian students to study in the UK.</li>
        <li>Sir Ratan Tata Scholarship: Financial assistance for Indian postgraduate students in social sciences and development studies.</li>
        </ol>`,
    },
  ],
  universities: [
    {
      name: "Ulster University, NI",
      logo: university1,
    },
    {
      name: "Queen`s University, Belfast",
      logo: university2,
    },
    {
      name: "Heriot-Watt University, Scotland",
      logo: university3,
    },
    {
      name: "Hertfordshire University",
      logo: university4,
    },
    {
      name: "Middlesex University, London",
      logo: university5,
    },
    {
      name: "INTO Group of Institutions",
      logo: university6,
    },
    {
      name: "Birmingham City University",
      logo: university7,
    },
    {
      name: "South Wales University",
      logo: university8,
    },
    {
      name: "De Montfort university",
      logo: university9,
    },
    {
      name: "University of Gloucestershire",
      logo: university10,
    },
    {
      name: "Oxford International Education Group (OIGEC)",
      logo: university11,
    },
    {
      name: "University for the creative Arts",
      logo: university12,
    },
    {
      name: "University for wolverhampton",
      logo: university13,
    },
    {
      name: "Bath spa University",
      logo: university14,
    },
    {
      name: "University of Liverpool",
      logo: university15,
    },
    {
      name: "Canterbury christ church university",
      logo: university16,
    },
    {
      name: "University of Law",
      logo: university17,
    },
    { logo: university394 },
    { logo: university395 },
    { logo: university396 },
    { logo: university397 },
    { logo: university398 },
    { logo: university399 },
    { logo: university400 },
    { logo: university401 },
    { logo: university402 },
    { logo: university403 },
    { logo: university404 },
    { logo: university405 },
    { logo: university406 },
    { logo: university407 },
    { logo: university408 },
    { logo: university409 },
    { logo: university410 },
    { logo: university411 },
    { logo: university412 },
    { logo: university413 },
    { logo: university414 },
    { logo: university415 },
    { logo: university416 },
    { logo: university417 },
    { logo: university418 },
    { logo: university419 },
    { logo: university420 },
    { logo: university421 },
    { logo: university422 },
    { logo: university423 },
    { logo: university424 },
    { logo: university425 },
    { logo: university426 },
    { logo: university427 },
    { logo: university428 },
    { logo: university429 },
    { logo: university430 },
    { logo: university431 },
    { logo: university432 },
    { logo: university433 },
    { logo: university434 },
    { logo: university435 },
    { logo: university436 },
    { logo: university437 },
    { logo: university438 },
    { logo: university439 },
    { logo: university440 },
    { logo: university441 },
    { logo: university442 },
    { logo: university443 },
    { logo: university444 },
    { logo: university445 },
    { logo: university446 },
    { logo: university447 },
    { logo: university448 },
    { logo: university449 },
    { logo: university450 },
    { logo: university451 },
    { logo: university452 },
    { logo: university453 },
    { logo: university454 },
    { logo: university455 },
    { logo: university456 },
    { logo: university457 },
    { logo: university458 },
    { logo: university459 },
    { logo: university460 },
    { logo: university461 },
    { logo: university462 },
    { logo: university463 },
    { logo: university464 },
    { logo: university465 },
    { logo: university466 },
    { logo: university467 },
    { logo: university468 },
    { logo: university469 },
    { logo: university470 },
    { logo: university471 },
    { logo: university472 },
    { logo: university473 },
    { logo: university474 },
    { logo: university475 },
    { logo: university476 },
  ],
};

countryDetails[6] = {
  name: "Study in Canada",
  image: coursesGrid1,
  link: "/study-in-canada",
  pageTitle: "Study in Canada: The Perks",
  pageDesc:
    "<h6>At a Glance</h6><p>Do you have a plan to pursue your further education in Canada? Guidance Plus is available to assist. Guidance Plus have more than ten years of experience and are experts in assisting students to tackle the challenges of studying in Canada. Our counsellors are prepared to offer you whatever information you require, including information on course alternatives, college options, cost schedules, and application procedures. Make your dream of attending university in Canada a reality by getting in touch with us for individualized advice!</p><h6>The perks</h6><p>The world’s second-largest country Canada is considered as one of the favorite destinations for study abroad aspirants. It is one of the safest countries in the world. Canada provides numerous state-of-the-art facilities for students who choose to study there. Canada is one of the most industrialized countries in the world, which opens up greater opportunities for students. Canada possesses a diverse culture and an all-inclusive environment welcoming students from around the world. The country ranks 3rd for the best quality of life. The country offers equal treatment and facilities to native and international students.</p>",
  pageContent: [
    {
      heading: "Some additional benefits",
      content: `<ol>
        <li>Qualifications are valued around the world.</li>
        <li>Affordable education.</li>
        <li>Multicultural society.</li>
        <li>Healthy and safe communities.</li>
        <li>Possibility of paid internships while studying.</li>
        <li>Post-study work visa</li>
        <li>Good job opportunities.</li>
        <li>Fast-track PR options</li>
        <li>Scholarship availability</li>
        <li>Part-time opportunities</li>
        </ol>`,
    },
    {
      heading: "The Procedures",
      content: `<p>International students can apply to Canada in three main intakes: September, January, and May. Apart from the aforementioned significant intakes, there are a handful of smaller ones, with restricted openings between July and October. This implies that you have a variety of possibilities to fulfil your desire of attending a Canadian university for higher education.</p>
      <ol>
          <li>Choose a course and an institution.</li>
          <li>Application process</li>
          <li>Documentation and fee submission</li>
          <li>Medical examination</li>
          <li>Visa proceedings</li>
          <li>Find accommodation</li>
          </ol>`,
    },
    {
      heading: "Universities recommended by us.",
      content: "",
    },
    {
      heading: "Am I eligible for scholarships?",
      content:
        "<p>If you're facing any difficulty with budgetary issues to study in Canada, there's no need to get tensed. Numerous grants are availiable for understudies in Canada. There are grants and budgetary help given by distinctive teach and organizations. All you have got to do is interface with a perfect think about overseas organization to help you in your methods.</p>",
    },
    {
      heading: "Prominent scholarships",
      content: `
      <ol>
        <li>Canadian Commonwealth Scholarship and Fellowship Plan</li>
        <li>Ontario Graduate Scholarship Program</li>
        <li>Dick Martin Scholarship Award</li>
        <li>BeArt Presets Academic Scholarship</li>
        <li>Education Future International Scholarship</li>
        <li>Global Citizen Scholarship</li>
        <li>Narotam Sekhsaria Scholarship</li>
        <li>International Peace Scholarship for Women</li>
      </ol>
      <p>There are varieties of scholarships for international students in Canada like, merit-based scholarships, need-based scholarships, athletic scholarships, subject-specific scholarships, regional scholarships, government-funded scholarships, college or university-funded scholarships, and so on. The University of Toronto scholarships for Indian students, McGill University Scholarship, the University of British Columbia scholarships for international students, The University of Alberta Scholarships, etc are a few of the scholarships provided by institutions in Canada. Quebec Provincial Government Scholarship is type of a scholarship that is provided to students in certain provinces.</p>`,
    },
    {
      heading: "Connect with us",
      content:
        "<p>With over 10+ years of experience, we help students to achieve their dreams of studying abroad, offering expert guidance and support in every step of the way.</p>",
    },
  ],
  universities: [
    { logo: university83 },
    { logo: university84 },
    { logo: university85 },
    { logo: university86 },
    { logo: university87 },
    { logo: university88 },
    { logo: university89 },
    { logo: university90 },
    { logo: university91 },
    { logo: university92 },
    { logo: university93 },
    { logo: university94 },
    { logo: university95 },
    { logo: university96 },
    { logo: university97 },
    { logo: university98 },
    { logo: university99 },
    { logo: university100 },
    { logo: university101 },
    { logo: university102 },
    { logo: university103 },
    { logo: university104 },
    { logo: university105 },
    { logo: university106 },
    { logo: university107 },
    { logo: university108 },
    { logo: university109 },
    { logo: university110 },
    { logo: university111 },
    { logo: university112 },
    { logo: university113 },
    { logo: university114 },
    { logo: university115 },
    { logo: university116 },
    { logo: university117 },
    { logo: university118 },
    { logo: university119 },
    { logo: university120 },
    { logo: university121 },
    { logo: university122 },
    { logo: university123 },
    { logo: university124 },
    { logo: university125 },
    { logo: university126 },
    { logo: university127 },
    { logo: university128 },
    { logo: university129 },
    { logo: university130 },
    { logo: university131 },
    { logo: university132 },
    { logo: university133 },
    { logo: university134 },
    { logo: university135 },
    { logo: university136 },
    { logo: university137 },
    { logo: university138 },
    { logo: university139 },
    { logo: university140 },
    { logo: university141 },
    { logo: university142 },
    { logo: university143 },
    { logo: university144 },
    { logo: university145 },
    { logo: university146 },
    { logo: university147 },
    { logo: university148 },
    { logo: university149 },
    { logo: university150 },
    { logo: university151 },
    { logo: university152 },
    { logo: university153 },
    { logo: university154 },
    { logo: university155 },
    { logo: university156 },
    { logo: university157 },
    { logo: university158 },
    { logo: university159 },
    { logo: university160 },
    { logo: university161 },
    { logo: university162 },
    { logo: university163 },
    { logo: university164 },
    { logo: university165 },
    { logo: university166 },
    { logo: university167 },
    { logo: university168 },
    { logo: university169 },
    { logo: university170 },
    { logo: university171 },
    { logo: university172 },
    { logo: university173 },
    { logo: university174 },
    { logo: university175 },
    { logo: university176 },
    { logo: university177 },
  ],
};

countryDetails[3] = {
  name: "Study in France",
  image: coursesGrid3,
  link: "/study-in-france",
  pageTitle: "Study in France",
  pageDesc: `<h6>At a Glance</h6>
    <p>In terms of unique historical culture, geography and contributions to education, France remains one of the giants of Western European nations. With a specialization in educational excellence, the country welcomes non-French scholars from several continents. Students looking forward to studying engineering, arts and humanities, business, gastronomy as well as fashion, will have their needs well catered for in French institutions. The rich cultural life coupled with the desire for innovation and discovery typifies the frenzied academic environment. Furthermore, the safe and cosmopolitan nature of France serves as an asset in enrichening one both professionally and personally. In many ways, French culture captured us, but most of all the opportunity to study in France – we are glad to fulfill your wish and help you in each step of this process!</p>`,
  pageContent: [
    {
      heading: "The Upsides",
      content: `<ul class="education-list-style-one">
        <li>Affordable and Quality Education: High standards at competitive prices.</li>
        <li>Multicultural Society: A diverse and inclusive environment.</li>
        <li>English-Taught Courses: Many universities offer programs in English.</li>
        <li>Attractive Tuition Fees: Cost-effective options for international students.</li>
        <li>Research and Development Opportunities: Excellent facilities and support for innovation.</li>
        <li>Available Scholarships: Various funding options to assist students.</li>
        <li>Government Accommodation Assistance: Support for securing housing.</li>
        <li>Travel Opportunities: An appealing destination for exploring Europe.</li>
        </ul>

        <h6>Requirements</h6>
        <p><b>Language of Instruction:</b> Courses are offered in both French and English.</p>
        <p><b>French Language Proficiency:</b> No need for a French language test for international students.</p>

        <h6>Academic Qualifications:</h6>
        <p><b>Bachelor’s Degree:</b> Minimum of 50% in 12th grade required.</p>
        <p><b>Master’s Degree:</b> Minimum of 50% in Bachelor’s degree required.</p>

        <h6>Foundation and Diploma Programs:</h6>
        <p>Students with around 50% can qualify for these programs.</p>
        <p><b>Age Requirement:</b> Students must be at least 18 years old before joining a degree program.</p>

        <h6>Mandatory Documentation:</h6>
        <p>MOI (Medium of Instruction) certificate must be submitted for enrollment.</p>
        <p>IELTS and French language proficiency are non-mandatory.</p>
        <h6>Intakes</h6>
        <p>Major intakes are available in February and September</p>`,
    },
    {
      heading: "Prominent scholarships",
      content: `<ul class="education-list-style-one">
        <li>Scholarships for International Students in France</li>
        <li>Charpak Scholarship Program</li>
        <li>Eiffel Scholarship Program of Excellence</li>
        <li>Erasmus+ Mobility Scholarships for Foreign Students</li>
        <li>French+ Sciences Scholarships</li>
        <li>Make Our Planet Great Again (MOPGA) Scholarships</li>
        <li>Legrand Empowering Scholarship</li>
        <li>Amba Damia Scholarship</li>
        <li>Emile-Boutmy Scholarship</li>
        <li>Accommodation Assistance </li>
        </ul>`,
    },
    {
      heading: "CAF (Caisse des Allocations Familiales):",
      content: `<ul>
        <li>Financial support provided by the French government to help with housing-related costs.</li>
        <li>Available to international students renting a flat, furnished rental, studio, or flat share.</li>
        <li>Eligible students can receive approximately €180 to €200 each month to assist with accommodation expenses.</li>
        </ul>
        `,
    },
  ],
  universities: [
    {
      name: "ESSCA",
      logo: university25,
    },
    {
      name: "ESDES",
      logo: university26,
    },
    {
      name: "Burgundy School of Business",
      logo: university27,
    },
    {
      name: "Neoma Business School",
      logo: university28,
    },
    {
      name: "College De Paris",
      logo: university29,
    },
    { logo: university239 },
    { logo: university240 },
    { logo: university241 },
    { logo: university242 },
    { logo: university243 },
    { logo: university244 },
    { logo: university245 },
    { logo: university246 },
    { logo: university247 },
    { logo: university248 },
    { logo: university249 },
    { logo: university250 },
    { logo: university251 },
    { logo: university252 },
    { logo: university253 },
    { logo: university254 },
    { logo: university255 },
    { logo: university256 },
    { logo: university257 },
    { logo: university258 },
    { logo: university259 },
    { logo: university260 },
    { logo: university261 },
    { logo: university262 },
  ],
};

countryDetails[4] = {
  name: "Study in Germany",
  image: coursesGrid4,
  link: "/study-in-germany",
  pageTitle: "Study in Germany",
  pageDesc: `<h6>At a Glance</h6>
    <p>Due to excellent quality education and advanced research, Germany remains among the most popular countries for international students. Consisting of 16 federal states, Germany provides comprehensive studies in Engineering, Medicine, Natural Sciences, Business, Technology, Arts, Architecture and other fields. Several eminent universities and technical colleges create a perfect academic milieu for their students by generating new ideas on the background of past. With relatively low tuition and high practical employment opportunities, Germany grows students from every corner of the world making it a great country to study.</p>

    <h6>The Upsides</h6>

    <p><b>Better Universities:</b> Germany bears some of the most prestigious universities that are famous for their best academic records.</p>
    <p><b>Different Degree Courses:</b> Students have the options to select different degree programs in various study fields.</p>
    <p><b>Globally Accepted Study Programs:</b> Degrees from German universities are widely accepted and recognized across the world.</p>
    <p><b>Affordability:</b> Cost of living in Germany is fairly better than many other countries for students.</p>
    <p><b>Flexible Travel Options:</b> Applying for a German student visa also provides you with a Schengen visa which means that you can visit 27 other European countries as well using the same visa.</p>
    <p><b>Part-time work:</b> Students have to complete their studies as well then they are headed for a glimpse of valuable work experience and supplementary in-cash support.</p>
    <p><b>Higher employability rate:</b> Studies show that there is a high probability of you being employed as against graduates from other countries.</p>
    <p><b>Diverse Community:</b> Germany is a country of multi-cultural These factors makes it easier to foreign students to adapt and feel welcome.</p>
    <p><b>Free language training:</b> Students have access to free courses in German, which can facilitate the daily life and their experience.</p>`,
  pageContent: [
    {
      heading: "Requirements",
      content: `<p><b>Higher Education Entrance Qualification:</b>
        A high school diploma, school-leaving certificate, or university entrance exam result is typically required for international and undergraduate students.</p>
        <p><b>Program-Specific Requirements:</b>
        You may need to meet additional specific requirements based on your chosen program.</p>
        <p><b>English Language Proficiency:</b>
        Proof of English proficiency may be required, typically through TOEFL or IELTS scores.</p>
        <p><b>German Language Proficiency:</b>
        Proficiency in German is non-mandatory for studying at colleges or universities in Germany.</p>`,
    },
    {
      heading: "Course Qualifications",
      content: `<p><b>Bachelor’s Degree:</b> Minimum of 12/13 years of education with at least 50% marks in the 12th grade.</p>
        <p><b>IELTS Requirement:</b> Overall score of 6.0, with no individual score lower than 6.0.</p>
        <p><b>Master’s Degree:</b> Bachelor’s degree with 50% and above.</p>
        <p><b>IELTS Requirement:</b> Overall score of 5.5, with no individual score lower than 6.0.</p>

        <h6>Duration</h6>
        <p><b>Undergraduate Degrees:</b> Typically take a minimum of 3 years.</p>
        <p><b>Postgraduate Degrees:</b> Usually require 2 years.</p>`,
    },
    {
      heading: "Intakes",
      content: `<h6>Summer Intake:</h6>
        <p>Application Period: March/April</p>
        <p>Tuition Fees: Approximately 10,236 Euros for the first year.</p>

        <h6>Winter Intake:</h6>
        <p>Application Period: September/October</p>
        <p>Certain private universities may have additional intakes in February, May, and June.</p>

        <h6>Prominent scholarships</h6>
        <ul class="education-list-style-one">
        <li>DAAD Scholarship Programs</li>
        <li>Erasmus Scholarship Programs</li>
        <li>Heinrich Böll Foundation Scholarships</li>
        <li>DeutschlandStipendium National Scholarship Programme</li>
        <li>Konrad-Adenauer-Stiftung Scholarships</li>
        <li>Bayer Foundation Awards</li>
        <li>International Ambassador Scholarship</li>
        <li>International Student Financial Aid (ISFA)</li>
        </ul>`,
    },
  ],
  universities: [
    { logo: university263 },
    { logo: university264 },
    { logo: university265 },
    { logo: university266 },
    { logo: university267 },
    { logo: university268 },
    { logo: university269 },
    { logo: university270 },
    { logo: university271 },
    { logo: university272 },
    { logo: university273 },
    { logo: university274 },
    { logo: university275 },
    { logo: university276 },
    { logo: university277 },
    { logo: university278 },
    { logo: university279 },
    { logo: university280 },
    { logo: university281 },
  ],
};

countryDetails[5] = {
  name: "Study in Dubai",
  image: coursesGrid5,
  link: "/study-in-dubai",
  pageTitle: "Study in Dubai",
  pageDesc: `<h6>At a Glance</h6>
    <p>Dubai is part of the largest and most populous metropolitan area in the United Arab Emirates (UAE), situated on the Persian Gulf and backed by the Arabian desert.Dubai is famous for its skyscapers, high living standards and its standard higher education institutions, Dubai makes for a popular study destination. It is a truly cosmopolitan city, attracting students from all around the world.Strong post-study work prospects, a booming economy, tax-free income, easy visa policies and a growing job market are benefits for international students who studies in Dubai.</p>`,
  pageContent: [
    {
      heading: "The Upsides",
      content: `<ul class="education-list-style-one">
        <li>Afforadable cost</li>
        <li>Low travel costs and time</li>
        <li>Multicultural Experience</li>
        <li>IELTS is not mandatory</li>
        <li>Age gap and year gap accepted</li>
        <li>Opportunity to learn in the branch institutions of top universities in UK, USA, Australia and so on at lower cost</li>
        <li>Opportunity to visit or study on the mother campuses in different nations.</li>
        </ul>`,
    },
    {
      heading: "Academic Qualifications:",
      content: `<ul class="education-list-style-one">
        <li>Bachelor’s Degree: Minimum of 50% in 12th grade required.</li>
        <li>Master’s Degree: Minimum of 50% in Bachelor’s degree required.</li>
        <li>PG Diploma: Minimum of 50% in Bachelor’s degree required.</li>
        <li>Students with less than 50% marks for qualifying exams can attend foundation courses and it leads to securing their dream institutions with the course of their choice.</li>
        </ul>`,
    },
    {
      heading: "Intakes",
      content: `<p>Major intakes are available in Januray and September</p>
        <p>Modul University has an intake in December or February.</p>
        <p>Canadian University, Murdoch University and University of Wollongong has an intake in May along with Intakes of January and September.</p>
        <ul class="education-list-style-one">
        <li>Prominent scholarships</li>
        <li>UG Merit Scholarship</li>
        <li>Khalifa University Scholarships</li>
        <li>UAE University Full Scholarship</li>
        <li>Graduate Merit Scholarships</li>
        <li>Partial Tution Scholarship for International Students</li>
        <li>Chancellor Fellowship for Graduate Students at UAE University</li>
        </ul>`,
    },
  ],
  universities: [
    { logo: university181 },
    { logo: university182 },
    { logo: university183 },
    { logo: university184 },
    { logo: university185 },
    { logo: university186 },
    { logo: university187 },
    { logo: university188 },
    { logo: university189 },
    { logo: university190 },
    { logo: university191 },
    { logo: university192 },
    { logo: university193 },
    { logo: university194 },
    { logo: university195 },
    { logo: university196 },
    { logo: university197 },
    { logo: university198 },
    { logo: university199 },
    { logo: university200 },
    { logo: university201 },
    { logo: university202 },
    { logo: university203 },
    { logo: university204 },
    { logo: university205 },
    { logo: university206 },
    { logo: university207 },
    { logo: university208 },
    { logo: university209 },
    { logo: university210 },
    { logo: university211 },
    { logo: university213 },
  ],
};

countryDetails[2] = {
  name: "Study in Ireland",
  image: coursesGrid6,
  link: "/study-abroad-in-ireland",
  pageTitle: "Study in Ireland",
  pageDesc: `<h6>At a Glance</h6>
    <p>Out of all the countries in the world, Ireland is one of the countries which has great appreciation with regards to higher studies. Due to their rich history and resources, Irish universities provide cutting edge facilities and pro-education services developed by the greatest universities in the world. However, due to the concentration of the country’s government on the improvement of its education level, it has seen a rapid growth in the number of foreign students studying in Ireland.</p>
    <p>The Irish people have always considered it and have every reason to consider it as the best in international tourism, welcoming every visitor who sets foot in Ireland. It is one of the safest countries and we take it for granted that security is strictly enforced.</p>`,
  pageContent: [
    {
      heading: "The Upsides",
      content: `<ul>
        <li>Education - Impressive and high quality education system. World's top universities for computer science are located in Ireland.</li>
        <li>Research - Universities in Ireland are among the top 1% research institutes in world.</li>
        <li>Scholarships - Government of ireland offers attractive scholarships for studying and research.</li>
        <li>COST - Ireland is one the most affordable country compared to other European study designations.</li>
        <li>Culture - Studying in Ireland offers a unique opportunity to immerse yourseld in tapestry of culture, history and artistic expression.</li>
        <li>Environment - Friendly and Supportive Environment.</li>
        <li>Industry - Students get offers from various innovative industries.</li>
        </ul>`,
    },
    {
      heading: "Classification of Irish Education System",
      content: `
      <ul class="education-course-detail-option">
      <li><h6>Universities</h6></li>
      <li><h6>Institutes</h6></li>
      <li><h6>Colleges</h6></li>
      </ul>
      <p>Universities in Ireland offer over 5000 courses in various subject areas at different study levels.</p>`,
    },
    {
      heading: "Popular programs in Ireland",
      content: `
      <ul class="education-course-detail-option">
      <li><h6>IT</h6></li>
      <li><h6>Pharmaceuticals</h6></li>
      <li><h6>Business</h6></li>
      <li><h6>Engineering</h6></li>
      <li><h6>Hotel Management</h6></li>
      </ul>
      <h6>Requirements</h6>
      Bachelors - Minimum of 50% in 12th grade required.
      IELTS Score- Overall-6 , Individual-5.5
      Many Irish Universities & Institutions offers honors Degree at the Bachelor's level.
      Master's - Minimum of 55% in Bachelor’s degree required.
      IELTS Score- Overall-6.5 , Individual-6


      <h6>Intakes</h6>

      <p>Major intakes are available in February and September to October.</p>

      <h6>Irish Education in My Career</h6>
      <p>Such educational systems are among the best in the world regarding the offer of courses and qualifications. At the end of certain courses, a few of these institution providers guaranteed employment. For study interval international students studying in Ireland can work part-time every week for 20 hours, as per the Irish government. When on holiday, international students are allowed to work for as much as forty hours per week. Upon course completion, the Bachelor’s degree holder is allowed to remain in Ireland for a maximum period of twelve months. The postgraduate students who studied in Ireland will also be eligible to remain in the country for a period of 2 years. After the stayback duration, a student can earn the least income set up employed by the government and be eligible for a Green Card application.</p>

      <ul class="education-list-style-one">
      <li>Prominent scholarships</li>
      <li>Fullbright Scholarships</li>
      <li>Undergraduate Merit Scholarship</li>
      <li>Naughton Fellowship</li>
      <li>Government of Ireland Postgraduate Scholarships</li>
      <li>Scholarships for Women in Leadership & Management</li>
      <li>St. Vincent de Paul Scholarship</li>
      <li>NUI Gateway International Students Scholarships</li>
      <li>Central Bank of Ireland Undergraduate Scholarships for International Students</li>
      </ul>`,
    },
  ],
  universities: [
    {
      name: "University Of Limerick",
      logo: university18,
    },
    {
      name: "Maynooth University",
      logo: university19,
    },
    {
      name: "Griffith College",
      logo: university20,
    },
    {
      name: "Dundalk Institute of Technology",
      logo: university21,
    },
    {
      name: "Technological university Dublin",
      logo: university22,
    },
    {
      name: "Dublin City University",
      logo: university23,
    },
    {
      name: "IBAT College Dublin",
      logo: university24,
    },
    { logo: university282 },
    { logo: university283 },
    { logo: university284 },
    { logo: university285 },
    { logo: university286 },
    { logo: university287 },
    { logo: university288 },
    { logo: university290 },
    { logo: university291 },
    { logo: university292 },
    { logo: university293 },
    { logo: university294 },
    { logo: university295 },
    { logo: university296 },
    { logo: university297 },
    { logo: university298 },
  ],
};

countryDetails[7] = {
  name: "Study in Malaysia",
  image: coursesGrid7,
  link: "/malaysia-study-abroad-agency",
  pageTitle: "Study in Malaysia",
  pageDesc: `<h6>At a Glance</h6>
    <p>Malaysia is a beautiful as well as geographical innovative nation is located in South-East Asia region between Thailand and Singapore.
    There is no doubt that Malaysia possesses a unique blend of cultural and geographical diversity. This country is a hub of business-as-usual and trade within the region of Southeast Asia. In recent years, Malaysia has positioned herself-up as the region’s hub of higher learning. In addition, the good standard of living, comparatively low cost of education and the diversity of the population makes Malaysia one of the most desirable places to study for foreigners.
    The system of education in Malaysia provides many international and national level programs and various areas of study.</p>`,
  pageContent: [
    {
      heading: "The Upsides",
      content: `
      <ul class="education-instructor-social">
      <li>Affordable tution fees & Low Living cost</li>
      <li>Multiethnic Population</li>
      <li>Melting pot of different cultures</li>
      <li>Student friendly laws for International Students</li>
      <li>Quality education</li>
      <li>Business and trade hub of South East Asia</li>
      <li>Diverse Courses</li>
      </ul>`,
    },
    {
      heading: "Requirements",
      content: "<p>IELTS or TOEFL score</p>",
    },
    {
      heading: "Academic Qualifications:",
      content: `<ul>
      <li>Diploma : Minimum of 60% in 10th or 12th grade required.</li>
      <li>Bachelor’s Degree: Minimum of 60% in 12th grade required.</li>
      <li>Master’s Degree: Minimum of 50% in Bachelor’s degree required.</li>
      <li>PG Diploma : Minimum of 60% in Bachelor’s degree required.</li>
      </ul>`,
    },
    {
      heading: "Intakes",
      content: `<p>Major intakes are in January & September. Additional intakes happens in May, July & November.</p>
      <ul>
      <li>Prominent scholarships</li>
      <li>Malaysian Government Scholarships</li>
      <li>Malaysia International Scholarship (MIS)</li>
      <li>The Malaysian Technical Cooperation Programme (MTCP) Scholarship</li>
      <li>Malaysia Commonwealth Scholarship and Fellowship plan</li>
      <li>United Nations University ligh Scholarship</li>
      <li>Education Future International Scholarship</li>
      </ul>`,
    },
  ],
  universities: [
    { logo: university310 },
    { logo: university311 },
    { logo: university312 },
    { logo: university313 },
    { logo: university314 },
    { logo: university315 },
    { logo: university316 },
    { logo: university317 },
    { logo: university318 },
    { logo: university319 },
  ],
};

countryDetails[8] = {
  name: "Study in USA",
  image: coursesGrid8,
  link: "/study-in-usa",
  pageTitle: "Study in USA",
  pageDesc: `<h6>At a Glance</h6>
    <p>It is no secret that the United States attracts students from every part of the world due to the strength and the diversity of its education systems With a range of programs as Engineering, Medicine, Business, Arts, Technology, and Humanities, the most US universities are focused not only on healthy scholarly expectations but also on practical approaches to the gained knowledge. Because of this, students benefit from practical classroom experience that makes them well-prepared for the work environment. From the most prestigious Ivy League schools to top public colleges, U.S. education is comprehensively aimed to embrace and facilitate individual and professional development and be an excellent place for people willing to achieve their educational purposes.</p>

    <h6>The Upsides</h6>
    <ul class="education-list-style-one">
    <li>Globally Recognized Qualifications</li>
    <li>Flexible Learning Environment</li>
    <li>Opportunities for Interships</li>
    <li>Practical and Research-Oriented Programs</li>
    <li>Scholarships and Fellowships Available</li>
    <li>Technology-Driven Learning</li>
    <li>Part-Time Employment Options</li>
    <li>Post-Study Work Visa Opportunities</li>
    </ul>`,
  pageContent: [
    {
      heading: "Requirements",
      content: `<h6>English Language Proficiency:</h6>
      <p>TOEFL and IELTS: - These examinations are required for all learners to prove their English competency for admission purposes.</p>

      <h6>Graduate Studies:</h6>
      <p>GRE:- The examination is very essential for graduate studies as it measures the knowledge of verbal reasoning, as well as quantitative reasoning and analytical writing skills.</p>

      <h6>Management Studies:</h6>
      <p>GMAT: – This examination is essential for an individual wishing to undertake an MBA and other postgraduate management training programmes. It assesses the analytical and writing capabilities, as well as problem solving skills.</p>

      <h6>Collegiate Programs:</h6>
      <p>SAT:- Test offered to undergraduate candidates seeking for degrees in bachelor’s studies with emphasis on reading, writing and maths skills.</p>

      <h6>Legal and Clinical Institutions:</h6>
      <p>LSAT:- Accepted by law schools as criterion for admission, scoring skills in critical thought, reading and reasoning.</p>
      <p>MCAT: - Also a qualifying examination for medical school, students are tested on knowledge of natural as well as social sciences and analytical reasoning skills.</p>


      <h6>Undergraduate programs candidates are required to pass</h6>

      <p>60% in their 12th subjects. (In engineering programmes, students are required to obtain very high grades in Mathematics, Physics and Chemistry subjects.)</p>

      <p>Average SAT Score = 1160 out of 1600.</p>

      <p>IELTS 6.5 Overall, and each component 6.0. Some institutions prefers an overall score of  6.0 and for individuals a score of 5.5</p>

      <p>Should the student wish to enroll in bachelor’s programs in the United States of America, tuition expenses extending from 20,000 USD to 35,000 USD must be envisaged. The standard length of studies comprising a bachelor’s degree is 4 years. Living expenses stand at approximately 16000 usd.</p>


      <h6>Intakes</h6>
      <p>Majority of programs are available in January and August</p>

      <ul class="education-list-style-one">
      <li>Prominent scholarships</li>
      <li>Fulbright – Nehru Fellowships</li>
      <li>Chicago Booth Scholarships for Indian Students</li>
      <li>Global Students Scholarship</li>
      <li>National Overseas Scholarship</li>
      <li>SN Bose Scholarship</li>
      <li>Narotam Sekhsaria Scholarship</li>
      <li>Hubert Humphrey Fellowship Program</li>
      <li>AAUW International Fellowships</li>
      </ul>`,
    },
  ],
  universities: [
    { logo: university477 },
    { logo: university478 },
    { logo: university479 },
    { logo: university480 },
    { logo: university481 },
    { logo: university482 },
    { logo: university483 },
    { logo: university484 },
    { logo: university485 },
    { logo: university486 },
    { logo: university487 },
    { logo: university488 },
    { logo: university489 },
    { logo: university490 },
    { logo: university491 },
    { logo: university492 },
    { logo: university493 },
    { logo: university494 },
    { logo: university495 },
    { logo: university496 },
    { logo: university497 },
    { logo: university498 },
    { logo: university499 },
    { logo: university500 },
    { logo: university501 },
    { logo: university502 },
    { logo: university503 },
    { logo: university504 },
    { logo: university505 },
    { logo: university506 },
    { logo: university507 },
    { logo: university508 },
    { logo: university509 },
    { logo: university510 },
    { logo: university511 },
    { logo: university512 },
    { logo: university513 },
    { logo: university514 },
    { logo: university515 },
    { logo: university516 },
    { logo: university517 },
    { logo: university518 },
    { logo: university519 },
    { logo: university520 },
    { logo: university521 },
    { logo: university522 },
    { logo: university523 },
    { logo: university524 },
    { logo: university525 },
    { logo: university526 },
    { logo: university527 },
    { logo: university528 },
    { logo: university529 },
    { logo: university530 },
    { logo: university531 },
    { logo: university532 },
    { logo: university533 },
    { logo: university534 },
    { logo: university535 },
    { logo: university536 },
    { logo: university537 },
    { logo: university538 },
    { logo: university539 },
    { logo: university540 },
    { logo: university541 },
    { logo: university542 },
    { logo: university543 },
    { logo: university544 },
    { logo: university545 },
    { logo: university546 },
    { logo: university547 },
    { logo: university548 },
    { logo: university549 },
    { logo: university550 },
    { logo: university551 },
    { logo: university552 },
    { logo: university553 },
    { logo: university554 },
    { logo: university555 },
    { logo: university556 },
    { logo: university557 },
    { logo: university558 },
    { logo: university559 },
    { logo: university560 },
    { logo: university561 },
    { logo: university562 },
    { logo: university563 },
    { logo: university564 },
    { logo: university565 },
    { logo: university566 },
    { logo: university567 },
    { logo: university568 },
    { logo: university569 },
    { logo: university570 },
    { logo: university571 },
    { logo: university572 },
    { logo: university573 },
    { logo: university574 },
    { logo: university575 },
    { logo: university576 },
    { logo: university577 },
    { logo: university578 },
    { logo: university579 },
    { logo: university580 },
    { logo: university581 },
    { logo: university582 },
    { logo: university583 },
    { logo: university584 },
    { logo: university585 },
    { logo: university586 },
    { logo: university587 },
    { logo: university588 },
    { logo: university589 },
    { logo: university590 },
    { logo: university591 },
    { logo: university592 },
    { logo: university593 },
    { logo: university594 },
    { logo: university595 },
    { logo: university596 },
    { logo: university597 },
    { logo: university598 },
    { logo: university599 },
    { logo: university600 },
    { logo: university601 },
    { logo: university602 },
    { logo: university603 },
    { logo: university604 },
    { logo: university605 },
    { logo: university606 },
    { logo: university607 },
    { logo: university608 },
    { logo: university609 },
    { logo: university610 },
    { logo: university611 },
    { logo: university612 },
    { logo: university613 },
    { logo: university614 },
    { logo: university615 },
    { logo: university616 },
    { logo: university617 },
    { logo: university618 },
    { logo: university619 },
    { logo: university620 },
    { logo: university621 },
    { logo: university622 },
    { logo: university623 },
    { logo: university624 },
    { logo: university625 },
    { logo: university626 },
    { logo: university627 },
    { logo: university628 },
    { logo: university629 },
    { logo: university630 },
    { logo: university631 },
    { logo: university632 },
    { logo: university633 },
    { logo: university634 },
    { logo: university635 },
    { logo: university636 },
    { logo: university637 },
    { logo: university638 },
    { logo: university639 },
    { logo: university640 },
    { logo: university641 },
    { logo: university642 },
    { logo: university643 },
    { logo: university644 },
    { logo: university645 },
    { logo: university646 },
    { logo: university647 },
    { logo: university648 },
    { logo: university649 },
    { logo: university650 },
    { logo: university651 },
    { logo: university652 },
    { logo: university653 },
    { logo: university654 },
    { logo: university655 },
    { logo: university656 },
    { logo: university657 },
    { logo: university658 },
    { logo: university659 },
    { logo: university660 },
    { logo: university661 },
    { logo: university662 },
    { logo: university663 },
  ],
};

countryDetails[9] = {
  name: "Study in Australia",
  image: coursesGrid9,
  link: "/study-abroad-in-australia",
  pageTitle: "Study in Australia",
  pageDesc: `<h6>At a Glance</h6>
  <p>Australia is known for providing the best education and indulging in modern research, and its appeal specifically to the cosmopolitan learners is highly commendable. Most Australian universities are well rounded and well diversified, offering programs in Engineering, Medicine, Business, Technology, Arts, Hospitality, Environmental studies among others. In addition, The comparatively low cost of education, good links to the industries, and the availability of many internships and jobs make Australia easily appealing to many students from all over the globe. In addition, the beautiful and multicultural environment of the country adds on to the favorable experience of studying there. We will help you achieve your goal of studying in Australia by offering you specialized assistance and advice!</p>

  <h6>The Upsides</h6>
  <ul>
  <li>Quality Education: World-class academic standards.</li>
  <li>Extended Stay: 4-5 years post-study stay.</li>
  <li>Internships: Abundant hands-on experience opportunities.</li>
  <li>Employment Prospects: High job demand for graduates.</li>
  <li>Top Universities: Several institutions ranked globally.</li>
  <li>Scholarships: Financial support options available.</li>
  <li>Part-Time Jobs: Flexible work opportunities for students.</li>
  <li>Visa Success: 100% success rate for eligible applicants.</li>
  </ul>`,
  pageContent: [
    {
      heading: "Requirements",
      content: `<h6>Bachelor’s Degree:</h6>
      <p>Qualifications: Completion of 12th grade with 50% and above.</p>
      <p>IELTS Score: Minimum of 6.5.</p>
      <p>PTE Score: Minimum of 50.</p>

      <h6>Master’s Degree:</h6>
      <p>Qualifications: Bachelor’s degree with 50% and above.</p>
      <p>IELTS Score: Minimum of 6.5.</p>
      <p>PTE Score: Minimum of 50.</p>

      <h6>Foundation and Pre-Master’s Courses:</h6>
      <p>Available for students who do not meet the required qualifications.</p>
      <p>By Completing these courses can help gain admission to desired programs.</p>

      <h6>Intakes</h6>
      <p>Major intakes are available in February,July and November</p>


      <ul class="education-list-style-one">
      <li>Prominent scholarships</li>
      <li>Australia Award Scholarship</li>
      <li>Global Excellence Scholarship</li>
      <li>Australian Government Research and Training Program (AGRTP)</li>
      <li>Destination Australia International Scholarship</li>
      <li>Rotary Peace Fellowship</li>
      <li>STEM Scholarship for Southeast Asian Students</li>
      </ul>`,
    },
  ],
  universities: [
    {
      logo: university30,
    },
    {
      logo: university31,
    },
    {
      logo: university32,
    },
    {
      logo: university33,
    },
    {
      logo: university34,
    },
    {
      logo: university35,
    },
    {
      logo: university36,
    },
    {
      logo: university37,
    },
    {
      logo: university38,
    },
    {
      logo: university39,
    },
    {
      logo: university40,
    },
    {
      logo: university41,
    },
    {
      logo: university42,
    },
    {
      logo: university43,
    },
    {
      logo: university44,
    },
    {
      logo: university45,
    },
    {
      logo: university46,
    },
    {
      logo: university47,
    },
    {
      logo: university48,
    },
    {
      logo: university49,
    },
    {
      logo: university50,
    },
    {
      logo: university51,
    },
    {
      logo: university52,
    },
    {
      logo: university53,
    },
    {
      logo: university54,
    },
    {
      logo: university55,
    },
    {
      logo: university56,
    },
    {
      logo: university57,
    },
    {
      logo: university58,
    },
    {
      logo: university59,
    },
    {
      logo: university60,
    },
    {
      logo: university61,
    },
    {
      logo: university62,
    },
    {
      logo: university63,
    },
    {
      logo: university64,
    },
    {
      logo: university65,
    },
    {
      logo: university66,
    },
    {
      logo: university67,
    },
    {
      logo: university68,
    },
    {
      logo: university69,
    },
    {
      logo: university70,
    },
    {
      logo: university71,
    },
    {
      logo: university72,
    },
    {
      logo: university73,
    },
    {
      logo: university74,
    },
    {
      logo: university75,
    },
    {
      logo: university76,
    },
    {
      logo: university77,
    },
    {
      logo: university78,
    },
    {
      logo: university79,
    },
    {
      logo: university80,
    },
    {
      logo: university81,
    },
    {
      logo: university82,
    },
  ],
};

countryDetails[10] = {
  name: "Study in Singapore",
  image: coursesGrid10,
  link: "/study-in-singapore",
  pageTitle: "Study in Singapore",
  pageDesc: `<h6>At a Glance</h6>
    <p>Singapore also commonly known as Little Red Dot has penetrated the domestic as well as the international scene in several ways.</p>

    <p>Overlooking this metropolitan city, there are a million reasons to appreciate knowing this garden city such as its thoroughly and amicably organized urban system and swift economic growth and rated among the top in safety in the entire world.</p>

    <p>The growth of this small island has been boosted by the education system in the island country which is also highly regarded by the Government of Singapore. It is not surprising therefore that the people of Singapore are well educated and possess great skills amongst other attributes. A notable institute in this regard, the National University of Singapore (NUS) founded in 1905 has within six years persistently rated the top three tertiary institutions in Asia since 2013.</p>

    <h6>The Upsides</h6>
    <ol>
    <li>Affordable education with low cost of living</li>
    <li>Safe city with low crime rate and stable political institution</li>
    <li>Diverse Population</li>
    <li>Multilingual Country</li>
    <li>High Standard of Living</li>
    <li>Excellent Job Opportunities</li>
    <li>Countless Courses</li>
    </ol>`,
  pageContent: [
    {
      heading: "Requirements",
      content: `<p>Diploma : Minimum pass in 10th or 12th grade required.</p>
      <p>Bachelor’s Degree: Minimum pass in 12th grade required.</p>
      <p>Master’s Degree: Minimum pass in Bachelor’s degree required.</p>
      <p>PG Diploma : Minimum pass in Bachelor’s degree required.</p>

      <h6>Mandatory Documentation:</h6>
      <p>IELTS is mandatory for students not having MOI (Medium of Instruction not English)
      IELTS with overall band score of minimum 6 and individual score of minimum 5.5</p>

      <h6>Intakes</h6>
      <p>Student intakes varies according to institutions and some institutes have rolling intakes.</p>

      <ul class="education-list-style-one">
      <li>Prominent scholarships</li>
      <li>Singapore International Graduate Award</li>
      <li>Singapore Government Scholarship</li>
      <li>Global Merit Scholarship</li>
      <li>Commonwealth Scholarship for Integrated Science</li>
      <li>Singapore Millenium Foundation Scholarship</li>
      <li>SIA Youth Scholarship</li>
      </ul>`,
    },
  ],
  universities: [
    { logo: university371 },
    { logo: university372 },
    { logo: university373 },
    { logo: university374 },
    { logo: university375 },
    { logo: university376 },
    { logo: university377 },
    { logo: university378 },
    { logo: university379 },
    { logo: university380 },
    { logo: university381 },
    { logo: university382 },
    { logo: university383 },
    { logo: university384 },
    { logo: university385 },
    { logo: university386 },
  ],
};

countryDetails[11] = {
  name: "Study in New Zealand",
  image: coursesGrid11,
  link: "/study-in-new-zealand",
  pageTitle: "Study in New Zealand",
  pageDesc: `<h6>At a Glance</h6>

    <p>Are you considering pursuing advanced studies in stunning countries with excellent academic institutions? Are you considering New Zealand as your study destination? New Zealand's educational institutions provide diverse academic programs, accommodating students at all levels. The educational program in New Zealand is designed to improve students' ability to analyze and their global perspective, resulting in a strong need for graduates in the worldwide job market. Moreover, New Zealand stands out as a breathtaking destination with inspiration in every aspect. At Guidance Plus, a top education consultancy for New Zealand, you can be assured that we will help you achieve your study abroad goals.</p>

    <h6>The Upsides</h6>

    <ul>
    <li>Home to eight globally renowned universities.</li>
    <li>Offers a wide range of academic programs.</li>
    <li>Known for educational excellence.</li>
    <li>Provides immense post-study work opportunities.</li>
    <li>Offers internships and placements.</li>
    <li>Universities are government-owned.</li>
    <li>Plenty of part-time work opportunities.</li>
    <li>Enormous chances for cutting-edge research and innovation.</li>
    </ul>`,
  pageContent: [
    {
      heading: "Requirements",
      content: `<p>Entry requirements for studying in New Zealand include achieving a minimum academic score of 50 - 60%. Furthermore, different courses have specific IELTS requirements. Bachelor’s, master’s, graduate diploma, PG diploma, and graduate certificate programs are all open to international students. The PG diploma or bachelor degree or Master degree courses are categorized under level 7 programs. The duration of the PG diploma is one year, and completing this course provides a 3-year stay-back period. Opting for a course from the green list increases the chances of obtaining permanent residency.</p>

      <h6>Bachelor’s</h6>
        <ul>
        <li>Academic Score: Minimum 60% marks in 12th</li>
        <li>IELTS: Overall: 6 Individual: 5.5</li>	
        <li>PTE: 50</li>
        </ul>
      <h6>Master’s</h6>
      <ul>
        <li>Academic Score: Minimum 50% marks for Bachelor’s	</li>
        <li>IELTS: Overall: 6.5 Individual: 6	</li>
        <li>PTE: 64</li>
        </ul>
      <h6>Graduate Diploma/Graduate Certification Program</h6>
        <ul>
        <li>Academic Score: Minimum 60% marks in 12th	</li>
        <li>Overall: 6 Individual: 6	</li>
        <li>PTE: 50</li></ul>
      <h6>PG Diploma</h6>	
      <ul>
        <li>Academic Score: Minimum 60% marks in Diploma/Bachelor’s	</li>
        <li>IELTS: Overall: 6.5 Individual: 6	</li>
        <li>PTE: 60</li>
        </ul>
        
      <p>Looking to understand the entry criteria for getting admitted to a prestigious university in New Zealand? Connect now with the top New Zealand education consultancy, Guidance Plus, for expert advice and support.</p>

      <h6>Admission Processes and Enrollment: </h6>
      <p>The usual intakes in New Zealand are in the months of February and July though few universities also have intakes in September and November. At Guidance Plus, our advisors will assist you on which program and university is appropriate. Once you've chosen your preferred university, we'll support you in finalizing your application. Once you've been accepted, you'll be required to undergo a medical examination and demonstrate financial stability to obtain your enrollment. Guidance Plus has over a decade of experience in international education and a flawless track record of securing visas for all applicants.</p>
      <p>New Zealand's education system follows a framework that aligns with international standards, ensuring that a degree or diploma from a New Zealand institution is globally recognized and esteemed.</p>


      <h6>Intakes</h6>
      <p>Major intakes are available in February, July and October</p>

      <ul class="education-list-style-one">
      <li>Prominent scholarships</li>
      <li>New Zealand Excellence Awards (NZEA)</li>
      <li>Palmerston North City Mayor's Goodwill Ambassador Scholarship</li>
      <li>New Zealand Commonwealth Scholarships</li>
      <li>SEG Scholarship</li>
      <li>Eamon Molloy Memorial Scholarship</li>
      <li>Victoria Master’s Scholarship</li>
      <li>A C Rayner Memorial Scholarship</li>
      <li>The University of Waikato Excellence Scholarship for Asia</li>
      </ul>`,
    },
  ],
  universities: [
    { logo: university335 },
    { logo: university336 },
    { logo: university337 },
    { logo: university338 },
    { logo: university339 },
    { logo: university340 },
    { logo: university341 },
    { logo: university342 },
    { logo: university343 },
    { logo: university344 },
    { logo: university345 },
    { logo: university346 },
    { logo: university347 },
    { logo: university348 },
    { logo: university349 },
    { logo: university350 },
    { logo: university351 },
    { logo: university352 },
    { logo: university353 },
    { logo: university354 },
    { logo: university355 },
    { logo: university356 },
    { logo: university357 },
    { logo: university358 },
    { logo: university359 },
    { logo: university360 },
    { logo: university361 },
    { logo: university362 },
  ],
};

countryDetails[12] = {
  name: "Study in Sweden",
  image: coursesGrid12,
  link: "/study-in-sweden",
  pageTitle: "Study in Sweden",
  pageDesc: `<h6>At a Glance</h6>
    <p>The Kingdom of Sweden is the official name of Sweden and it is perched further into the northern region of Europe.</p>
    <p>Sweden is a nation surrounded by many seas, thousands of islands, and indifferent plains with innumerable internal lakes alongside boreal forests swathed with glacial mountains. To the west, it is bordered by Norway and to the East Finland while to the southwest it has a bridge-tunnel that connects it to Denmark across the Oresund Straits.</p>
    <p>Sweden is a very advanced country, being on the 6th place, according to the Human Development Index.</p>
    <p>Another distinction would be the way one studies in Sweden, which is completely different than studying anywhere else since Swedish universities have an open atmosphere with advanced pack work. The emphasis of the education system of Sweden is rather on your personal interests and passions than making you try to get a certain mark.</p>

    <h6>The Upsides</h6>
    <ul>
    <li>Affordable and Quality Education</li>
    <li>English-Taught Courses</li>
    <li>Easy Admission Process</li>
    <li>High Visa Success Ratio</li>
    <li>Technically strong Country in Innovation</li>
    <li>Industry Centric Courses</li>
    <li>Liberal Scholarships</li>
    <li>Excellent International Study Environment</li>
    </ul>`,
  pageContent: [
    {
      heading: "Requirements",
      content: `<ul>
        <li>STEM Graduates - IELTS/GRE is exempted.</li>
        <li>Management Studies - IELTS based on University requirement</li>
        </ul>

        <h6>Academic Qualifications:</h6>

        <p>Bachelor’s Degree: Minimum of 60% in 12th grade required.</p>
        <p>IELTS - Overall band-6 Individual - 5.5</p>

        <p>Master’s Degree: Minimum of 60% in Bachelor’s degree required.</p>
        <p>IELTS - Overall band-6.5 Individual - 6</p>



        <h6>Intakes</h6>
        <p>Mainly two intakes in the month of January & August</p>

        <ul class="education-list-style-one">
        <li>Prominent scholarships</li>
        <li>Swedish Institute Scholarships for Global Professionals (SISGP)</li>
        <li>Lund University Global Scholarship</li>
        <li>Karolinska Institute Glabal Master's Scholarships</li>
        <li>Erasmus Mundus Joint Masters Scholarships</li>
        <li>Chalmers IPOET Scholarships</li>
        </ul>`,
    },
  ],
  universities: [
    { logo: university387 },
    { logo: university388 },
    { logo: university389 },
    { logo: university390 },
    { logo: university391 },
    { logo: university392 },
    { logo: university393 },
  ],
};

countryDetails[13] = {
  name: "Study in Finland",
  image: coursesGrid13,
  link: "/study-in-finland",
  pageTitle: "Study in Finland",
  pageDesc: `<h6>At a Glance</h6>
    <p>Education in Finland is known across the globe and pursuing studies in this country is highly interesting and enables one to acquire new knowledge and change the outlook on the teaching methods while actively participating in student life in a safe environment. Finnish institutions are more research oriented and place emphasis on the students activities.</p>
    <p>The supporters of Finland offer quality education, do a lot for students, allowing them to focus on research and hence it becomes an ideal place for students. Most of the universities and their teachers in Finland encourage the students and their expertise is quite useful.</p>

    <h6>The Upsides</h6>
    <ul class="education-list-style-one">
    <li>Affordable and Quality Education</li>
    <li>High Standard of Living</li>
    <li>Easy Admission Process</li>
    <li>IELTS not Mandatory</li>
    <li>Strong Research and Innovation Culture</li>
    <li>Healthcare and social welfare system is good</li>
    </ul>`,
  pageContent: [
    {
      heading: "Academic Qualifications",
      content: `<p>Bachelor’s Degree: Minimum of 60% in 12th grade required.</p>
        <p>IELTS (not mandatory) - Overall band score - 6  individual score - 5.5</p>

        <p>Master’s Degree: Minimum of 60% in Bachelor’s degree required.</p>
        <p>IELTS (not mandatory) - Overall band score - 6  individual score - 5.5</p>

        <h6>Intakes</h6>
        <p>There are 2 major intakes for international students. The intakes are in February and September.</p>

        <ul class="education-list-style-one">
        <li>Prominent scholarships</li>
        <li>ERCIM Alain Bensoussan Fellowship Program</li>
        <li>Education Future International Scholarship</li>
        <li>Dr.Eduard Gubelin Research Scholarship</li>
        <li>Higher Education Scholarship Test for Indian Students HEST</li>
        <li>ACI Foundation Richard D.Stehly Memorial Scholarships</li>
        <li>K C Mahindra Scholarships for Post-Graduate Studies Abroad</li>
        </ul>`,
    },
  ],
  universities: [
    { logo: university223 },
    { logo: university224 },
    { logo: university225 },
    { logo: university226 },
    { logo: university227 },
    { logo: university228 },
    { logo: university229 },
    { logo: university230 },
    { logo: university231 },
    { logo: university232 },
    { logo: university233 },
    { logo: university234 },
    { logo: university235 },
    { logo: university236 },
    { logo: university237 },
    { logo: university238 },
  ],
};

countryDetails[14] = {
  name: "Study in Denmark",
  image: coursesGrid14,
  link: "/study-in-denmark",
  pageTitle: "Study in Denmark",
  pageDesc: `<h6>At a Glance</h6>
    <p>Denmark has been mesmerizing tourists for centuries owing to its breathtaking landscape, embracing culture and immaculate coastlines.
    With over a thousand years of history and culture, as well as vast picturesque Scandinavian scenery to visit, each one of your moments as an international students in Denmark will surely be great fun and one you will forever treasure.
    Due to the overall well-being of citizens, the efficiency of the educational process and beautiful nature it is very comfortable to study in this country.
    Denmark owns a well renounced education system which prioritizes student centered approaches and inter disciplinary learning.</p>

    <h6>The Upsides</h6>
    <ul class="education-list-style-one">
    <li>Top ranked universities</li>
    <li>Numerous grants and scholarships</li>
    <li>Reasonable living costs</li>
    <li>Key Industries</li>
    <li>Happiest Country in World</li>
    <li>Top rated academic collaborations</li>
    <li>Global Outlook</li>
    <li>Multicultural Environment</li>
    </ul>`,
  pageContent: [
    {
      heading: "Academic Qualifications",
      content: `<p>Bachelor’s Degree: Minimum of 60% in 12th grade required.</p>
      <p>IELTS - Overall band score with minimum 6.0</p>

      <p>Master’s Degree: Minimum of 60% in Bachelor’s degree required.</p>
      <p>IELTS - Overall band score with minimum 6.5</p>

      <p>MBA Degree: Minimum of 60% in Bachelor's degree & minimum of 2years management experience.</p>
      <p>IELTS - Overall band score with minimum 6.5</p>

      <h6>Intakes</h6>
      <p>There are 2 intakes in Danish Universities. They are from January to March and July to September.</p>


      <ul class="education-list-style-one">
      <li>Prominent scholarships</li>
      <li>Erasmus Mundus Scholarships</li>
      <li>Nordplus Scholarship</li>
      <li>Danish Government Scholarship(Ministry of Higher Education and Science)</li>
      <li>Danish State Tution Fee Waivers and Scholarships at Roskilde University</li>
      <li>Fulbright Denmark</li>
      <li>The Lendo Scholarship</li>
      <li>K C Mahindra Scholarships for Post-Graduate Studies Abroad</li>
      <li>Education Future International Scholarship</li>
      </ul>`,
    },
  ],
  universities: [
    {
      logo: university178,
    },
    {
      logo: university179,
    },
    {
      logo: university180,
    },
  ],
};

countryDetails[15] = {
  name: "Study in Malta",
  image: coursesGrid15,
  link: "/study-in-malta",
  pageTitle: "Study in Malta",
  pageDesc: `<h6>At a Glance</h6>
    <p>The Republic of Malta is an island nation in the Mediterranean Sea, situated to the south of Italy and North of Libya. Three islands are inhabited including some islands which impress visitors by their culture, historical places and natural beauty. Malta is renowned for its prehistoric architectural sites that date back over eight thousand years with the oldest megalithic temples being older than ancient Egypt’s pyramid and the Stonehenge.</p>
    <p>Malta is situated in the Mediterranean and being a small country it has a great history, beautiful landscapes, the best educational institutions in the world, and more. Moreover, the archipelago also houses one of the oldest and leading universities in the Mediterranean along with few foreign educational bodies like GBS Malta. This has made it a hot spot for overseas students who wish to study in Malta.</p>

    <h6>The Upsides</h6>
    <ul class="education-list-style-one">
    <li>High Quality Education</li>
    <li>English-Taught Courses</li>
    <li>Safe Study Destination</li>
    <li>Exciting Career Opportunities</li>
    <li>Education Tax benefits</li>
    <li>Low Budget Country</li>
    <li>Part time job Opportunities</li>
    <li>Diverse Courses</li>
    </ul>`,
  pageContent: [
    {
      heading: "Academic Qualifications",
      content: `<p>Bachelor’s Degree: Minimum of 50% in 12th grade required.</p>
      <p>IELTS - Overall Score - 6, Individual Score - 5.5</p>

      <p>Master’s Degree: Minimum of 50% in Bachelor’s degree required.</p>
      <p>IELTS - Overall Score - 6.5, Individual Score - 6</p>

      <h6>Intakes</h6>
      <p>Mainly intakes are availiable in months of February, May, June and October.</p>
      <p>Some Universities in Malta also intakes students in January, April and September.</p>


      <ul class="education-list-style-one">
      <li>Prominent scholarships</li>
      <li>Erasmus Mundus Scholarship</li>
      <li>Education Future International Scholarship</li>
      <li>ACI Foundation Richard D.Stehly Memorial Scholarships</li>
      <li>ServiceScape Scholarship</li>
      <li>Rotary Foundation Global Scholarship Grants for Development</li>
      <li>Richard A.Freund International Scholarship</li>
      </ul>`,
    },
  ],
  universities: [
    {
      logo: university320,
    },
    {
      logo: university321,
    },
  ],
};

countryDetails[16] = {
  name: "Study in Estonia",
  image: coursesGrid16,
  link: "/study-in-estonia",
  pageTitle: "Study in Estonia",
  pageDesc: `<h6>At a Glance</h6>
    <p>Are you considering pursuing your further education in Estonia? Are you eager to find out more about your overseas study options? Guidance Plus is available to assist. We have over ten years of experience and are experts at assisting students just like you in navigating the challenges of studying in Estonia. Our counsellors are ready to offer you various informations you require, including information on course alternatives, college options, cost schedules, and application procedures. Make your dream of attending university in Estonia a reality by getting in touch with us for individualized advice!</p>
    <h6>The Upsides</h6>
    <p>Approximately there are about more than 160 study programs in English available for international students in Estonia. Doctoral candidates can pursue their studies at different European universities in four main regions: the Baltic area, the Tallinn region, and the Latvian region of the Russian Federation. This arrangement gives students convenient access to a range of financial assistance options. Estonia is also considered to be one of the best places to live and settle, due to its high standard of living and pristine environment.</p>
    `,
  pageContent: [
    {
      heading: "Benefits of Studying in Estonia",
      content: `<ol>
        <li>Programs offered in English</li>
        <li>Free Internet Access</li>
        <li>Cost-effective tuition and living expenses</li>
        <li>Easy Admission Process</li>
        <li>High success rate for visa applications</li>
        <li>No mandatory IELTS requirement</li>
        <li>Schengen Group Country</li>
        </ol>

        <p>If Estonia is your preferred destination for further studies, having essential information readily available is vital. Our Estonia Education Consultants are here to provide you with a brief overview of the country, its education system, and other relevant details to help you make well-informed decisions.</p>


        <h6>Entry Requirements to study in Estonia</h6>

        <p>Universities provide both general requirements and specific criteria for admissions. All higher education institutions must follow the established guidelines.</p>

        <ol>
        <li>IELTS: Overall – Overall band score of 6.0, with a minimum of 5.5 in each band</li>
        <li>TOEFL: Minimum score of 72</li>
        <li>Previous education: At least 50% of previous education history</li>
        </ol>

        <p>Contact Guidance Plus, the No.1 Estonia study abroad consultants, to learn more about the entry requirements.</p>

        <h6>Documents Required</h6>

        <ol>
        <li>A copy of passport or ID card</li>
        <li>A passport picture</li>
        <li>Statement of purpose</li>
        <li>Copies of obtained secondary school diplomas, certificates, and grade lists</li>
        <li>Transcript of records</li>
        <li>Proof of English language proficiency</li>
        </ol>

        <p>With the support of Guidance Plus, you can choose the perfect program and college that match your interests and skills. Our experienced education specialists will guide you through the entire application process for an Estonian student visa. As the leading Estonian education consultant, we take pride in helping young people achieve their dreams.</p>


        <h6>Prominent scholarships</h6>

        <p>Explore the wide range of scholarships offered for studying in Estonia, which are primarily supported by public funding. International students with a valid passport are eligible to apply for these scholarships.</p>

        <h6>Most Demanded UG/PG Programmes</h6>
        <ol>
        <li>Computer Security</li>
        <li>Networking</li>
        <li>IT</li>
        <li>Cyber Security</li>
        <li>Digital Applications</li>
        </ol>

        <h6>Universities recommended by us</h6>
        <ol>
        <li>Tallinn University</li>
        <li>Tallinn University of Technology</li>
        <li>Estonian University of Life Sciences</li>
        <li>Estonian Entrepreneurship of Applied Sciences</li>
        <li>Tartu Health Care College</li>
        <li>TTK University of Applied Sciences</li>
        <li>Estonian Business School</li>
        <li>Estonian Information Technology College</li>
        <li>Pallas University of Applied Sciences</li>
        <li>Euroacademy</li>
        <li>University of Tartu</li>
        </ol>`,
    },
  ],
  universities: [
    { logo: university214 },
    { logo: university215 },
    { logo: university216 },
    { logo: university217 },
    { logo: university218 },
    { logo: university219 },
    { logo: university220 },
    { logo: university221 },
    { logo: university222 },
  ],
};

countryDetails[17] = {
  name: "Study in Lithuania",
  image: coursesGrid17,
  link: "/study-in-lithuania",
  pageTitle: "Study in Lithuania",
  pageDesc: `<h6>At a Glance</h6>
    <p>Lithuania boasts a well-developed education system which unites ancestral traditions and modernity in every possible way. Numerable number of English programs are offered to students and it includes various degrees from engineering and IT to business and humanities. Addition to research institution, Lithuania has a vibrant startup ecosystem where students can get hands-on experience and build skills necessary for working in an international environment.</p>
    <h6>The Upsides</h6>
    <ul class="education-list-style-one">
    <li>Affordable fees & Reasonable living expenses</li>
    <li>High quality education with wide variety of courses</li>
    <li>High quality of life</li>
    <li>Welcoming Environment & Friendly People</li>
    <li>Multicultural Environment with diverse population</li>
    <li>Work Opportunities</li>
    </ul>`,
  pageContent: [
    {
      heading: "Required Tests",
      content: `<p>IELTS - Overall band score of 5.5-6.5</p>
      <p>TOEFL - Score of 65-105</p>

      <h6>Intakes</h6>
      <p>Major intakes are available in September</p>

      <h6>Part-Time Job</h6>
      <p>International students are allowed to work part time jobs while they are enrolled in classes. No limitations for European Union Students while Non EU students are permitted to work for 20hours a week during school year and full time during summer.</p>

      <p>Prominent scholarships</p>
      <p>Erasmus+ Programme</p>
      <p>Lithuanian State Scholarship Programme</p>`,
    },
  ],
  universities: [
    { logo: university299 },
    { logo: university300 },
    { logo: university301 },
    { logo: university302 },
    { logo: university303 },
    { logo: university304 },
    { logo: university305 },
    { logo: university306 },
    { logo: university307 },
    { logo: university308 },
    { logo: university309 },
  ],
};

countryDetails[18] = {
  name: "Study in Netherlands",
  image: coursesGrid18,
  link: "/study-in-netherlands",
  pageTitle: "Study in Netherlands",
  pageDesc: `<h6>At a Glance</h6>
    <p>Holland, also known as The Kingdom of Netherlands, is one of the small northern European country with the North sea in the north. It is one of the Low Countries or Benelux countries with Belgium and Luxembourg. The region is predominantly known for its flat terrain, decorated with networks of waterways, tulip gardens, wind turbines and highways specially designated for bicycles. The official capital is Amsterdam while the center of the government is in the Hague.</p>
    <p>Holland supports one of the best higher education systems and also offers higher degrees in scientific and engineering courses with much Employment opportunities.</p>
    <p>Holland boasts of beautiful country sides and vibrant cities filled with strings of art galleries, cafes and historical places which can all be tour on a bicycle. While staying in Holland you can take inexpensive trips to countries such as Germany and France as it provides cheap flights, bus and train direct to other countries in Europe.</p>

    <h6>The Upsides</h6>
    <ul>
    <li>High Quality Education </li>
    <li>Affordable living Expenses</li>
    <li>Diverse Population</li>
    <li>English taught Courses</li>
    <li>IELTS not mandatory</li>
    <li>Cities with best quality of infrastructure</li>
    <li>Internationaly recognized courses</li>
    </ul>`,
  pageContent: [
    {
      heading: "Academic Qualifications",
      content: `<p>Bachelor’s Degree: Minimum of 75% in 12th grade required.</p>
      <p>IELTS - Minimum band of 6 </p>
      <p>TOEFL- Score of 550</p>

      <p>Master’s Degree: Completion of 3-year bachelor degree in arts, science or commerce.</p>
      <p>IELTS - Minimum band of 6 </p>
      <p>TOEFL- Score of 550</p>

      <h6>Intakes</h6>
      <p>Major intakes are available in February and September</p>

      <h6>Prominent scholarships</h6>
      <p>NL Scholarship (Holland Scholarship)</p>`,
    },
  ],
  universities: [
    { logo: university322 },
    { logo: university323 },
    { logo: university324 },
    { logo: university325 },
    { logo: university326 },
    { logo: university327 },
    { logo: university328 },
    { logo: university329 },
    { logo: university330 },
    { logo: university331 },
    { logo: university332 },
    { logo: university333 },
    { logo: university334 },
  ],
};

countryDetails[19] = {
  name: "Study in Poland",
  image: coursesGrid19,
  link: "/study-in-poland",
  pageTitle: "Study in Poland",
  pageDesc: `<h6>At a Glance</h6>
    <p>Poland, located in Central Europe with a population nearing 37.96 million, shares borders with Germany, the Czech Republic, Slovakia, Ukraine, Belarus, Lithuania, Latvia, and the Baltic Sea. Human presence in Poland dates back to the Paleolithic Age, making it a country with a rich historical legacy. For international students, Poland is a prime choice not just because of its renowned universities and attractive job prospects, but also due to its affordable living standards compared to other European countries, which is ideal for those on a budget. Polish universities are known for their high educational quality, featuring European-accredited teaching staff and offering a wide range of study programs including medicine, engineering, arts, and social sciences.</p>

    <h6>The Upsides/<h6>
    <ul class="education-list-style-one">
    <li>Affordable tution fees & Living Cost</li>
    <li>Wide range of English taught Courses</li>
    <li>Availiable Scholarships</li>
    <li>Young Innovators</li>
    <li>Internationally recognized Courses</li>
    <li>IELTS not mandatory</li>
    <li>Friendly and Supportive Environment</li>
    </ul>`,
  pageContent: [
    {
      heading: "Mandatory Documentation",
      content: `<p>Proof for English Language Competency must be submitted for enrollment.</p>
      <p>IELTS and TOEFL are non-mandatory.</p>

      <ul>
      <li>Educational Structure</li>
      <li>Bachelor's Degree</li>
      <li>Master's Degree</li>
      <li>Doctor of Philosophy</li>
      </ul>

      <h6>Intakes</h6>
      <p>Major intakes are available in February and September</p>


      <ul class="education-list-style-one">
      <li>Prominent scholarships</li>
      <li>Polish Government Scholarship Programme</li>
      <li>The Banach Scholarship Programme</li>
      <li>The Konstanty Kalinowski Scholarship Programme</li>
      <li>Lukasiewicz Scholarship Programme</li>
      <li>University-Specific Scholarships</li>
      </ul>`,
    },
  ],
  universities: [
    { logo: university367 },
    { logo: university368 },
    { logo: university369 },
    { logo: university370 },
  ],
};

countryDetails[20] = {
  name: "Study in Norway",
  image: coursesGrid20,
  link: "/study-in-norway",
  pageTitle: "Study in Norway",
  pageDesc: `<h6>At a Glance</h6>
    <p>Norway, located on the western side of the Scandinavian Peninsula, features a long coastline and stunning nature reserves, making it one of Europe's prime tourist spots. Its climate ranges from severe subarctic in the north to gentler temperate conditions in the south. The country's landscape, with more than three-fifths being hilly, offers beautiful bays and natural beaches perfect for outdoor activities.</p>
    <p>Despite its size, Norway is home to a variety of universities, university colleges, and other higher education institutions that often exceed American educational standards.</p>

    <h6>The Upsides</h6>
    <ul class="education-list-style-one">
    <li>High Quality education</li>
    <li>Healthy work life balance</li>
    <li>Diverse and inclusive Environment</li>
    <li>Internationally recognized Courses</li>
    <li>One of World's happiest Countries</li>
    <li>Easy Admission Process</li>
    <li>High Innovative Job Opportunities</li>
    </ul>
    
    <h6>Language Requirements</h6>
    <p>TOEFL, IELTS, PTE, MOI are accepted</p>`,
  pageContent: [
    {
      heading: "Academic Qualifications",
      content: `<p>Bachelor’s Degree: Above 70% in 12th grade required.</p>
      <p>Master’s Degree: Above 70% in Bachelor’s degree required.</p>

      <h6>Part Time</h6>
      <p>Graduate and diploma students are allowed to work part-time for up to 40 hours each week.</p>

      <h6>Stay Back</h6>
      <ol>
      <li>Bachelor’s Degree: 2 years</li>
      <li>Master’s Degree & PhD: Upto 4 years</li>
      </ol>

      <h6>Intakes</h6>
      <p>There is only a single intake and most of the intake occurs between December and March.</p>

      <ul class="education-list-style-one">
      <li>Prominent scholarships</li>
      <li>SCAR Fellowships in Antarctic Research</li>
      <li>Microsoft Research PhD Fellowship</li>
      <li>Mobility Grant for Norwegian Language nd Literature</li>
      <li>The ERASMUS+ Grant</li>
      <li>Wilhelmsen Foundation Scholarship Programme</li>
      <li>BI Presidential Scholarship</li>
      <li>NTNU Scholarship</li>
      <li>Scholarship Grant for Undergradute Students</li>
      <li>The $2000 "Privacy Matter" College Scholarship</li>
      <li>CLIFF-GRAD Scholarship (PhD)</li>
      <li>Become JACK and JONES Scholar (Masters and PhD)</li>
      </ul>`,
    },
  ],
  universities: [
    { logo: university363 },
    { logo: university364 },
    { logo: university365 },
    { logo: university366 },
  ],
};
export const COUNTRY_DETAILS = countryDetails;
