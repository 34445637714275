import React, { useEffect } from "react";

const OciServices = ({ pageTitle }) => {
  useEffect(() => {
    document.title = `${pageTitle ?? ""} - GuidanceplusNI`;
  }, []);

  return (
    <div class="education-main-content">
      <div class="education-main-section">
        <div class="container">
          <div className="row">
            <div class="col-md-12">
              <div class="education-section-heading">
                <h2>INTRODUCTION</h2>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <ul>
                    <li>
                      The Constitution of India prohibits holding dual citizenship, i.e., Indian
                      citizenship and citizenship of another country simultaneously.
                    </li>
                    <li>
                      Based on recommendations from the High Level Committee on Indian Diaspora, the
                      Government of India introduced the Overseas Citizen of India (OCI) card.
                    </li>
                    <li>
                      The OCI card is available to certain categories of Persons of Indian Origin
                      (PIOs), as outlined in Section 7A of the Citizenship Act, 1955.
                    </li>
                    <li>
                      Applications for registration as an OCI Cardholder can be submitted online.
                    </li>
                    <li>To apply, visit: https://ociservices.gov.in.</li>
                    <li>
                      Before filling out the application, carefully read the instructions to avoid
                      any mistakes.
                    </li>
                    <li>
                      Review the details regarding the application fee and the offices where
                      original supporting documents must be submitted for verification.
                    </li>
                  </ul>
                </div>
              </div>

              <div class="education-section-heading">
                <h2>Benefits for Registered OCI Cardholders: </h2>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <h2>Lifelong Multiple Entry Visa</h2>
                  <p>
                    OCI cardholders can visit India for any purpose with a lifelong, multiple-entry
                    visa.
                  </p>
                  <p>Special permission is required for activities such as:</p>
                  <ul>
                    <li>Research</li>
                    <li>Missionary work, mountaineering, or journalism</li>
                    <li>Internships or jobs with foreign diplomatic missions</li>
                    <li>Visiting protected or restricted areas</li>
                  </ul>
                  <p>
                    For special permission, visit:{" "}
                    <a href="https://ociservices.gov.in">https://ociservices.gov.in</a>.
                  </p>

                  <h2>No Registration Required</h2>
                  <p>
                    OCI cardholders do not need to register with the Foreigners Regional
                    Registration Officer (FRRO) for any length of stay in India. Residents must
                    notify the FRRO of any changes in address or occupation via email.
                  </p>

                  <h2>Equal Benefits with Indian Nationals</h2>
                  <p>OCI cardholders enjoy the same benefits as Indian nationals, including:</p>
                  <ul>
                    <li>Domestic airfare tariffs</li>
                    <li>Entry fees for national parks, wildlife sanctuaries, and museums</li>
                  </ul>

                  <h2>Equal Benefits with Non-Resident Indians (NRIs)</h2>
                  <p>OCI cardholders have similar rights as NRIs, including:</p>
                  <ul>
                    <li>Adoption of Indian children (following proper procedures)</li>
                    <li>
                      Eligibility for entrance exams for NRI seats (not for seats reserved for
                      Indian citizens)
                    </li>
                    <li>Buying and selling non-agricultural property</li>
                    <li>Pursuing professions like medicine, law, architecture, and accounting</li>
                  </ul>

                  <h2>Economic and Educational Rights</h2>
                  <p>
                    In areas not specified by the Reserve Bank of India, OCI cardholders have the
                    same rights as foreigners.
                  </p>

                  <h2>Teaching Positions</h2>
                  <p>
                    OCI cardholders can apply for teaching roles at prestigious institutions like
                    IITs, NITs, and IIMs.
                  </p>

                  <h2>Notes</h2>
                  <ul>
                    <li>OCI cardholders are foreign nationals, not Indian citizens.</li>
                    <li>
                      "Non-Resident Indian" refers to individuals defined by the Foreign Exchange
                      Management Act and Income Tax Act.
                    </li>
                  </ul>
                </div>
              </div>

              <div class="education-section-heading">
                <h2>Additional Benefits: </h2>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <p>
                    The Central Government may announce more benefits for OCI cardholders as needed.
                  </p>

                  <h2>Benefits Not Available to OCI Cardholders</h2>
                  <h3>Political Rights</h3>
                  <p>
                    OCI cardholders cannot vote or become members of the Legislative Assembly,
                    Legislative Council, or Parliament. They are not eligible for constitutional
                    positions such as President, Vice President, or Judge of the Supreme Court or
                    High Court (as stated in Section 7B(2) of the Citizenship Act, 1955).
                  </p>

                  <h3>Public Service Appointments</h3>
                  <p>
                    OCI cardholders are not eligible for public service positions or posts related
                    to the affairs of the Union or any State, except for roles specified by the
                    Central Government through special orders.
                  </p>

                  <h3>Property Ownership Restrictions</h3>
                  <p>
                    OCI cardholders cannot acquire agricultural land, farmhouses, or plantation
                    properties in India.
                  </p>

                  <h2>Identification for OCI Cardholders</h2>
                  <p>
                    State Governments must recognize the OCI Cardholder registration booklets as
                    valid identification for accessing services provided to them.
                  </p>

                  <h2>Eligibility for Indian Citizenship for OCI Cardholders</h2>
                  <p>An OCI Cardholder can apply for Indian citizenship if they:</p>
                  <ul>
                    <li>Are at least 18 years old.</li>
                    <li>Have held their OCI status for five years.</li>
                    <li>Have lived in India for at least twelve months before applying.</li>
                  </ul>
                  <p>
                    The Central Government can reduce the twelve-month residency requirement by up
                    to thirty days in special cases, which can be taken in breaks, as long as the
                    reasons are documented.
                  </p>

                  <h2>Overseas Citizen of India (OCI) Cardholder - Miscellaneous Services</h2>
                  <h3>OCI Cardholder Scheme Overview</h3>
                  <p>
                    The OCI Cardholder Scheme began on December 2, 2005, and was formally launched
                    by the Prime Minister during Pravasi Bhartiya Diwas in Hyderabad on January 7,
                    2006. It has received a positive response.
                  </p>

                  <h3>Common Requests for Re-issuance</h3>
                  <p>
                    After the launch, suggestions were received about re-issuing OCI Cards in
                    specific situations, such as:
                  </p>
                  <ul>
                    <li>When a new passport is issued.</li>
                    <li>When personal details change (e.g., nationality).</li>
                    <li>If the OCI registration certificate is lost or damaged.</li>
                    <li>If incorrect personal details were provided in the online application.</li>
                    <li>When changing address or occupation.</li>
                    <li>
                      If there’s an official error in uploading the applicant's photo or signature.
                    </li>
                  </ul>

                  <h3>OCI Miscellaneous Services</h3>
                  <p>
                    In response to these requests, the OCI Miscellaneous Services were launched
                    online on January 1, 2008, allowing registered OCI cardholders to apply for
                    re-issuance of their OCI Cards and update their details. Applications can be
                    submitted at:{" "}
                    <a href="https://ociservices.gov.in">https://ociservices.gov.in</a>.
                  </p>

                  <h3>Eligibility and Application Process</h3>
                  <p>
                    Only registered OCI cardholders can use these services. Applicants should check
                    FAQs and the brochure for guidance and review application guidelines to avoid
                    mistakes.
                  </p>

                  <h3>Application Processing</h3>
                  <p>
                    Decisions on applications are usually made within one month. Fees are
                    non-refundable if services are not granted. New cards are issued only after
                    canceling the old ones, except for address or occupation changes.
                  </p>

                  <h3>Document Upload and Notifications</h3>
                  <p>
                    After uploading documents, a confirmation email will be sent. There are no
                    travel restrictions during the application process, and updates are free of
                    charge.
                  </p>

                  <h2>Brochure</h2>
                  <h3>A. Eligible Categories (Section 7A, Citizenship Act, 1955)</h3>
                  <p>The following foreign nationals can apply for OCI registration:</p>
                  <ol>
                    <li>
                      A person who is a citizen of another country but:
                      <ul>
                        <li>
                          Was a citizen of India at any time after the Constitution's commencement
                          (26.01.1950).
                        </li>
                        <li>
                          Was eligible to become an Indian citizen when the Constitution started
                          (26.01.1950).
                        </li>
                        <li>Belonged to a territory that became part of India after 15.08.1947.</li>
                        <li>Is a child, grandchild, or great-grandchild of such a citizen.</li>
                      </ul>
                    </li>
                    <li>A minor child whose parents or one parent is an Indian citizen.</li>
                    <li>
                      A spouse of Indian origin or an OCI cardholder with a valid marriage of at
                      least two years before applying, subject to security clearance.
                    </li>
                  </ol>
                  <p>
                    <strong>Note:</strong> Anyone with a parent or grandparent who was a citizen of
                    Pakistan, Bangladesh, or any other country specified by the Indian government
                    cannot apply for OCI registration.
                  </p>

                  <h2>Widowed Foreign Spouse</h2>
                  <p>
                    If the Indian spouse dies, the foreign spouse can still apply for OCI status if:
                  </p>
                  <ul>
                    <li>They have children who are Indian citizens or OCI cardholders.</li>
                    <li>They jointly or individually own property with the deceased spouse.</li>
                    <li>They fulfill any other relevant conditions.</li>
                  </ul>
                  <p>
                    <strong>Note:</strong> If the foreign spouse remarries another foreign national
                    or Indian, the OCI status will be canceled. A fresh OCI application is required
                    if remarried to another Indian or OCI cardholder.
                  </p>

                  <h2>Children of Deceased Indian Nationals</h2>
                  <p>
                    Children of a deceased Indian parent can register as OCI cardholders based on
                    their Indian origin, under section 7A of the Citizenship Act, 1955.
                  </p>

                  <h2>Spousal Conditions</h2>
                  <p>
                    Only one living spouse can be registered as an OCI cardholder, as per section
                    7A.
                  </p>

                  <h2>Note</h2>
                  <p>
                    An OCI card is a lifelong visa, but a valid passport is still required for
                    staying in India. An OCI cardholder without a valid passport is staying
                    illegally in India.
                  </p>

                  <h2>C. No More ‘U Visa Sticker’ on Foreign Passports for OCI Cardholders</h2>
                  <p>
                    As of January 29, 2015, the Ministry of Home Affairs decided to stop requiring
                    the ‘U’ (Universal) visa sticker on the foreign passports of OCI cardholders.
                  </p>
                  <ul>
                    <li>Indian Missions/Posts and FRROs were informed about this change.</li>
                    <li>
                      Immigration authorities at Indian checkpoints should no longer ask OCI
                      cardholders to show a foreign passport with the ‘U’ visa sticker when entering
                      or exiting India.
                    </li>
                  </ul>

                  <h2>D. How to Apply for OCI Card for Former PIO Cardholders</h2>
                  <p>
                    PIO cardholders with valid cards as of January 9, 2015, are now considered OCI
                    cardholders under a Ministry of Home Affairs notification. However, it is
                    advised that those with valid PIO cards apply for OCI cards due to the following
                    benefits:
                  </p>
                  <ul>
                    <li>Smart card format.</li>
                    <li>Faster immigration clearance at Indian checkpoints.</li>
                    <li>Easier access to Consular Services from Indian Missions.</li>
                    <li>For residents in India, easier access to various services.</li>
                  </ul>

                  <h3>How to Apply</h3>
                  <p>
                    A separate online application form for converting PIO to OCI is available at{" "}
                    <a href="https://ociservices.gov.in">https://ociservices.gov.in</a>
                  </p>

                  <h3>For PIO Cardholders Applying for an OCI Card</h3>
                  <ul>
                    <li>
                      <strong>Upload only:</strong>
                    </li>
                    <ul>
                      <li>A copy of the valid passport.</li>
                      <li>A copy of the PIO card.</li>
                    </ul>
                    <li>
                      <strong>Fee Details:</strong>
                    </li>
                    <ul>
                      <li>Abroad: US $100 (or equivalent local currency) via Demand Draft.</li>
                      <li>
                        In India: ₹5,500 via Demand Draft to "Pay and Accounts Officer
                        (Secretariat), Ministry of Home Affairs," payable at New Delhi.
                      </li>
                    </ul>
                  </ul>

                  <p>
                    Applications can be submitted online from anywhere. The OCI card will be issued
                    by the Indian Mission/Post/FRRO where the applicant resides. The applicant must
                    surrender the PIO card to collect the OCI card.
                  </p>

                  <h2>E. Cancellation of OCI Card Registration</h2>
                  <p>Registration as an OCI cardholder may be canceled if:</p>
                  <ul>
                    <li>
                      It was obtained through fraud, false representation, or concealment of
                      important facts.
                    </li>
                    <li>The OCI cardholder shows disaffection toward the Constitution of India.</li>
                    <li>
                      The individual falls under any provisions of Section 7D of The Citizenship
                      Act, 1955.
                    </li>
                  </ul>
                  <p>
                    In such cases, the registration will be immediately canceled, and the individual
                    will be blacklisted, preventing any future entry into India.
                  </p>

                  <h2>F. Benefits of an OCI Cardholder</h2>
                  <h3>Lifelong Visa</h3>
                  <p>
                    OCI cardholders receive a lifelong, multiple-entry visa to visit India for any
                    purpose. However, special permission is required for:
                  </p>
                  <ul>
                    <li>Research</li>
                    <li>Missionary, Tabligh, Mountaineering, or Journalistic activities</li>
                    <li>Internships in foreign diplomatic missions in India</li>
                    <li>Visits to Protected or Restricted areas</li>
                  </ul>
                  <p>
                    For special permission, visit{" "}
                    <a href="https://ociservices.gov.in">OCI Services</a>.
                  </p>

                  <h3>No Registration Requirement</h3>
                  <p>
                    OCI cardholders do not need to register with the Foreigners Regional
                    Registration Officer (FRRO) for any length of stay in India. However, they must
                    inform the FRRO about changes in their address or occupation.
                  </p>

                  <h3>Equal Treatment with Indians</h3>
                  <p>OCI cardholders have the same rights as Indian nationals regarding:</p>
                  <ul>
                    <li>Domestic airfare rates</li>
                    <li>Entry fees for national parks and monuments</li>
                  </ul>

                  <h3>Equal Rights with Non-Resident Indians (NRIs)</h3>
                  <ul>
                    <li>Adopt Indian children (following proper procedures)</li>
                    <li>Take all-India entrance tests for NRI or supernumerary seats</li>
                    <li>Buy or sell properties (excluding agricultural land)</li>
                    <li>Work in certain professions (e.g., doctors, lawyers, architects)</li>
                  </ul>

                  <h3>Rights in Other Fields</h3>
                  <p>
                    In areas not covered by specific regulations, OCI cardholders have the same
                    rights as foreigners.
                  </p>

                  <h3>Teaching Positions</h3>
                  <p>
                    OCI cardholders can apply for teaching jobs at major institutions like IITs and
                    IIMs.
                  </p>

                  <h3>Definitions</h3>
                  <p>An OCI cardholder is a foreign national who is not an Indian citizen.</p>
                  <p>
                    A Non-Resident Indian (NRI) is defined by The Foreign Exchange Management Act,
                    1999.
                  </p>

                  <h3>Additional Information</h3>
                  <ul>
                    <li>The Central Government may provide more benefits for OCI cardholders.</li>
                    <li>
                      OCI cardholders' registration booklets should be accepted as identification
                      for services.
                    </li>
                    <li>
                      OCI cardholders can apply for Indian citizenship if they are 18 or older,
                      registered for five years, and have lived in India for twelve months prior to
                      applying. This period can be reduced in special cases.
                    </li>
                  </ul>

                  <h2>G. Benefits Not Available to OCI Cardholders</h2>
                  <ul>
                    <li>
                      <strong>Political Rights:</strong> They cannot vote, become members of the
                      Legislative Assembly, Legislative Council, or Parliament, or hold
                      constitutional positions (e.g., President, Vice President, Supreme Court
                      Judge).
                    </li>
                    <li>
                      <strong>Public Service Positions:</strong> OCI cardholders are generally
                      ineligible for public service jobs with the Union or State, except for
                      specific roles that the Central Government may allow through special orders.
                    </li>
                    <li>
                      <strong>Property Restrictions:</strong> They cannot purchase agricultural
                      land, farmhouses, or plantation properties in India.
                    </li>
                  </ul>

                  <h2>H. Prior Special Permission for Certain Activities</h2>
                  <p>
                    OCI cardholders must obtain special permission before engaging in certain
                    activities:
                  </p>
                  <ul>
                    <li>Research</li>
                    <li>Missionary Work</li>
                    <li>Tabligh activities</li>
                    <li>Mountaineering</li>
                    <li>Journalism</li>
                    <li>
                      Internships/Employment in foreign diplomatic missions or government
                      organizations in India
                    </li>
                    <li>Visiting Restricted Areas</li>
                  </ul>
                  <p>
                    To request special permission, OCI cardholders should fill out the application
                    form available at <a href="https://ociservices.gov.in">OCI Services</a> and
                    upload the required documents.
                  </p>

                  <h3>Additional Notes</h3>
                  <ul>
                    <li>
                      Except for specific fees related to grants like PAP or RAP for visiting
                      Prohibited areas, all other services for obtaining prior permission are free
                      of charge.
                    </li>
                    <li>
                      Once an application is submitted, the OCI cardholder will receive an
                      acknowledgment email with a link to check the application status.
                    </li>
                    <li>
                      The FRRO or Indian Mission may request the applicant to upload any missing
                      documents and may require a personal interview if needed.
                    </li>
                    <li>
                      If the Special Permit is granted, an acknowledgment email will be sent, along
                      with a link to download the permit.
                    </li>
                  </ul>

                  <h1>I. OCI Miscellaneous Services Overview</h1>
                  <p>
                    OCI Miscellaneous Services were introduced on 01/01/2008 for the convenience of
                    OCI Cardholders. These services are for re-issuance of OCI Cards and updating
                    cardholder details, and they can only be availed by registered OCI Cardholders.
                  </p>

                  <h2>Re-issuance of OCI Cards</h2>
                  <h3>When to Apply:</h3>
                  <ul>
                    <li>Issuance of new passport after turning 20 years old.</li>
                    <li>Change of personal details (nationality, etc.).</li>
                    <li>Loss or damage of OCI registration certificate.</li>
                    <li>Correction of wrong details (e.g., name, date of birth, etc.).</li>
                    <li>Change of address or occupation.</li>
                  </ul>

                  <h3>Mandatory Re-issuance (Age 20+)</h3>
                  <p>
                    When a new passport is issued after the cardholder turns 20, re-issuance is
                    necessary to capture updated facial features. This can be done online with a fee
                    of US $25 or equivalent in local currency.
                  </p>

                  <h3>Application Process</h3>
                  <ul>
                    <li>
                      Submit online, upload required documents, photograph, and signature, and pay
                      the fee.
                    </li>
                    <li>
                      Bring original documents for verification to the Indian Mission/Post or FRRO
                      (based on jurisdiction).
                    </li>
                    <li>No hard copies or printouts of the application are required.</li>
                  </ul>

                  <h3>Verification of Documents</h3>
                  <p>
                    <strong>Outside India:</strong> Submit original supporting documents to:
                  </p>
                  <ul>
                    <li>The Indian Mission/Post in the country of your citizenship, or</li>
                    <li>
                      If residing in another country, submit to the Indian Mission/Post there.
                    </li>
                  </ul>
                  <p>
                    <strong>In India:</strong> Submit to the relevant FRRO based on your region.
                  </p>

                  <h3>FRRO Jurisdictional Control</h3>
                  <ul>
                    <li>
                      <strong>FRRO Amritsar:</strong> Punjab, Jammu & Kashmir, Chandigarh
                    </li>
                    <li>
                      <strong>FRRO Bengaluru:</strong> Karnataka
                    </li>
                    <li>
                      <strong>FRRO Chennai:</strong> Tamil Nadu, Puducherry, Andaman & Nicobar
                      Islands
                    </li>
                    <li>
                      <strong>FRRO Delhi:</strong> Delhi, Haryana, Rajasthan, Himachal Pradesh,
                      Uttarakhand, Gautam Budh Nagar, Ghaziabad (UP)
                    </li>
                    <li>
                      <strong>FRRO Hyderabad:</strong> Andhra Pradesh, Telangana, Odisha,
                      Chhattisgarh
                    </li>
                    <li>
                      <strong>FRRO Kochi:</strong> Kerala (excluding other FRRO areas)
                    </li>
                    <li>
                      <strong>FRRO Kolkata:</strong> West Bengal, Sikkim, Assam, Arunachal Pradesh,
                      Mizoram, Nagaland, Manipur, Meghalaya, Tripura
                    </li>
                    <li>
                      <strong>FRRO Kozhikode:</strong> Kerala (excluding other FRRO areas)
                    </li>
                    <li>
                      <strong>FRRO Lucknow:</strong> UP (except Gautam Budh Nagar & Ghaziabad),
                      Bihar, Jharkhand
                    </li>
                    <li>
                      <strong>FRRO Mumbai:</strong> Maharashtra, Goa, Dadra & Nagar Haveli, Daman &
                      Diu, Madhya Pradesh
                    </li>
                    <li>
                      <strong>FRRO Thiruvananthapuram:</strong> Kerala (excluding other FRRO areas),
                      Lakshadweep
                    </li>
                    <li>
                      <strong>FRRO Ahmedabad:</strong> Gujarat
                    </li>
                  </ul>

                  <h3>Document Upload for Re-issuance</h3>
                  <ul>
                    <li>
                      <strong>New Passport Issuance:</strong> Upload a copy of the OCI card, new
                      passport, and old passport. If the old passport is unavailable, it can be
                      omitted.
                    </li>
                    <li>
                      <strong>Loss of OCI Card:</strong> Upload the police complaint report
                      (translated into English if needed) and a copy of the OCI card.
                    </li>
                    <li>
                      <strong>Change of Personal Details:</strong> Upload documents proving the
                      requested changes (e.g., name change, address change, etc.). Acceptable
                      address proof includes electricity or phone bills, rent/lease agreements, etc.
                    </li>
                  </ul>
                  <p>
                    <strong>In Case of Loss/Damage:</strong> A personal interview is required, and
                    applicants will be notified of the interview date by the Indian Mission/FRRO.
                  </p>

                  <h2>Updation of Details</h2>
                  <p>
                    OCI Cardholders are required to upload a copy of their new passport and a recent
                    photograph online:
                  </p>
                  <ul>
                    <li>For minors: Each time a new passport is issued up to the age of 20.</li>
                    <li>For adults: Once after completing 50 years of age.</li>
                  </ul>
                  <p>This service is provided free of charge.</p>
                  <p>
                    For OCI cardholders registered as spouses of Indian citizens or OCI Cardholders,
                    additional documents must be uploaded, including the spouse’s Indian passport or
                    OCI card.
                  </p>

                  <h3>Travel Restrictions</h3>
                  <p>
                    There are no travel restrictions for OCI cardholders during the process of
                    updating their details. They can continue to travel to and from India during
                    this period.
                  </p>

                  <h3>Fees</h3>
                  <ul>
                    <li>Re-issuance due to loss/damage: US $100 or equivalent.</li>
                    <li>Re-issuance due to new passport (age 20+): US $25 or equivalent.</li>
                    <li>Updating details (e.g., new passport, address change): Free of charge.</li>
                  </ul>

                  <h3>Processing Time</h3>
                  <p>
                    The decision for re-issuance is typically made within one month from the date of
                    acknowledgement. The new OCI Card will only be issued after cancellation of the
                    old one.
                  </p>

                  <h2>How to Apply</h2>
                  <p>
                    Applicants can apply online for OCI Miscellaneous Services at:{" "}
                    <a href="https://ociservices.gov.in">OCI Services</a>.
                  </p>

                  <h2>J. Help Desk</h2>
                  <p>
                    For any clarification or queries regarding OCI Miscellaneous Services, you can:
                  </p>
                  <ul>
                    <li>
                      Visit the <a href="http://mha.gov.in">Ministry of Home Affairs website</a>
                    </li>
                    <li>Visit the website of the local Indian Mission/Post</li>
                    <li>
                      Contact the Indian Mission/Post or the relevant FRRO office in your
                      jurisdiction for assistance.
                    </li>
                  </ul>

                  <h2>K. Important Instructions</h2>
                  <h3>Special Permissions</h3>
                  <p>
                    The OCI Card serves as a lifelong multiple-entry visa for visiting India.
                    However, OCI cardholders need special permission for certain activities,
                    including:
                  </p>
                  <ul>
                    <li>
                      Research, missionary work, mountaineering, journalism, and Tabligh activities
                    </li>
                    <li>
                      Internships or employment at foreign Diplomatic Missions or Government
                      organizations in India
                    </li>
                    <li>
                      Visiting Protected, Restricted, or Prohibited areas in India (see Paras 6 & 8
                      for more details)
                    </li>
                  </ul>

                  <h3>OCI Card Re-Issuance</h3>
                  <p>
                    OCI cardholders must re-issue their card when a new passport is issued after the
                    age of 20 to update facial features. Visit{" "}
                    <a href="https://ociservices.gov.in">OCI Services</a> or{" "}
                    <a href="https://passport.gov.in/oci">Passport OCI</a> to apply.
                  </p>

                  <h3>Passport Updates</h3>
                  <p>
                    OCI cardholders should update passport details online every time a new passport
                    is issued until they reach age 20 and once after age 50. See Para 9.3 above for
                    more details.
                  </p>

                  <h3>Eligibility for OCI Card</h3>
                  <p>A foreign national can apply if they meet any of these criteria:</p>
                  <ul>
                    <li>Was an Indian citizen at any time after January 26, 1950</li>
                    <li>Was eligible for Indian citizenship on January 26, 1950</li>
                    <li>Belonged to a territory that became part of India after August 15, 1947</li>
                    <li>Is a descendant of a previous Indian citizen</li>
                    <li>Is a minor child of any eligible person or Indian citizen</li>
                    <li>
                      Is a foreign spouse of an Indian citizen or OCI cardholder, married for at
                      least two continuous years
                    </li>
                  </ul>

                  <h3>Exclusions</h3>
                  <p>
                    Individuals with ancestry from Pakistan, Bangladesh, or specified countries are
                    ineligible for OCI.
                  </p>

                  <h3>Restrictions for Foreign Nationals</h3>
                  <p>
                    Foreign nationals cannot apply for OCI on Tourist, Missionary, or Mountaineering
                    Visas and must reside in India for at least 6 continuous months before applying.
                  </p>

                  <h3>Benefits of OCI Card</h3>
                  <ul>
                    <li>Multiple-entry, life-long visa for visiting India</li>
                    <li>No police reporting during any stay in India</li>
                    <li>
                      Equal treatment with NRIs in financial, economic, and educational fields,
                      excluding agricultural land acquisition
                    </li>
                  </ul>

                  <h3>Application Process</h3>
                  <p>
                    Applications are online. Follow instructions carefully before submitting, and
                    check the status online. For more on fees and applications, visit the official
                    site.
                  </p>

                  <h3>OCI Registration Photograph Requirements</h3>
                  <p>OCI applications require photos meeting ISO and ICAO standards.</p>
                  <ul>
                    <li>
                      <strong>Size:</strong> 2 inches x 2 inches (51 mm x 51 mm), in color
                    </li>
                    <li>
                      <strong>Clarity:</strong> Clear, continuous-tone quality on high-quality photo
                      paper
                    </li>
                    <li>
                      <strong>Face Position:</strong> Frontal view, eyes open, head centered, entire
                      head visible
                    </li>
                    <li>
                      <strong>Background:</strong> Plain, light-colored, no shadows
                    </li>
                    <li>
                      <strong>Head Coverings:</strong> Allowed for religious reasons; full face must
                      be visible
                    </li>
                    <li>
                      <strong>Expression:</strong> Neutral, closed mouth
                    </li>
                  </ul>
                  <h4>Do’s:</h4>
                  <ul>
                    <li>Face fully visible, centered, with open eyes</li>
                    <li>
                      Head height 1 to 1 3/8 inches (25-35 mm); eye height 1 1/8 to 1 3/8 inches
                      (28-35 mm) from bottom
                    </li>
                  </ul>
                  <h4>Don’ts:</h4>
                  <ul>
                    <li>Avoid shadows, glares, or digital retouching</li>
                    <li>No dark or tinted glasses; avoid glare if wearing glasses</li>
                  </ul>

                  <h4>Digital Photos</h4>
                  <p>High-resolution photos with no visible pixels are preferred.</p>

                  <h4>Children's Photos</h4>
                  <p>
                    For babies under one, eyes can be closed. Photos should show a clear,
                    front-facing view of the face with no distractions.
                  </p>

                  <h2>Comparative Chart: NRI / Person of Indian Origin / OCI Cardholder</h2>
                  <h3>Who Qualifies?</h3>
                  <p>Anyone who:</p>
                  <ul>
                    <li>Was a citizen of India after January 26, 1950</li>
                    <li>Descends from someone part of India after August 15, 1947</li>
                    <li>
                      Is a spouse of an Indian citizen or OCI holder with a legally registered
                      marriage lasting two years
                    </li>
                  </ul>
                  <p>
                    <em>Note:</em> Individuals with ancestry from Pakistan, Bangladesh, or specified
                    countries are not eligible for OCI registration.
                  </p>

                  <h3>How to Apply for OCI</h3>
                  <p>
                    Applications are online at <a href="https://ociservices.gov.in">OCI Services</a>
                    . Upload documents, photo, and signature; minors need a left thumb impression.
                    After submission, originals must be verified at the Indian Mission/Post or FRRO.
                  </p>

                  <h3>Benefits of an OCI Cardholder</h3>
                  <ul>
                    <li>Multiple-entry, lifelong visa for India</li>
                    <li>No police registration for any length of stay</li>
                    <li>
                      Parity with Indian citizens for domestic airfare, entry to parks and
                      monuments, and property (except agricultural land)
                    </li>
                    <li>
                      Parity with NRIs for inter-country adoption, entrance exams, property
                      sale/purchase, and certain professions
                    </li>
                  </ul>
                  <p>
                    <em>Note:</em> OCI cardholders need special permissions for research, missionary
                    work, journalism, mountaineering, and visiting restricted areas.
                  </p>

                  <h3>Fees for OCI Application</h3>
                  <ul>
                    <li>Outside India: USD 275 or equivalent</li>
                    <li>Within India: INR 15,000</li>
                  </ul>

                  <h3>Citizenship Eligibility</h3>
                  <p>
                    OCI cardholders can apply for Indian citizenship after five years of
                    registration and one year of residence in India.
                  </p>

                  <p>
                    For more information, visit the{" "}
                    <a href="http://mha.gov.in">Ministry of Home Affairs</a>.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OciServices;
