import coursesGrid1 from "../extra-images/MBA-in-USA.jpg";
import coursesGrid2 from "../extra-images/Banking-and-Finance.jpg";
import coursesGrid3 from "../extra-images/Engineering.jpg";
import coursesGrid4 from "../extra-images/Computer-Science.jpg";
import coursesGrid5 from "../extra-images/Mass-Communication.jpg";
import coursesGrid6 from "../extra-images/Hotel-Management.jpg";
import coursesGrid7 from "../extra-images/event-management-courses-abroad.jpg";
import coursesGrid8 from "../extra-images/data-science-courses-abroad.jpg";
import coursesGrid9 from "../extra-images/Health-Science-Courses-Abroad.jpg";
import coursesGrid10 from "../extra-images/MBA-in-Ireland.jpg";
import coursesGrid11 from "../extra-images/Artificial-Intelligence-Courses-Abroad.jpg";
import coursesGrid12 from "../extra-images/Social-Work-Courses-Abroad.jpg";

let courseDetails = [];
courseDetails[1] = {
  name: "MBA in Abroad",
  image: coursesGrid1,
  link: "/study-abroad-courses/mba-in-abroad",
  pageTitle: "MBA IN ABROAD",
  pageDesc:
    "Embarking on the journey of pursuing an MBA can be an exhilarating choice. Choosing to study for an MBA abroad not only offers the opportunity to acquire a prestigious degree but also presents a unique international experience. Immersing yourself in a foreign country provides a rich cultural encounter that expands your global outlook and sharpens your cross-cultural competencies. The exposure to diverse cultures and business methodologies is priceless in today's interconnected global market.</br></br> Additionally, opting for an MBA program overseas enables you to connect with professionals from various backgrounds and nationalities. Building a robust global professional network can pave the way for international career prospects and collaborations. Moreover, many international MBA programs are esteemed for their top-tier education and distinguished faculty members.</br></br>Enrolling in these institutions equips you with a solid business foundation and essential skills, enhancing your credibility and marketability in a fiercely competitive job arena. Choosing to pursue an international MBA is a strategic decision for those aiming to gain a competitive advantage in the global business landscape.",
  pageContent: [
    {
      heading: "Admission Process and course details for an MBA in abroad",
      content:
        "When considering pursuing an MBA abroad, understanding the admission process for international MBA programs is crucial. While specific requirements may vary depending on the university and country of choice, there are general steps to anticipate.</br></br> Initially, research and identify universities offering MBA programs abroad, carefully reviewing admission criteria, especially for international students. Next, prepare application documents, which typically include academic transcripts, letters of recommendation, a CV, a statement of purpose, and proof of language proficiency, such as an English test score. The admissions committee will thoroughly review your application, evaluating academic qualifications, work experience, and personal statement. Upon successful application, you'll receive an offer of admission, requiring acceptance and payment of any necessary deposit to secure your place.</br></br> Subsequently, apply for a student visa if needed and make arrangements for accommodation and logistics. While the admission process for an MBA abroad can be competitive and time-consuming, careful preparation and attention to detail can make it an achievable goal. Utilizing the services of a study abroad consultancy like Guidance Plus can streamline these steps. MBA programs abroad offer a wide range of specializations catering to various career paths and industries such as finance, marketing, entrepreneurship, and sustainability. Specializations like Healthcare Management, Sports Management, International Management, Energy Management, and Digital Innovation Management provide opportunities to focus your studies on specific areas, gaining in-depth knowledge and expertise that differentiate you in the job market. Specialized courses often impart practical, industry-specific skills applicable to real-world business challenges. Most MBA programs abroad typically span from one to two years academically.",
    },
    {
      heading: "Job Opportunities",
      content:
        "Earning an MBA from a foreign university can significantly boost your career opportunities and unlock exciting pathways. In today's interconnected global business environment, employers highly value professionals with a global mindset and international exposure. Holding an MBA from a prestigious overseas institution can differentiate you from other candidates, positioning you as a sought-after asset in the competitive job market. Moreover, many international MBA programs offer robust career services and networking opportunities to support your post-MBA career launch. These may include partnerships with multinational</br></br> corporations, networking events, and assistance with internships or job placements, enhancing your job search effectiveness and providing a competitive advantage. On average, professionals in the MBA sector can expect to earn a starting salary of around 144,900 USD per year. Studying for an MBA abroad not only equips you with a high- quality education but also immerses you in diverse cultures, fosters valuable networks, and broadens your global perspectives. If you are prepared to embark on an exceptional educational journey that will shape both your professional trajectory and personal growth, pursuing an MBA abroad is a compelling option to consider.",
    },
  ],
};
courseDetails[2] = {
  name: "Banking and Finance Courses Abroad",
  image: coursesGrid2,
  link: "/study-abroad-courses/banking-and-finance-courses-abroad",
  pageTitle: "BANKING AND FINANCE COURSES ABROAD",
  pageDesc:
    "Are you passionate about numbers and interested in studying abroad? If so, banking and finance courses overseas could be the perfect fit for you. Finance professionals play a crucial role in efficiently allocating resources and capital, driving economic development, stability, and global growth. Banking, accounting, and finance are essential components of both private and public sectors, with bankers aiding customers in navigating financial challenges. By pursuing banking and finance courses abroad, students gain insights into financial markets and economic systems, enhancing their knowledge base.<br/></br> In a world where business and the economy are rapidly evolving, accountants oversee daily expenditures, while financial experts focus on capital management and company growth planning. Accountancy involves analyzing financial information, policies, reports, auditing, credits, debits, tax, and revenue within organizations. With a strong focus on money management, the field offers a wide range of job opportunities globally. Enrolling in banking and finance courses abroad can pave the way for a promising future in the field. For expert guidance on studying abroad, reach out to the leading overseas study consultancy in Belfast, Guidance Plus NI, to have all your queries addressed.",
  pageContent: [
    {
      heading: "Bachelors in Accounting, Banking and Finance",
      content:
        "Embarking on a journey to pursue a bachelor's degree in accounting, banking, and finance courses abroad represents a significant milestone in cultivating a global outlook and contributing to the dynamic financial landscape. This educational path is instrumental in shaping future financial professionals who grasp the interwoven fabric of the global economy. Additionally, studying banking and finance courses abroad exposes students to a variety of financial systems and practices, providing them with a comprehensive understanding of diverse approaches utilized worldwide. This exposure enables students to comprehend the intricate dynamics governing international financial markets, equipping them to navigate these systems adeptly and facilitate international financial transactions.</br></br> A bachelor's degree in fields like accounting and financial management, business with finance management, banking and wealth management, and financial management typically spans 3 to 4 years. Students who have completed their secondary studies from a recognized board are eligible to apply for these programs. Moreover, universities abroad offer well-structured curricula, allowing students to pursue their bachelor's degree at top-rated institutions globally without straining their budget. With the assistance of Guidance Plus NI, the leading overseas education consultants in Belfast, Northern Ireland, students can explore affordable options to complete their bachelor's degree at world-renowned colleges.",
    },
    {
      heading: "Masters in Accounting, Banking and Finance",
      content:
        "Obtaining an advanced degree in finance equips students with profound knowledge and skills that are highly pertinent in today's interconnected world, representing an investment in a brighter and more globally impactful future. By delving into financial markets, risk management, and investment strategies, students are primed to excel in a world where economic choices made in one region can reverberate worldwide. Following the successful completion of a bachelor's degree with a commendable score from a recognized university, students can progress to pursue a master's degree. While a bachelor's degree provides students with precise knowledge of banking, accounting, and finance, a master's degree encourages broader thinking and deepens their understanding of these areas. The duration of a master's program typically ranges from one to two years, contingent on the chosen major and country of study.</br></br> Students can opt for a master's in various specializations such as corporate finance and banking, business administration, applied finance, finance, financial market and investment, international securities, commerce, economics, professional accounting, accounting, business research finance and accounting, corporate governance and accountability, financial analysis and business development, corporate finance and management accounting, among others. Given the ever-evolving global financial landscape, a master's degree in finance equips students with the skills necessary to navigate this complexity effectively.",
    },
    {
      heading: "Diploma in Accounting, Banking and Finance",
      content:
        "A diploma program serves as a foundational course providing students with essential knowledge about a specific field of study. Students who have completed their higher secondary education with commendable marks from a recognized board are eligible to apply for these programs. Diploma options include Business Administration with a specialization in banking and finance or a dedicated Diploma in Banking and Finance. Both courses aim to equip students with the skills to manage current operations effectively and strategize for the future. Colleges often provide internship opportunities in reputable industries, allowing students to gain practical experience. Upon completing a diploma program, students have the flexibility to transition into the workforce or pursue a bachelor's degree.</br></br> Graduates of a diploma program in banking and financial management are well-prepared to assume management roles within their organizations. This credential opens doors to various job opportunities, including managerial positions in financial institutions, banks, and insurance companies.</br>Accounting is a universally recognized profession with abundant career prospects. It offers stability and vast opportunities for growth. Accountants speak the universal language of business, making them indispensable in all sectors. A proficient entrepreneur is also a skilled accountant. Every industry requires accountants to maintain records, handle tax and revenue matters, assess profit and loss margins, and evaluate the financial standing of the business. On average, finance professionals can expect an annual salary of around $120,000. Salaries may vary depending on experience and expertise levels.",
    },
    {
      heading: "Conclusion",
      content:
        "In conclusion, embarking on accounting, banking, and finance courses abroad is a wise decision that offers a broadened global perspective, a diverse educational setting, and the chance to thrive in the ever-evolving financial realm. This educational experience provides you with the necessary skills and knowledge to excel in the international financial landscape, positioning you as a competitive contender in the global job market. Opting for this course sets you on a path towards a promising and globally relevant career in accountancy and finance. Moreover, studying accounting, banking, and finance courses abroad presents ample international networking opportunities. Interacting with peers from around the world builds a global network that can unlock exciting career possibilities globally. These connections may lead to collaborative partnerships, entrepreneurial ventures, and opportunities with a worldwide impact. For further information or to address any queries, please do not hesitate to reach out to us",
    },
  ],
};
courseDetails[3] = {
  name: "Engineering Courses Abroad",
  image: coursesGrid3,
  link: "/study-abroad-courses/engineering-courses-abroad",
  pageTitle: "Exploring Engineering Courses in Overseas Universities",
  pageDesc:
    "Are you contemplating the idea of pursuing engineering courses abroad? Engineering is a prestigious field within the STEM (science, technology, engineering, and mathematics) disciplines. As STEM degrees equip students with a diverse skill set, they are gaining popularity globally. Employers worldwide have a high demand for engineering graduates due to their specialized knowledge and essential life skills acquired through their degrees.",
  pageContent: [
    {
      heading: "Reasons to Consider Studying Engineering Courses Abroad",
      content:
        "Studying engineering courses overseas offers a high-quality education and the opportunity to immerse yourself in a new culture, broaden your horizons, and establish an international network. In a world where technology and global collaboration are increasingly vital, having an international engineering degree can give you a competitive edge in the job market.",
    },
    {
      heading: "Benefits of Attending Top Engineering Colleges Abroad",
      content:
        "Studying abroad allows you to experience different teaching methods, gain exposure to diverse perspectives, adapt to new environments, foster independence, and enhance problem-solving skills. As an engineering student, you will engage in real-world projects, collaborate with peers from various backgrounds, and learn from renowned professors in the field. Furthermore, you will have access to cutting-edge facilities and research opportunities, ensuring hands-on experience and keeping you abreast of the latest advancements in engineering.",
    },
    {
      heading: "Countries Offering Excellent Engineering Courses",
      content:
        "From established institutions in the United States and the United Kingdom to emerging engineering hubs in Germany, Canada, Australia, and Singapore, there is a plethora of options for studying engineering overseas. Each country provides unique advantages and opportunities for engineering students, with top universities renowned for their innovative programs and cutting-edge research.",
    },
    {
      heading: "Popular Engineering Courses Abroad",
      content:
        "Whether your interest lies in civil engineering, mechanical engineering, computer science, or any other engineering discipline, there are numerous sought-after courses available at universities worldwide. Aerospace engineering, structural engineering, geotechnical engineering, electrical engineering, and transportation engineering are among the popular choices for aspiring engineers.",
    },
    {
      heading: "Admission Requirements for Engineering Courses Abroad",
      content:
        "Admission criteria for engineering courses abroad vary by country and university. Typically, applicants must submit academic transcripts, standardized test scores (such as the SAT or ACT), letters of recommendation, and a statement of purpose. Proficiency in the English language may also be required through exams like the TOEFL or IELTS. It is essential to research and meet the specific admission requirements of each university of interest, including considerations of extracurricular activities, work experience, and personal achievements in the application process.",
    },
    {
      heading: "Career Opportunities for International Engineering Graduates",
      content:
        "Studying engineering courses abroad opens up a world of career opportunities. International engineering graduates are highly sought after by employers. This is mainly due to their global perspective, diverse skill sets, and cross-cultural communication abilities.</br></br>Depending on your area of specialization, you may find employment opportunities in various sectors such as aerospace, automotive, energy, electronics, construction, and technology. Many international engineering graduates go on to work for multinational corporations, research institutions, and government agencies. Some may even start their entrepreneurial ventures. For engineers in the US, the average annual salary is approximately 135655 USD.</br></br>Furthermore, studying engineering abroad also provides opportunities for internships, co-op programs, and networking events, allowing you to connect with industry professionals and gain practical experience. These experiences can significantly enhance your employability and help you stand out in a competitive job market. Research your options, consider your interests and goals, and take the leap into an exciting educational adventure that will shape your future as a successful engineer.</br></br>So, if you’re ready to embark on an exciting educational journey, explore the top engineering courses offered by universities worldwide and prepare for a rewarding career in the field of engineering.",
    },
  ],
};
courseDetails[4] = {
  name: "Computer Science Courses Abroad",
  image: coursesGrid4,
  link: "/study-abroad-courses/computer-science-courses-abroad",
  pageTitle: "Exploring Computer Science Courses Overseas",
  pageDesc:
    "Are you passionate about computers and technology, dreaming of expanding your horizons while pursuing your interest in computer science? Embark on a journey to explore the world of computer science courses abroad, where endless opportunities await. Studying computer science overseas not only offers a high-quality education but also enriches your academic experience.",
  pageContent: [
    {
      heading: "Advantages of Studying Computer Science Courses Abroad",
      content:
        "Studying computer science abroad exposes you to diverse teaching methods and perspectives, challenging traditional thinking and broadening your knowledge base. Interacting with students from around the globe in a multicultural environment fosters collaboration and helps you establish a global network, essential for future career prospects.",
    },
    {
      heading: "Top Universities for Computer Science Abroad",
      content:
        "When pursuing a bachelor's degree in computer science abroad, you not only receive a quality education but also immerse yourself in a new culture, enhancing personal growth. Many international universities offer internship programs with leading tech companies, allowing you to apply theoretical knowledge in practical settings and boost your employability post-graduation. Specialized tracks in areas like artificial intelligence, actuarial studies, computational design, and cybersecurity can provide a competitive edge in the job market.",
    },
    {
      heading: "Mastering Computer Science Abroad",
      content:
        "For those seeking to deepen their expertise in computer science, pursuing a master's degree abroad opens up exciting opportunities. These programs delve into specialized areas like artificial intelligence, big data systems, biomedical informatics, data analytics, cybersecurity, and software engineering, combining theory with practical applications to prepare students for the evolving tech industry.",
    },
    {
      heading: "Diploma Programs in Computer Science Abroad",
      content:
        "Shorter and focused diploma programs in computer science abroad offer practical training and hands-on experience, allowing you to quickly enter the job market or advance your career. With a flexible duration ranging from 6 to 18 months, diploma programs specialize in programming, software development, cybersecurity, or data analysis, equipping students with technical skills through coursework and practical projects to enhance their employability.",
    },
    {
      heading: "Career Opportunities in Computer Science",
      content:
        "Graduates of computer science programs abroad are in high demand globally, with job opportunities in software development, data analysis, cybersecurity, artificial intelligence, and more. International experience demonstrates adaptability, cultural awareness, and independence, qualities highly valued by employers in today's interconnected world. Whether working for multinational corporations, start-ups, research institutions, or government agencies, a degree in computer science from a prestigious institution abroad significantly enhances career prospects.",
    },
    {
      heading: "In Conclusion",
      content:
        "Internship programs and industry partnerships offered by many universities provide students with hands-on experience and valuable networking opportunities, enhancing practical skills and opening doors to lucrative career paths. The global perspective gained through studying computer science abroad gives graduates a competitive advantage in the fast-evolving job market driven by technological advancements. Explore the range of options available for studying computer science courses abroad and take the leap towards an exciting future!",
    },
  ],
};
courseDetails[5] = {
  name: "Mass Communication",
  image: coursesGrid5,
  link: "/study-abroad-courses/mass-communication",
  pageTitle: "MASS COMMUNICATION",
  pageDesc:
    "The significance of mass communication courses abroad is rapidly increasing, as staying informed about global affairs is essential in today's world. Media serves as the platform through which we gain knowledge about events worldwide, encompassing print, broadcasting, visual, and advertising media under one umbrella. Pursuing a degree in mass communication abroad presents a valuable opportunity in fields such as journalism, advertising, editing, and more, allowing you to become a crucial contributor to global updates. You can specialize in areas like mass communication, public relations, and mass communication with advertising.</br></br>Guidance Plus Educational Services offers guidance to students interested in pursuing mass communication courses abroad, assisting in securing admissions to top-rated colleges and universities internationally. Here are some details about mass communication programs:",
  pageContent: [
    {
      heading: "Bachelor’s in Mass Communication",
      content:
        "A bachelor's degree in mass communication abroad typically spans 2 to 3 years and requires students to be at least 18 years old. Candidates should hold an advanced diploma in mass communication from a recognized government institution or possess a higher secondary education with good marks from a recognized body. Proficiency in English, with an IELTS score of 6, is often required, though not universal across all universities. Courses such as Bachelor of Arts in Mass Communication, Bachelor of Arts in Mass Communication with Advertising, and Bachelor of Arts in Mass Communication and Public Relations cover topics like news reporting, video editing, public relations, media management, and more.",
    },
    {
      heading: "Masters in Mass Communication",
      content:
        "Master’s in Mass Communication courses abroad typically last one year in most countries and are open to graduates holding a good bachelor’s degree from a recognized university. Some institutions may require TOEFL or IELTS scores based on eligibility criteria. Programs like Master of Arts in Communication Management, Master of Business Administration in Information and Public Media, and Master of Arts in Journalism and Mass Communication are designed for those aiming for careers in the communication industry.",
    },
    {
      heading: "PhD in Mass Communication",
      content:
        "The Doctor of Philosophy in Mass Communication is an innovative course available to students who have completed a master’s degree with a good score from a recognized university. Proficiency in English is essential, and students can choose their research subject in this dynamic program.",
    },
    {
      heading: "Diploma and Advanced Diploma in Mass Communication",
      content:
        "Diploma and advanced diploma programs in mass communication abroad typically range from one year to eight to ten months in duration. These programs cater to individuals aged 16 and above, offering modules covering advertisement, public relations, journalistic ethics, print media, and more. English proficiency tests like IELTS or TOEFL may be required by some institutions but are not mandatory for all.",
    },
    {
      heading: "Job Opportunities in Mass Communication",
      content:
        "The field of mass communication is gaining popularity worldwide, offering promising career prospects in areas like advertising, public relations, journalism, content writing, and more. Graduates of mass communication courses abroad can pursue roles such as journalists, video editors, news anchors, and photographers, with ample opportunities in media-related professions. Graduates in countries like New Zealand and Canada can expect competitive annual salaries in the mass communication field.</br></br>Mass communication presents a rewarding career path for individuals with dedication and skill, offering a range of opportunities in the media industry.",
    },
  ],
};
courseDetails[6] = {
  name: "Hospitality and Tourism Courses Abroad​",
  image: coursesGrid6,
  link: "/study-abroad-courses/hospitality-and-tourism-management",
  pageTitle: "Exploring Hospitality and Tourism Courses Abroad",
  pageDesc:
    "Hospitality and tourism courses are essential educational programs that offer in-depth knowledge about managing businesses and services in the hospitality and travel industries. These courses cover the operation and administration of hotels, restaurants, resorts, airlines, cruise lines, travel agencies, and other hospitality and tourist organizations in detail.</br></br> Hospitality and tourism are interconnected sectors, with hospitality providing services to visitors as part of tourism, which encompasses lodging, attractions, and transportation. The global tourism and hospitality industries present vast opportunities for growth and employment, driving economic expansion and job creation worldwide.",
  pageContent: [
    {
      heading: "Masters in Hospitality and Tourism Courses Abroad",
      content:
        "A Master’s in Hospitality and Tourism from an overseas university opens doors to a rewarding career in these dynamic industries. As crucial components of the global economy, the need for skilled professionals in hospitality and tourism is increasing. Pursuing a Master’s in Hospitality and Tourism abroad equips you with the knowledge to thrive in this ever-evolving field. Specializations such as hotel management, event planning, sustainable tourism, and culinary arts offer diverse career paths for passionate individuals. Programs include hotel management, events and tourism management, sustainable tourism, and more, with master’s degrees typically lasting one to two years.",
    },
    {
      heading: "Bachelors in Hospitality and Tourism Courses Abroad",
      content:
        "Bachelor’s programs in hospitality and tourism expose students to the travel and leisure sector, emphasizing business administration, hospitality studies, and key theoretical concepts. Students often study abroad to gain real-world experience during their program. Degrees may conclude with a bachelor’s thesis or project, with options like Bachelor in Hotel Management and Catering Technology, Hospitality, and Hotel Administration, and BBA in Hotel Management available. Admission requirements typically include a minimum of 12 years of schooling and a minimum of 50% marks in higher secondary education, with bachelor’s degrees lasting 3-4 years.",
    },
    {
      heading: "Diploma in Hospitality and Tourism Courses Abroad",
      content:
        "Diploma programs in hospitality and tourism abroad offer industry-relevant education, combining business theory with practical training to prepare graduates for successful careers. These programs are suitable for students seeking career changes or as a foundation for further studies. Postgraduate diploma programs typically require a bachelor’s degree for admission.",
    },
    {
      heading: "Opportunities in Asian Countries",
      content:
        "Certain Asian countries like Singapore, Malaysia, and Dubai offer entry-level hospitality and tourism courses for students who have completed 10th standard, providing opportunities for those without higher education to pursue studies abroad.",
    },
    {
      heading: "Conclusion",
      content:
        "The thriving tourism and hospitality sectors encompass various industries and offer diverse job opportunities, from entry-level to executive management roles. The sectors evolve to meet customer needs and emerging trends, providing job security and advancement prospects for professionals. The tourism and hospitality sector can drive positive change, support economies, preserve culture, and foster prosperity. Consider enrolling in hospitality and tourism courses abroad to embark on a rewarding and impactful career. Connect with Guidance Plus for expert assistance in navigating your overseas education journey.",
    },
  ],
};
courseDetails[7] = {
  name: "Event Management Courses Abroad",
  image: coursesGrid7,
  link: "/study-abroad-courses/event-management-courses-abroad",
  pageTitle: "Exploring Event Management Courses Abroad",
  pageDesc:
    "If you are considering pursuing event management courses abroad, it's essential to understand the field of event management, the various courses available internationally, and the promising job opportunities awaiting you upon completion of your education in event management.",
  pageContent: [
    {
      heading: "What is Event Management?",
      content:
        "Event management involves the planning, marketing, and execution of events tailored to meet customer demands, with the ultimate goal of ensuring customer satisfaction. In today's world, people celebrate significant moments in their lives, such as birthdays, weddings, and anniversaries, with a desire to make these events special. The demand for skilled event managers is high, making event management courses abroad an attractive option for those interested in this field.",
    },
    {
      heading: "Bachelor Courses in Event Management",
      content:
        "Bachelor courses in event management typically have a duration of 2 to 4 years, varying based on the country and specialization chosen. Let's explore some bachelor's degree specializations in event management:</br></br> Bachelor of Arts (Honours) Festival and Event Management: This program focuses on developing students' ideas and providing a comprehensive view of business and event management, covering concepts of event planning and programs.</br></br> Bachelor of Business (Sports and Event Management): This program combines practical and theoretical classes to enhance careers in sports and event management, offering opportunities in these growing sectors.</br></br> Bachelor of Arts with Honors in International Hospitality and Event Management: This program equips students with event management concepts and knowledge of the hospitality sector, providing opportunities in hospitality management and corporate sectors.",
    },
    {
      heading: "Diploma Courses for Event Management",
      content:
        "Diploma programs in event management are designed to enhance students' skills and executive ideas in event planning, marketing, and management. Some diploma courses include:</br></br> ⁠Diploma in Events Management: This program introduces students to event management principles, tourism techniques, marketing strategies, event sponsorship, and promotions.</br></br> ⁠Diploma in Integrated Event Management and Project Management (DEPM): This diploma program prepares students to work as professionals in event management, providing training in event marketing, project management, and public relations.</br></br>⁠Diploma in Business Management, specializing in Event Management: This program focuses on project management concepts and essential event management skills, offering a comprehensive understanding of event planning.",
    },
    {
      heading: "Job Opportunities for Event Management Programs",
      content:
        "Event management programs open doors to various job prospects, including roles such as event coordinator, wedding planner, conference organizer, and corporate event manager. The field's dynamic nature allows professionals to work with diverse clients, plan a wide range of events, and find opportunities for career growth and development.</br></br> In the USA, entry-level event managers typically earn around $90,000 annually, with experienced professionals earning even more.</br></br> Event management offers a range of career paths, including event coordination, production and logistics, sales and sponsorship, and more, providing exciting opportunities for individuals with strong communication, organizational, and creative skills.</br></br> If you are interested in pursuing event management courses abroad, explore the top colleges offering MBA programs in Ireland for further studies.",
    },
  ],
};
courseDetails[8] = {
  name: "Data Science Courses Abroad",
  image: coursesGrid8,
  link: "/study-abroad-courses/data-science-courses-abroad",
  pageTitle: "Exploring Data Science Courses Abroad",
  pageDesc:
    "Data science courses are structured educational programs designed to equip individuals with the skills, techniques, and tools necessary to work in the field of data science. Data science involves extracting insights and knowledge from structured and unstructured data, going beyond algorithms and numbers to uncover hidden trends, make informed decisions, and foster creativity. Data scientists play a pivotal role in transforming data into valuable insights in the digital age.",
  pageContent: [
    {
      heading: "Components of Data Science",
      content:
        "Practical data science, theoretical data science, logic, and the history of data science are integral components of a data science course, aimed at providing individuals with the skills to analyze and interpret data, make data-driven decisions, and contribute to advancements across various industries.",
    },
    {
      heading: "Masters in Data Science Courses Abroad",
      content:
        "In a data-driven era, the demand for skilled professionals in data science is soaring. Master's in data science courses abroad cover various methods such as predictive modeling, data visualization, machine learning, and big data. Students learn to use cutting-edge tools to explore, analyze, manage, and visualize large datasets, applying analytical techniques to solve data-rich problems across different domains. Admission requirements typically include a relevant bachelor's degree, a statement of purpose, and letters of recommendation.</br></br>Postgraduate courses in data science and business analytics, data science and AI, big data, business intelligence and data science, cyber security data science, and data engineering are offered internationally, with program durations ranging from 1 to 2 years.",
    },
    {
      heading: "Bachelor in Data Science Courses Abroad",
      content:
        "Bachelor's in data science courses abroad help students develop quantitative thinking and leadership skills, covering ethical and legal aspects of data analytics, effective communication, and teamwork. The curriculum includes topics like analyzing large datasets, extracting meaningful patterns, and making informed decisions. Applicants typically need to have completed high school and possess a strong background in computer science, statistics, and mathematics.</br></br> Bachelor's courses in data science such as Bachelor of Data Science and Decisions, B.A. in Statistics and Data Science, and Bachelor of Applied Data Science provide students with foundational knowledge and skills essential for a career in data science.",
    },
    {
      heading: "Diploma in Data Science Courses Abroad",
      content:
        "Professional credentials in data science can enhance career prospects and job opportunities. Graduate and postgraduate diploma programs in data science are available, including postgraduate diploma in applied data science, PG certificate in statistics for data science, and graduate diploma in data science",
    },
    {
      heading: "Job Opportunities in Data Science",
      content:
        "Data science offers a wide range of career paths, with job positions including analytics manager, database administrator, business analyst, data scientist, data engineer, machine learning engineer, data architect, and statistician. Entry-level data science professionals can expect an average annual salary of around $130,800 USD overseas.</br></br>Data science experts play a crucial role in extracting insights from vast amounts of data, applying analytical skills to transform raw data into valuable information. Pursuing data science courses abroad provides individuals with the knowledge and skills necessary to thrive in this rapidly evolving field.",
    },
  ],
};
courseDetails[9] = {
  name: "Health Science Courses Abroad",
  image: coursesGrid9,
  link: "/study-abroad-courses/health-science-courses-abroad",
  pageTitle: "Exploring Health Science Courses Abroad",
  pageDesc:
    "Studying health science courses abroad offers a transformative educational experience, providing access to a diverse range of knowledge, innovation, and cultural perspectives. Health science education has become a global phenomenon, with institutions worldwide offering advanced programs to prepare students for success in this dynamic and vital field.",
  pageContent: [
    {
      heading: "Advantages of Studying Health Science Courses Abroad",
      content:
        "One of the primary benefits of pursuing health science courses abroad is the extensive range of specializations available. With over 50 academic fields for specialization, including nutrition science, anatomy, health policy, pharmacy, physiology, and more, international institutions offer tailored programs to meet diverse career aspirations. Practical experience is a crucial component of health science courses abroad, providing hands-on exposure through internships and clinical placements in real-world healthcare settings, enhancing skills and readiness for the healthcare industry.",
    },
    {
      heading: "Masters in Health Science Courses Abroad",
      content:
        "Embarking on a journey to study health science courses abroad is a life-changing decision, offering unparalleled educational and cultural experiences. Master's programs in health science cater to various specializations like healthcare management, health informatics, global public health, and more, providing students with the skills and perspectives needed to make a meaningful impact in their chosen field. Admission typically requires a bachelor's degree in a relevant field, with a score above 55%.",
    },
    {
      heading: "Bachelor in Health Science Courses Abroad",
      content:
        "Health science courses at the bachelor's level focus on preserving human health, reflecting the field's significance in advancing healthcare practices and extending life expectancy. Programs emphasize research and practical experience, providing hands-on research projects and clinical placements to prepare students for real-world healthcare challenges. Bachelor courses include specialties like nuclear medicine technology, biomedical science, nursing, microbiology, public health, and more, with course durations ranging from 2 to 4 years.",
    },
    {
      heading: "Diploma in Health Science Courses Abroad",
      content:
        "Diploma courses in health science abroad offer opportunities for postgraduate and undergraduate diplomas, providing specialized training in areas like healthcare management, health informatics, and public health. Programs like PG Diploma in International Healthcare Management, Graduate Diploma in Environmental Public Health, and more cater to students seeking a professional credential to enhance their career prospects. Admission requirements vary, with diploma durations ranging from 10 months to two years.",
    },
    {
      heading: "Job Opportunities in Health Science",
      content:
        "Studying health science courses abroad opens doors to a wide range of career opportunities, such as epidemiologist, physician, nurse, physiotherapist, biomedical scientist, health administrator, and more. Graduates often find rewarding careers in research, healthcare administration, public health, and various healthcare sectors globally. The practical exposure gained during studies enhances employability and prepares students for success in the healthcare industry.</br></br>Health science courses abroad offer a unique chance to build a global professional network, gain international exposure, and position oneself as a well-rounded healthcare professional ready to make a positive impact in the field. Whether aspiring to work in research, healthcare management, or public health, international institutions provide the skills and expertise needed for a successful career in healthcare.",
    },
  ],
};
courseDetails[10] = {
  name: "MBA in Ireland",
  image: coursesGrid10,
  link: "/study-abroad-courses/mba-in-ireland",
  pageTitle: "Advantages of Pursuing an MBA in Ireland",
  pageDesc:
    "MBA courses in Ireland are highly sought after due to the country's status as a prominent business hub for Europeans and the UK. Ireland is known for its rapid economic growth, making it an attractive destination for pursuing higher education. Irish universities and institutions are globally recognized for their excellence in management studies, with top-ranked institutions like Trinity College Dublin and University College Dublin offering prestigious MBA programs.",
  pageContent: [
    {
      heading: "Advantages of Studying MBA in Ireland:",
      content:
        "1.⁠ ⁠Diverse Specializations: Irish institutions offer a wide range of MBA specializations including international marketing, finance, HR, international business, logistics, supply chain management, business analytics, and research analytics. Students can choose a specialization that aligns with their interests and career goals.</br> 2.⁠ ⁠Post-Graduate Level Course: MBA courses in Ireland are post-graduate level programs with a duration of one year, offered in a semester-based system with full-time and distance learning options. Most MBA programs in Ireland are considered Level 8 programs, providing students with extensive knowledge and skills for career advancement.</br>3.⁠ ⁠Job Opportunities and Post-Study Work Visa: Completing an MBA in Ireland offers better job opportunities for international students, with many multinational corporations having their main offices in the country. International students can benefit from a post-study work visa for up to two years, creating opportunities for career growth and potential permanent residency.</br>4.⁠ ⁠Global Business Connections: By pursuing an MBA in Ireland, students gain exposure to diverse cultures, networks, and global perspectives, enhancing their professional development and marketability in the global job market.",
    },
    {
      heading: "Eligibility Criteria for MBA Courses in Ireland:",
      content:
        "•⁠  ⁠Overall IELTS score of 6.5 with no band less than 6.0</br>•⁠  ⁠Minimum 55% in undergraduate degree</br>•⁠  ⁠GMAT qualifications may be required by some institutions</br>•⁠  ⁠Statement of purpose, letters of recommendation, academic documents, and passport copy</br>•⁠  ⁠Work experience may be required by some institutions",
    },
    {
      heading: "Top MBA Courses Offered in Ireland:",
      content:
        "•⁠  ⁠MBA in International Business </br>•⁠  ⁠MBA in Finance</br> •⁠  ⁠MBA in Project Management</br>•⁠  ⁠MBA in Human Resource Management</br>•⁠  ⁠MBA in Marketing</br>•⁠  ⁠MBA in Cloud Computing</br>•⁠  ⁠MBA in Information Systems</br></br>Overall, pursuing an MBA in Ireland offers numerous benefits, including access to accredited institutions, international exposure, placement opportunities, and potential for settling in Europe. The average salary for MBA positions in Ireland ranges from €60,000 to €90,000 per year, making it an attractive option for career advancement and personal growth.</br></br>Consulting with an Ireland counselor can help you explore the best options for your MBA studies in Ireland and guide you through the application process.",
    },
  ],
};
courseDetails[11] = {
  name: "Artificial Intelligence Courses Abroad",
  image: coursesGrid11,
  link: "/study-abroad-courses/artificial-intelligence-courses-to-study-abroad",
  pageTitle: "Exploring Artificial Intelligence Courses Abroad",
  pageDesc:
    "Artificial Intelligence (AI) has emerged as a transformative force in various industries, revolutionizing how tasks are automated and problems are solved. Pursuing Artificial Intelligence courses abroad offers a unique opportunity to gain global exposure, access cutting-edge research, and explore the practical applications of AI technologies.",
  pageContent: [
    {
      heading: "Advantages of Studying AI Courses Abroad:",
      content:
        "1.⁠ ⁠Global Perspective: AI courses abroad provide a global perspective on the latest advancements in the field. Delivered by experienced instructors and industry experts, these programs blend international best practices with local insights, keeping students informed about dynamic and industry-relevant AI curriculum updates.</br>2.⁠ ⁠Cutting-Edge Technology: Institutions abroad offer state-of-the-art technologies and facilities, ensuring students have access to the tools and techniques shaping the future of AI. By immersing yourself in a conducive learning environment equipped with cutting-edge technology, you can enhance your learning experience and practical skills.</br>3.⁠ ⁠Specializations and Opportunities: AI courses abroad offer a wide range of specializations, including machine learning, natural language processing, computer vision, robotics, and more. With diverse choices available, students can select a specialization that aligns with their interests and career aspirations.",
    },
    {
      heading: "Master’s in Artificial Intelligence Courses Abroad:",
      content:
        "Master’s programs in AI last between one and two years, requiring a bachelor’s degree in a relevant field with a minimum score. These programs cover a broad spectrum of AI topics, emphasizing hands-on experience through real-world projects and collaborations with industry leaders.",
    },
    {
      heading: "Bachelor’s in Artificial Intelligence Courses Abroad:",
      content:
        "Bachelor’s programs in AI focus on preserving human health and advancing healthcare practices. Specialties include nuclear medicine technology, biomedical science, nursing, microbiology, public health, and more, with course durations ranging from 2 to 4 years.",
    },
    {
      heading: "Diploma in Artificial Intelligence Courses Abroad:",
      content:
        "Diploma and certificate programs in AI offer specialized training in areas like healthcare management, health informatics, and public health. These short-length courses provide students with practical skills and qualifications to excel in the AI field",
    },
    {
      heading: "Job Opportunities in Artificial Intelligence:",
      content:
        "Completing AI courses abroad opens doors to various career opportunities, including roles like data scientist, machine learning engineer, AI data analyst, and more. Networking events, internships, and career services provided by universities enhance students’ employability and give them a competitive edge in the job market.</br></br>In conclusion, studying Artificial Intelligence abroad provides a comprehensive understanding of the field, exposure to diverse cultures and perspectives, and the opportunity to work with leading researchers and industry experts. For aspiring AI professionals, investing in an education abroad can lead to a transformative and impactful career in this dynamic field.",
    },
  ],
};
courseDetails[12] = {
  name: "Social Work Courses Abroad",
  image: coursesGrid12,
  link: "/study-abroad-courses/study-social-work-abroad",
  pageTitle: "Exploring Social Work Courses Abroad",
  pageDesc:
    "Embarking on a journey to study social work abroad offers students a transformative experience that goes beyond academics, providing a global perspective on societal challenges and innovative approaches to address them. Social work is a field that addresses complex issues such as poverty, inequality, and social injustice, making it crucial to have a diversified understanding of these challenges to make a meaningful impact worldwide.",
  pageContent: [
    {
      heading: "Advantages of Studying Social Work Courses Abroad:",
      content:
        "1.⁠ ⁠Diverse Specializations: Social work courses abroad offer a variety of specializations, including clinical social work, community development, and policy advocacy, tailored to students' interests. Fieldwork and internships are often incorporated into the programs, allowing students to apply theoretical knowledge in real-world settings and enhance practical skills and cultural competence.</br>2.⁠ ⁠Hands-On Experience: The practical exposure gained through internships and fieldwork in social work courses abroad prepares students for real-world challenges and equips them with the skills needed to make a positive difference in communities worldwide.",
    },
    {
      heading: "Bachelor’s in Social Work Courses Abroad:",
      content:
        "Bachelor’s programs in social work provide foundational knowledge in core principles and skills essential to the profession, covering topics such as social welfare systems, human behavior, and social justice. Specializations include Bachelor of Social Work (BSW), BA in Sociology: Social Work, BSc in Human Services Administration: Social Work, and more, with programs lasting from 3 to 4 years.",
    },
    {
      heading: "Master’s in Social Work Courses Abroad:",
      content:
        "Master’s programs in social work offer advanced exploration of specialized areas like clinical social work, policy analysis, and community development. These programs focus on research, enabling students to critically evaluate social issues, implement evidence-based practices, and contribute to the ongoing development of the profession. Course durations vary from 1 to 2 years, with programs like MA in Trauma-Informed Practice: Social Work and Care, MSW in Social Work: Children, Youth, and Families, and more available for specialization.",
    },
    {
      heading: "Diploma and Certificate Courses in Social Work Abroad:",
      content:
        "Diploma and certificate programs provide specialized training in areas like personal support, care paramedics, developmental services, and social service work. These programs enhance students' academic and professional skills, broaden their worldview, and offer opportunities for practical application in the field of social work.",
    },
    {
      heading: "Job Opportunities in Social Work:",
      content:
        "Studying social work courses abroad opens doors to rewarding career opportunities in various sectors like healthcare, finance, manufacturing, and technology. Graduates can pursue roles like social worker, community organizer, policy analyst, and more, contributing to positive social change globally.</br></br>In conclusion, studying social work abroad is an enriching opportunity that equips students with the skills, knowledge, and cultural competence needed to make a meaningful impact in the field of social work. It provides a platform for personal growth, professional development, and global engagement in addressing societal challenges and fostering positive change.",
    },
  ],
};

export const COURSE_DETAILS = courseDetails;
