import React, { useEffect } from "react";

import courseThumbImg from "../extra-images/course-thumb-img.jpg";
import { COURSE_DETAILS } from "./CourseDetailsData";

const CourseDetails = ({ courseId }) => {
  const courseData = COURSE_DETAILS[courseId] ?? [];

  useEffect(() => {
    document.title = `${courseData?.pageTitle ?? ""} - GuidanceplusNI`;
  }, []);

  return (
    <>
      {courseData && (
        <div class="education-main-section">
          <div class="container">
            <div class="row">
              <div class="col-md-9">
                <figure class="education-course-thumb">
                  <img src={courseThumbImg} alt="Guiguidanceplusni" />
                  <figcaption>
                    <h2>{courseData?.pageTitle}</h2>
                  </figcaption>
                </figure>
                <div class="">
                  <p className="text-justify">
                    <span dangerouslySetInnerHTML={{ __html: courseData?.pageDesc }} />
                  </p>
                </div>
                {courseData?.pageContent?.map((c) => (
                  <>
                    <div className="education-section-heading">
                      <h2>{c?.heading}</h2>
                    </div>
                    <div className="education-course-features">
                      <p className="text-justify">
                        <span dangerouslySetInnerHTML={{ __html: c?.content }} />
                      </p>
                    </div>
                  </>
                ))}
              </div>
              <aside class="col-md-3 education-sidebar">
                <div class="education-widget-heading">
                  <h2>Featured Courses</h2>
                </div>
                <div class="widget widget_featured_courses">
                  <ul>
                    {COURSE_DETAILS.map((course) => (
                      <li>
                        <figure>
                          <a href={course?.link}>
                            <img src={course?.image} alt={course?.pageTitle} />
                            <i class="fa fa-link"></i>
                          </a>
                          <figcaption>
                            <h6>
                              <a href={course?.link}>{course?.pageTitle}</a>
                            </h6>
                          </figcaption>
                        </figure>
                      </li>
                    ))}
                  </ul>
                </div>
              </aside>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CourseDetails;
